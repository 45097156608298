import React from 'react';
import Icon from "../Icons/Icon";

interface Props {
  image?: string | null;
  name: string | React.ReactElement;
  textClasses?: string;
}

const NameTag: React.FC<Props> = ({image, name, textClasses}) => {
  return(
    <div className="flex flex-row items-center">
      {image ?
        <img src={image} className="w-8 h-8 rounded-full" alt="Your Profile Photo"/> :
        <Icon iconName="person" className="w-8 h-8 text-black"/>
      }
      <div className={`hidden md:block text-base md:text-lg pl-2 ${textClasses}`}>{name}</div>
    </div>
  )
}
export default NameTag

