import React from 'react';
import ReactModal from 'react-modal';
import Icon from "../Icons/Icon";

export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  disabled?: boolean;
} & Omit<ReactModal.Props, 'onRequestClose'>;

ReactModal.setAppElement('body')

const Modal: React.FC<ModalProps> = ({
   children,
   onClose,
   isOpen,
   disabled,
   shouldCloseOnOverlayClick,
   shouldCloseOnEsc,
   ...otherProps
 }) => {

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
    }
  };

  const localShouldCloseOnOverlayClick = disabled
    ? false
    : shouldCloseOnOverlayClick;
  const localShouldCloseOnEsc = disabled ? false : shouldCloseOnEsc;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      shouldCloseOnOverlayClick={localShouldCloseOnOverlayClick}
      shouldCloseOnEsc={localShouldCloseOnEsc}
      style={customStyles}
      {...otherProps}
    >
      {!disabled && (
        <button
          className="light shadow-none rounded-lg ml-auto w-4 h-4 absolute top-[20px] right-[20px]"
          onClick={() => onClose()}
        >
          <Icon className="w-3 h-3 text-darkGrey ml-auto dark:text-grey-100" iconName="close"/>
        </button>
      )}
      {children}
    </ReactModal>
  );
};

export default Modal;
