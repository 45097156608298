import React, {useMemo, useState} from "react";
import {Creator, SmallUser, userId} from '../../types'
import SmallAvatar from "../SmallAvatar/SmallAvatar";
import Icon from "../Icons/Icon";
import ActionMenu from "../ActionMenu/ActionMenu";
import LikeButton from "../LikeButton/LikeButton";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {addAttendingUserToPlan, deletePlan, removeAttendingUserFromPlan, updateIdea} from "../../store/planSlice";
import {toast} from "react-toastify";
import {Link, useRouteMatch} from "react-router-dom";

export interface PlanCardProps {
  id: number;
  name: string;
  creator: Creator;
  attending: userId[];
  attending_details: SmallUser[];
  showAttending: boolean;
  notes?: string | null;
  url?: string | null;
  emoji?: string | null;
  address?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  onEditIdea: (id: number) => void;
  onLocationClick?: (planId: number) => void;
  completed: boolean;
  lat: number | null;
  lng: number | null;
}

const PlanCard = ({
                    id,
                    name,
                    notes,
                    creator,
                    url,
                    emoji,
                    address,
                    city,
                    state,
                    postal_code,
                    onEditIdea,
                    attending,
                    attending_details,
                    showAttending,
                    completed,
                    lat,
                    lng,
                    onLocationClick
                  }: PlanCardProps) => {
  const dispatch = useAppDispatch()
  const {user} = useAppSelector(auth => auth.auth)
  const {owner} = useAppSelector((state) => state.trip.trip)
  const [userIsAttending, setUserIsAttending] = useState<boolean>(isUserAttending())
  const [completedChecked, setCompletedChecked] = useState<boolean>(completed);
  const canEdit = (creator?.id === user?.id) || (owner?.id === user?.id)
  let {path, url: routeUrl} = useRouteMatch();

  function isUserAttending(): boolean {
    let isUserAttending = false;
    if (!attending) {
      return false;
    }
    attending.forEach((attendee) => {
      if (attendee === user?.id) {
        isUserAttending = true;
      }
    })
    return isUserAttending
  }

  function handleLike() {
    if (user) {
      dispatch(addAttendingUserToPlan({planId: id, userId: user.id})).then(() => {
        setUserIsAttending(true)
      })
    }
  }

  function handleUnLike() {
    if (user) {
      dispatch(removeAttendingUserFromPlan({planId: id, userId: user.id})).then(() => {
        setUserIsAttending(false)
      })
    }
  }

  function handleMarkPlanCompleted(id: number) {
    //  Mark plan completed
    setCompletedChecked(!completedChecked)
    dispatch(updateIdea({planId: id, completed: !completedChecked}))
  }

  function handleDelete(id: number) {
    try {
      dispatch(deletePlan(id))
      toast.success(`💥 Deleted Idea.`)
    } catch (err) {
      toast.error(`😟 Something went wrong - ${err.message}`)
    }
  }

  function renderLocationAndWebsite() {
    if (address || city || state || postal_code || url) {
      const fullAddress = `${address}+${city}+${state}+${postal_code}`
      return (
        <div className="mt-3 flex flex-wrap space-x-2">
          {lat && lng && onLocationClick &&
          <button
            className="py-1 px-2 rounded-full bg-white text-xs flex flex-row items-center border border-lightGray transition hover:text-brand dark:bg-grey-700 dark:border-0 dark:text-white dark:hover:bg-brand"
            onClick={() => {
              if (onLocationClick) {
                onLocationClick(id)
              }
            }}>
            <Icon className="w-4 h-4 transition pr-1" iconName="map-pin"/>
            View on map
          </button>
          }

          {(address || city || state || postal_code) && !lat && !lng &&
          <a className="py-1 px-2 rounded-full bg-white text-xs flex flex-row items-center border border-lightGray hover:text-brand dark:bg-grey-700 dark:border-0 dark:hover:bg-brand dark:hover:text-white"
             href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`} target="_blank" rel="noreferrer noopener">
            <Icon className="w-4 h-4 transition pr-1" iconName="map-pin"/>
            Location
          </a>
          }
          {url &&
          <a className="py-1 px-2 rounded-full bg-white text-xs hover:text-brand flex flex-row items-center border border-lightGray dark:bg-grey-700 dark:text-white dark:border-0 dark:hover:bg-brand" href={url} target="_blank" rel="noopener noreferrer">
            <Icon className="w-4 h-4 pr-1" iconName="external-link"/>
            <span className="pt-1">Website</span>
          </a>}
        </div>
      )
    }
    return null
  }

  return (
    <div key={`${id}-plan`}
         className={`${showAttending ? 'md:pb-0' : ''} bg-offWhite shadow-md p-3 sm:p-4 md:p-5 rounded-xl relative overflow-visible flex flex-col justify-between dark:bg-grey-800 dark:text-white`}>

      {/* Emoji */}
      {emoji &&
      <div className="absolute top-[-20px] text-xs md:text-base rounded-full w-10 h-8 sm:w-12 sm:h-10 flex justify-center items-center border-2 border-white bg-offWhite dark:border-darkGrey dark:bg-black">
        {emoji}
      </div>
      }

      {/* Menu */}
      <div className="flex flex-col space-y-2">
        <div className="flex justify-between items-center space-x-2 mt-2">
          <Link to={`${routeUrl}/plan/${id}`} className="font-bold md:leading-none md:text-lg inline-flex dark:text-brandLight hover:underline">
            {name}
          </Link>
          <div className="flex flex-row absolute right-0 top-[-10px]">
            <button>
              <label
                className="text-xs md:text-sm py-1 px-1 md:px-2 bg-white rounded-full border-2 border-offWhite flex flex-row items-center hover:cursor-pointer hover:border-brand transition dark:border-0 dark:bg-grey-600 dark:hover:bg-brand">
                <input className="mr-1 md:mr-2 w-4 h-4 text-brand rounded-full ring-1 ring-0 focus:ring-brand dark:ring-brand" type="checkbox" checked={completedChecked} onChange={() => handleMarkPlanCompleted(id)}/>
                <span>Done</span>
              </label>
            </button>
            {/* Show menu if you are the plan creator or trip owner */}
            {canEdit && <ActionMenu name={name} onEdit={() => onEditIdea(id)} onDelete={() => handleDelete(id)}/>}
          </div>
        </div>

        {notes &&
        <p className="text-xs md:text-sm text-gray">{notes}</p>
        }

        {renderLocationAndWebsite()}
      </div>

      {/* Footer */}
      {showAttending &&
      <div className="mb-1 mt-2 md:mt-4 pb-0 md:pb-4">
        <hr className="border-lightGray mb-2 dark:border-grey-700"/>
        <div className="flex items-center">
          {creator &&
            <Link to={`/profile-${creator.id}`}>
              <span className="sr-only">View {creator.first_name ? creator.first_name : creator.username}'s Profile</span>
              <SmallAvatar name={creator.first_name ? creator.first_name : creator.username} image={creator.profile.image_thumbnail} showNameOnHover/>
            </Link>
          }
          {attending_details && attending_details.length > 0 &&
          attending_details.map((attendee) => (
            <Link key={`${attendee.id}-profile-link`} to={`/profile-${attendee.id}`}>
              <span className="sr-only">View {attendee.first_name ? attendee.first_name : attendee.username}'s Profile</span>
              <SmallAvatar key={`${attendee.id}-attendee`} name={attendee.first_name ? attendee.first_name : attendee.username} image={attendee.profile?.image_thumbnail} showNameOnHover/>
            </Link>
          ))}
        </div>
      </div>
      }

      {user?.id !== creator?.id &&
      <>
        {userIsAttending
          ? <LikeButton onLikeAction={() => handleUnLike()} active/>
          : <LikeButton onLikeAction={() => handleLike()} active={false}/>
        }
      </>
      }
    </div>
  )
}

export default PlanCard;
