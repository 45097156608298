import React, {useEffect, useState} from "react"
import AuthLayout from "../Containers/AuthLayout";
import {BASE_URL, getIdFromLocalStorage, getTokenFromLocalStorage, logout, useAppSelector} from "../store/store";
import {useAppDispatch} from "../store/store";
import {useHistory} from "react-router-dom";
import Button from "../Components/Button/Button";
import useQuery from "../utils/useQuery";
import {AppQueryStrings} from "../types";
import {toast} from "react-toastify";
import Loading from "../Components/Loading/Loading";
import {fetchUserById} from "../store/authSlice";

/*
* Note: This component has two jobs of reusing the api /verify-email and the react /verify-email endpoints to send new verify email as well as
* redirect the user if the query params are send with the email address from the email template
* */
const VerifyEmailPage = () => {
  const {auth} = useAppSelector((state) => state)
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useQuery();
  const [verifying, setVerifying] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);

  useEffect(() => {
    /* Explanation: Verify emails contain a url to the app eg. https://www.tripfomo.com/verify-email
    * That directs us to this view at this route however the method for actually verifying is part of the API endpoint
    *  which is {BASE_URL}/verify-email?uidb64={uidb64_from_email_generator)&t={verify_email_token}}
    * */

    const uidb64 = query.get(AppQueryStrings.base_64_uid_verify_email);
    const verify_email_token = query.get(AppQueryStrings.verify_email_token)

    if (auth.user?.email_verified || !auth.user && !uidb64 && !verify_email_token) {
      // If there is no user then direct to login. If there is a user and they are verified redirect
      history.push('/')
    }

    if (uidb64 && verify_email_token) {
      handleVerifyEmail(uidb64, verify_email_token)
        .then((res) => {
          if (res.email_verified) {
            const token = getTokenFromLocalStorage()
            if (token) {
              dispatch(fetchUserById())
            }
            history.push('/')
          }
        })
    }

  }, [auth.status])

  const handleVerifyEmail = async (uidb64: string, verify_email_token: string) => {
    try {
      setVerifying(true)
      const response = await fetch(
        // Cant be at api/ because the React router for the website must match tripfomo.com/verify-email
        `${BASE_URL}/verify-email/?uidb64=${uidb64}&t=${verify_email_token}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      if (response.status === 200) {
        setVerifying(false)
        toast.success('Email verified!')
        return (await response.json());
      } else {
        setVerifying(false)
        throw Error(response.statusText)
      }
    } catch (e) {
      setVerifying(false)
      toast.error(`${e}`)
      return e
    }
  }

  const handleSendEmail = async () => {
    try {
      const response = await fetch(
        // Cant be at api/ because the react router for the website must match tripfomo.com/verify-email
        `${BASE_URL}/verify-email/resend_verify_email/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Token ' + getTokenFromLocalStorage()
          }
        }
      )
      if (response.status === 204) {
        toast.success('Email sent!')
      } else {
        throw Error(response.statusText)
      }
    } catch (e) {
      toast.error(`There was an error sending the email, please try again later or contact support. ${e}`)
      console.log('Error sending email', e)
    }
  }

  return (
    <AuthLayout formTitle={verifying ? "Verifying Email" : "Verify Email"}
                hideSupportingText
                pageLink={
                  <button
                    className="w-full inline-flex justify-center text-white font-medium py-2 px-2 text-center bg-black w-full bottom-0 rounded-t-none rounded-b-xl dark:text-white dark:bg-darkGrey hover:bg-darkGrey"
                    onClick={() => {
                      dispatch(logout())
                      history.push('/login')
                    }}
                  >
                    Logout
                  </button>
                }>
      <div className="w-full flex flex-col justify-center">
        {verifying ?
          <Loading className={"mt-3"}/> :
          <>
            <p className="text-lg">You are logged in but you will need to verify your email before using Tripfomo. If you need the email again please resend using the button below.</p>
            <Button onClick={() => {
              setSendingEmail(true)
              handleSendEmail().finally(() => {
                setSendingEmail(false)
              })
            }} style={"brand"} classes={"mt-5 self-center"} disabled={sendingEmail}>{sendingEmail ? "Sending.." : "Resend Email"}</Button>
          </>
        }

      </div>
    </AuthLayout>
  )
}

export default VerifyEmailPage;
