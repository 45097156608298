import React, {useEffect, useState, useCallback, useMemo} from "react";
import {useHistory} from 'react-router-dom'
import Dashboard from "../Layout/Dashboard";
import SidebarNav from "../Components/SidebarNav";
import {useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "../store/store";
import {fetchPublicUser} from "../store/authSlice";
import {User, userId, Profile} from "../types";
import {unwrapResult} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {getName} from "../utils/utils";
import Button from "../Components/Button/Button";
import {cancelSentInvite, fetchInvitedBuddies, removeBuddy, sendBuddyRequest} from "../store/buddySlice";
import Icon from "../Components/Icons/Icon";
import ConfirmationDialog from "../Components/ConfirmationDialog/ConfirmationDialog";
import ContentHeading from "../Components/ContentHeading/ContentHeading";
import Loading from "../Components/Loading/Loading";
import {clearActiveSidebar, clearStickyFooter} from "../store/ui";

const PublicProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  let {id} = useParams<Record<string, string>>();
  const {invites} = useAppSelector(state => state.buddyInvites)
  // @ts-ignore
  const myUserId = useAppSelector((state) => state.auth.user?.id)
  const [user, setUser] = useState<User | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showCancelInviteModal, setShowCancelInviteModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const fetchUser = useCallback(async () => {
    try {
      setLoading(true)
      const response: any = await dispatch(fetchPublicUser(id))
      const publicUser = unwrapResult(response)
      setUser(publicUser)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast.error(`😟 Something went wrong - ${err.message}`)
    }
  }, [id])

  function handleRemoveFromBuddies(buddyId: userId, name: string) {
    dispatch(removeBuddy(buddyId))
    history.push('/')
    toast(`${name} Removed`)
  }

  function handleCancelSentInvite(inviteId: number, name: string) {
    dispatch(cancelSentInvite(inviteId))
    toast(`Canceled invite to ${name}`)
  }

  // Fetch user for this public profile page
  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  // Fetch your invited buddies
  useEffect(() => {
    dispatch(fetchInvitedBuddies())
  }, [dispatch])

  // Clear sticky footer and sidebar if visited from trip (this is for mobile)
  useEffect(() => {
    dispatch(clearStickyFooter())
    dispatch(clearActiveSidebar())
  }, [])

  async function handleBuddyInvite(receiver: userId) {
    try {
      const response: any = await dispatch(sendBuddyRequest(receiver))
      const result = unwrapResult(response)
      toast(`Invite sent!`)
    } catch (err) {
      toast.error(`😟 Something went wrong - ${err.message}`)
    }
  }

  //TODO: Change parseInt(id) when id's are converted too uuid
  const userIsAlreadyTravelBuddy = useMemo(() => {
    return user?.profile.buddies?.some((buddyId) => buddyId === myUserId)
  }, [myUserId, user])

  const sentBuddyInvite = useMemo(() => {
    return invites.find((invite) => invite.receiver.id === id)
  }, [invites, id])


  const renderActions = () => {
    if (userIsAlreadyTravelBuddy) {
      return <Button style="dark" onClick={() => setShowDeleteModal(true)}>Remove travel buddy</Button>
    }
    if (sentBuddyInvite) {
      return <Button style="dark" onClick={() => setShowCancelInviteModal(true)}>Cancel buddy invite</Button>
    }
    // User isn't you
    if (id !== myUserId) {
      return <Button style="dark" onClick={() => handleBuddyInvite(id)}>Invite as travel buddy</Button>
    }
    return
  }

  const renderSocialMedia = (profile: Profile) => {
    if (!profile.instagram || !profile.pinterest) {
      return;
    }
    // If there's at least one social media then show block
    return (
      <div className="py-2 space-x-4 flex flex-row">
        {profile.instagram &&
        <a className="group transition" href={`https://www.instagram.com/${profile.instagram}`} target="_blank" rel="noreferrer noopener">
          <Icon className="text-darkGrey w-5 h-5 group-hover:text-brand transition dark:text-grey-200" iconName="instagram"/>
        </a>
        }
        {profile.pinterest &&
        <a className="group transition" href={`https://www.pinterest.com/${profile.pinterest}`} target="_blank" rel="noreferrer noopener">
          <Icon className="text-darkGrey w-5 h-5 group-hover:text-brand transition dark:text-grey-200" iconName="pinterest"/>
        </a>
        }
      </div>
    )
  }

  return (
    <Dashboard sidebarChildren={<SidebarNav/>}>
      <>
        {loading && <Loading/>}
        {user && !loading &&
          <div>
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between  mb-6 md:mb-10">
              <div>
                <ContentHeading style="brand" text={`Hi i'm ${getName(user)}`}>
                  {myUserId !== id &&
                  <span className="text-gray text-sm">({getName(user)} {userIsAlreadyTravelBuddy ? `is your travel buddy` : `is not your travel buddy`})</span>
                  }
                </ContentHeading>
              </div>

              {/* Actions */}
              {myUserId !== id &&
                <div className="shrink-0 mt-4 md:mt-0">
                  {renderActions()}
                </div>
              }
            </div>

            {/* Top */}
            <div className="flex flex-col lg:flex-row gap-6 md:gap-8 p-4 md:p-6 rounded-md shadow-md dark:bg-grey-800">

              {user.profile.image_thumbnail
                ? <img src={user.profile.image_thumbnail} className="w-24 h-24 md:w-32 md:h-32 rounded-full" alt="Your Profile Photo"/>
                : <Icon iconName="person" className="w-24 h-24 md:w-32 md:h-32 text-lightGray"/>
              }

              <div className="flex space-y-1 flex-col">
                {user.profile.traveler_type &&
                <span className="text-brand md:text-lg leading-snug">{user.profile.traveler_type}</span>
                }
                <div className="space-x-1">
                  <span className="pr-2 font-semibold">{user.profile.buddies?.length}</span>
                  <span>Travel Buddies</span>
                </div>
                <div className="space-x-1">
                  <span className="pr-2 font-semibold">{user.trips_count}</span>
                  <span>Trips</span>
                </div>
                {/* TODO: Get country count */}
                {/*<div className="space-x-1">*/}
                {/*  <span className="pr-2 font-semibold">1</span>*/}
                {/*  <span>Countries Traveled To</span>*/}
                {/*</div>*/}

                {renderSocialMedia(user?.profile)}

              </div>
            </div>

            {/* Bio */}
            <div className="lg:max-w-4xl mt-6 md:mt-8">
              {user.profile.bio &&
              <div>
                <h3 className="">About me</h3>
                {user.profile.bio}
              </div>
              }
            </div>

          </div>
        }
        {!user && !loading &&
          <h3>No user found 😔</h3>
        }
        <Button classes="mt-8 lg:mt-10" onClick={() => history.goBack()} style="outline-dark">Go back</Button>
      </>

      {/* Cancel invite dialog */}
      <ConfirmationDialog
        confirmText="Yes! Do it!"
        text={`Are you sure you want to cancel your invite to ${getName(user)}`}
        isOpen={showCancelInviteModal}
        onClose={() => setShowCancelInviteModal(false)}
        onConfirm={() => {
          if (sentBuddyInvite) {
            handleCancelSentInvite(sentBuddyInvite.id, getName(sentBuddyInvite.receiver))
          }
          setShowCancelInviteModal(false)
        }}/>

      {/* Delete Confirm Dialog */}
      <ConfirmationDialog
        confirmText="Yes! Do it!"
        text={`Are you sure you want to remove ${getName(user)} from your buddies?`}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={() => {
          if (user) {
            handleRemoveFromBuddies(user.id, getName(user))
          }
          setShowDeleteModal(false)
        }}/>

    </Dashboard>
  )
}

export default PublicProfile;
