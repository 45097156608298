import {BASE_URL, getTokenFromLocalStorage} from './store'
import {combineReducers, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {FetchState, Accommodation, accommodationId, tripId, Plan, Coordinates, userId} from "../types";
import {plansSlice} from "./planSlice";

// When accommodation is updated or deleted
export interface accommodationChangingState {
  status: FetchState;
  errorDetail: any;
}

type AccommodationState = {
  accommodations: Record<number, Accommodation>;
  status: FetchState;
};

const initialAccommodations = {
  accommodations: {},
  status: FetchState.Idle
} as AccommodationState


export const fetchAccommodationsForTrip = createAsyncThunk<Accommodation[], string>(
  'accoms/fetchAccommodations',
  async (tripId) => {
    const response = await fetch(
      BASE_URL + '/api/trips/' + tripId + '/accommodations/',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    )
    return await response.json();
  }
)

interface AccommodationProps {
  accommodationId?: number;
  trip: tripId;
  creator: userId | undefined;
  name: string;
  notes: string;
  check_in_date?: string;
  check_out_date?: string;
  website: string;
  address?: string;
  city?: string;
  postal_code?: string;
  state?: string;
  lat: number | null;
  lng: number | null;
}

export const addAccommodation = createAsyncThunk<Accommodation, AccommodationProps>(
  'accoms/addAccommodation',
  async (props) => {
    const response = await fetch(
      BASE_URL + '/api/accommodation/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
          ...props,
        })
      }
    )
    return await response.json();
  }
)

export const addAccomSlice = createSlice({
  name: 'addAccommodation',
  initialState: {} as accommodationChangingState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(addAccommodation.pending, (state) => {
      state.status = FetchState.Loading;
    });
    builder.addCase(addAccommodation.fulfilled, (state, {payload}) => {
      state.status = FetchState.Fulfilled;
    });
    builder.addCase(addAccommodation.rejected, ((state, {payload}) => {
      state.status = FetchState.Failed
      state.errorDetail = payload || [];
    }));
  }
})

export const updateAccommodation = createAsyncThunk<Accommodation, AccommodationProps>(
  'accoms/updateAccommodation',
  async (props) => {
    const response = await fetch(
      `${BASE_URL}/api/accommodation/${props.accommodationId}/`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
          ...props,
        })
      }
    )
    return await response.json();
  }
)

export const deleteAccommodation = createAsyncThunk<accommodationId, accommodationId>(
  'accoms/deleteAccommodation',
  async (id) => {
    await fetch(
      `${BASE_URL}/api/accommodation/${id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    );
    return id;
  }
)

export const deletingAccommodation = createSlice(
  {
    name: 'deletingAccommodation',
    initialState: {} as accommodationChangingState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(deleteAccommodation.pending, (state, {payload}) => {
        state.status = FetchState.Loading
        state.errorDetail = payload || []
      });
      builder.addCase(deleteAccommodation.fulfilled, (state, {payload}) => {
        state.status = FetchState.Fulfilled
        state.errorDetail = payload || []
      })
      builder.addCase(deleteAccommodation.rejected, (state, {payload}) => {
        state.status = FetchState.Failed
        state.errorDetail = payload || []
      });
    }
  }
)


export const accomsSlice = createSlice({
  name: 'accomsObject',
  initialState: initialAccommodations,
  reducers: {
    clearAccoms(state) {
      state.accommodations = {}
      state.status = FetchState.Idle
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAccommodationsForTrip.pending, (state) => {
      state.status = FetchState.Loading;
    });
    builder.addCase(fetchAccommodationsForTrip.fulfilled, (state, {payload}) => {
      state.status = FetchState.Fulfilled;
      state.accommodations = {}
      if (payload.length) {
        payload.forEach((accommodation) => {
          accommodation.objectState = FetchState.Fulfilled;
          state.accommodations[accommodation.id] = {...accommodation}
        });
      }
    });
    builder.addCase(fetchAccommodationsForTrip.rejected, ((state, {payload}) => {
      state.status = FetchState.Failed
    }));
    builder.addCase(addAccommodation.fulfilled, (state, {payload}) => {
      state.accommodations = {...state.accommodations, [payload.id]: payload}
      state.status = FetchState.Fulfilled;
    });
    builder.addCase(updateAccommodation.fulfilled, (state, {payload}) => {
      state.accommodations[payload.id] = {...payload}
      state.status = FetchState.Fulfilled;
    });
    builder.addCase(deleteAccommodation.fulfilled, (state, {payload}) => {
      delete state.accommodations[payload]
    });
  }
})

export const {clearAccoms} = accomsSlice.actions

export default combineReducers({
  addAccom: addAccomSlice.reducer,
  accomsObject: accomsSlice.reducer,
})

