import React, {useEffect} from 'react';
import {Route, Redirect, RouteProps, RouteComponentProps} from 'react-router-dom';
import {getIdFromLocalStorage, getTokenFromLocalStorage, useAppDispatch, useAppSelector} from "../../store/store";
import {FetchState} from "../../types";
import Loading from "../Loading/Loading";
import Dashboard from "../../Layout/Dashboard";

type LocationState = {
  from: string;
};

type PrivateRouteProps = {
  children: React.ReactNode;
} & RouteProps;

//@ts-ignore
const PrivateRoute = ({ children, loadingComponent,  ...rest }: PrivateRouteProps) => {
  const { auth } = useAppSelector((state) => state);
  const token = getTokenFromLocalStorage();
  const isEmailVerified = auth.user?.email_verified;

  //Default loading component
  loadingComponent = <Dashboard loading={auth.status === FetchState.Loading}/>

  const renderRoute = ({ location }: RouteComponentProps<LocationState>) => {
    if (token) {
      // We have the token so we get the user and show the loadingComponent for now
      if (auth.status === FetchState.Loading) {
        return loadingComponent
      }
      // Loading is done lets check if the user is verified
      if (isEmailVerified) {
        return children
      } else {
          return (
            <Redirect
              to={{
                pathname: '/verify-email',
                state: { from: location },
              }}
            />
          );
      }
    } else {
      // No auth token, so redirect to login
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      );
    }
  };

  return <Route {...rest} render={renderRoute} />;
};
export default PrivateRoute;
