import React, {useEffect, useState} from 'react'
import {unwrapResult} from '@reduxjs/toolkit'
import Dashboard from "../Layout/Dashboard";
import SidebarNav from "../Components/SidebarNav";
import ContentHeading from "../Components/ContentHeading/ContentHeading";
import {useAppDispatch, useAppSelector} from "../store/store";
import {fetchBuddies, fetchBuddyRequests, fetchInvitedBuddies, searchBuddies, removeBuddy, deleteBuddyRequest, cancelSentInvite, acceptBuddyRequest} from "../store/buddySlice";
import TravelBuddyCard from "../Components/TravelBuddyCard/TravelBuddyCard";
import {getName} from "../utils/utils";
import Button from "../Components/Button/Button";
import Icon from "../Components/Icons/Icon";
import {FetchState, User, userId} from "../types";
import TravelBuddiesSearch from "../Components/TravelBuddiesSearch/TravelBuddiesSearch";
import {toast} from "react-toastify";
import ConfirmationDialog from "../Components/ConfirmationDialog/ConfirmationDialog";
import {clearStickyFooter} from "../store/ui";
import Loading from "../Components/Loading/Loading";

const TravelBuddies = () => {
  const dispatch = useAppDispatch()
  const {buddies} = useAppSelector((state) => state.buddies)
  const {status: buddiesStatus} = useAppSelector((state) => state.buddies)
  const {invites} = useAppSelector(state => state.buddyInvites)
  const {requests} = useAppSelector(state => state.buddyInvites)
  const [users, setUsers] = useState<User[] | null>([])
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [hasSearched, setHasSearched] = useState<boolean>(false)

  function onEnterSearchTerm(query: string) {
      setSearchValue(query)
      setLoading(true)
      setHasSearched(true)
  }

  function handleRemoveFromBuddies(buddyId: userId, name: string) {
    dispatch(removeBuddy(buddyId))
    toast(`${name} Removed`)
  }

  function handleRejectBuddyRequest(inviteId: number, name: string) {
    dispatch(deleteBuddyRequest(inviteId))
    toast(`Rejected invite from ${name}  💔`)
  }

  function handleCancelSentInvite(inviteId: number, name: string) {
    dispatch(cancelSentInvite(inviteId))
    toast(`Canceled invite to ${name}`)
  }

  function handleAcceptBuddyRequest(inviteId: number, sender: User) {
    dispatch(acceptBuddyRequest({inviteId, sender}))
    toast(`${getName(sender)} is now your travel buddy`)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(searchBuddies(searchValue))
        .then(unwrapResult)
        .then(result => {
          setUsers(result)
          setLoading(false)
        })
        .catch(rejectedValueOrSerializedError => {
          console.log(rejectedValueOrSerializedError)
          setLoading(false)
        })
    }, 600)
    return () => clearTimeout(timeout)
  }, [searchValue])

  // Fetch travel buddies
  useEffect(() => {
    dispatch(fetchBuddies())
  }, [dispatch])

  // Fetch your invited buddies
  useEffect(() => {
    dispatch(fetchInvitedBuddies())
  }, [dispatch])

  // Fetch buddies who invited you
  useEffect(() => {
    dispatch(fetchBuddyRequests())
  }, [dispatch])

  // Clear sticky footer if there is one
  useEffect(() => {
    dispatch(clearStickyFooter())
  }, [])

  return (
    <Dashboard sidebarChildren={<SidebarNav/>}>
      <div>

        <div className="grid grid-cols-1 gap-10">
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4 md:gap-8">
            <div className="mb-4 md:mb-0 max-w-lg">
              <ContentHeading style="dark" text="Travel buddies"/>
              <p className="">Travel buddies are just like friends or followers, once on your list they can be invited to trips. </p>
            </div>
            <Button onClick={() => setShowSearch(!showSearch)} style="brand" classes="shrink-0 whitespace-nowrap">
              {showSearch ?
                'View travel buddies' :
                <>
                  Find travel buddies
                  <Icon className="w-4 h-4 text-white ml-3" iconName="magnifying-glass"/>
                </>
              }
            </Button>
          </div>

          {showSearch ?
            <TravelBuddiesSearch onEnterSearchTerm={value => onEnterSearchTerm(value)} buddies={users} loading={loading} hasSearched={hasSearched}/>
            :
            <>
              {requests.length > 0 &&
              <div>
                <div className="mb-6">
                  <h3 className="font-bold text-xl md:text-2xl text-brand">Received buddy invites</h3>
                  <p className="text-gray">People that have invited you to be their travel buddy</p>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
                  {requests && requests.map((invite) => (
                    <TravelBuddyCard
                      id={invite.sender.id}
                      key={invite.id}
                      name={getName(invite.sender)}
                      traveler_type={invite.sender.profile.traveler_type}
                      image={invite.sender.profile.image_thumbnail}
                      instagram={invite.sender.profile.instagram}
                      pinterest={invite.sender.profile.pinterest}
                      type="request"
                      onCancelRequest={() => handleRejectBuddyRequest(invite.id, getName(invite.sender))}
                      onAcceptRequest={() => handleAcceptBuddyRequest(invite.id, invite.sender)}/>
                  ))}
                </div>
              </div>
              }

              {invites.length > 0 &&
              <div>
                <div className="mb-6">
                  <h3 className="font-bold text-xl md:text-2xl text-brand">Sent buddy invites</h3>
                  <p className="text-gray">People you have invited to be your travel buddy</p>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
                  {invites.map((invite) => (
                    <TravelBuddyCard
                      id={invite.receiver.id}
                      key={invite.id}
                      name={getName(invite.receiver)}
                      traveler_type={invite.receiver.profile.traveler_type}
                      image={invite.receiver.profile.image_thumbnail}
                      instagram={invite.receiver.profile.instagram}
                      pinterest={invite.receiver.profile.pinterest}
                      type="invited"
                      onCancelInvite={() => handleCancelSentInvite(invite.id, getName(invite.receiver))}/>
                  ))}
                </div>
              </div>
              }

              <div className="flex flex-col">

                <div className="flex md:flex-row justify-between items-center mb-6">
                  <div>
                    <h3 className="font-bold text-xl md:text-2xl text-brand">Buddies list</h3>
                    <p className="text-gray">A list of your current travel buddies</p>
                  </div>
                  <span className="font-bold text-sm pb-2 text-right">{buddies.length} Total</span>
                </div>

                <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
                  {buddies.length > 0 && buddies.map((buddy) => (
                      <TravelBuddyCard
                        id={buddy.id}
                        key={buddy.id}
                        image={buddy.profile.image_thumbnail}
                        instagram={buddy.profile.instagram}
                        pinterest={buddy.profile.pinterest}
                        name={getName(buddy)}
                        traveler_type={buddy?.profile.traveler_type}
                        type="buddy"
                        onRemoveBuddy={() => {
                          handleRemoveFromBuddies(buddy.id, getName(buddy))
                        }}/>
                  ))}
                </div>

                {buddiesStatus === FetchState.Loading &&
                  <Loading/>
                }

                {buddies.length < 1 && buddiesStatus !== FetchState.Loading &&
                  <div className="p-4 mt-4 bg-offWhite rounded-lg dark:bg-grey-700">
                    <h4 className="font-bold text-xl md:text-xl mb-2">Awe, you have no travel buddies yet.</h4>
                    <p>Don't worry! You can use the search button to find and invite your first buddy. If they aren't on Tripfomo yet, tell them to signup. 😀</p>

                    <button onClick={() => setShowSearch(!showSearch)} className="font-sm text-brand p-0 hover:underline pt-4 dark:text-brandLight dark:underline">
                          Find travel buddies
                    </button>
                  </div>
                }
              </div>
            </>
          }
        </div>
      </div>


    </Dashboard>
  )
}

export default TravelBuddies
