import {apiFetch} from "./apiFetch";
import {BASE_URL, getTokenFromLocalStorage, RootState} from './store'
import {combineReducers, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {FetchState, FieldQuill, Plan, tripId, TripMessage, User, userId} from "../types";
import {deletePlan, PlanDeleteState} from "./planSlice";
import TripMessages from "../Components/TripMessages/TripMessages";

type TripMessagesState = {
  messages: Record<string|string, TripMessage>;
  status: FetchState;
};

export interface TripMessageProps {
  content: string;
  posted_by: userId;
  tripId: string | number;
}

const initialMessages = {
  messages: {},
  status: FetchState.Idle
} as TripMessagesState

export const addTripMessage = createAsyncThunk<TripMessage, TripMessageProps, {state: RootState, rejectValue: string[]}>(
  'trip/addTripMessage',
  async ({content, posted_by, tripId}, {getState, rejectWithValue}) => {
    return apiFetch(`${BASE_URL}/api/trip-messages/`, {
      method: 'POST',
      json: {
        trip: tripId,
        content,
        posted_by,
      },
      getState,
      rejectWithValue
    })
  }
)

export const fetchTripMessages = createAsyncThunk<Record<string|string, TripMessage>, string | number, { state: RootState, rejectValue: string[] }>(
  'trip/fetchTripMessages',
  async (tripId, {getState, rejectWithValue, signal}) => {
    return apiFetch(`${BASE_URL}/api/trips/${tripId}/messages/`, {
      method: 'GET',
      getState,
      rejectWithValue,
      signal
    })
  }
)

export interface messageCreatingState {
  status: FetchState;
  errorDetail: any;
}

export const addTripMessageSlice = createSlice({
  name: 'addTripMessage',
  initialState: {} as messageCreatingState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(addTripMessage.pending, (state) => {
      state.status = FetchState.Loading;
    });
    builder.addCase(addTripMessage.fulfilled, (state, {payload}) => {
      state.status = FetchState.Fulfilled;
    });
    builder.addCase(addTripMessage.rejected, ((state, {payload}) => {
      state.status = FetchState.Failed
      state.errorDetail = payload || []
    }));
  }
})

export const deleteTripMessage = createAsyncThunk<number, number, { state: RootState, rejectValue: string[] }>(
  'trip/deleteTripMessage',
  async (id, {getState, rejectWithValue, signal}) => {
    return apiFetch(`${BASE_URL}/api/trip-messages/${id}/`, {
      method: 'DELETE',
      getState,
      rejectWithValue,
      signal
    })
  }
)

export interface TripMessageDeletingState {
  status: FetchState;
  errorDetail: string[]
}

export const deletingTripMessageSlice = createSlice(
  {
    name: 'deletingTripMessage',
    initialState: {} as Record<number, TripMessageDeletingState>,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(deleteTripMessage.pending, (state, action) => {
        state[action.meta.arg] = {
          status: FetchState.Loading,
          errorDetail: []
        }
      });
      builder.addCase(deleteTripMessage.fulfilled, (state, action) => {
        state[action.meta.arg] = {
          status: FetchState.Fulfilled,
          errorDetail: [],
        }
      })
      builder.addCase(deleteTripMessage.rejected, (state, action) => {
        state[action.meta.arg] = {
          status: FetchState.Failed,
          //@ts-ignore
          errorDetail: action.payload || [],
        };
      });
    }
  }
)

export interface updateTripMessageProps {
  id: number;
  content: string;
}

export const updateTripMessage = createAsyncThunk<TripMessage, updateTripMessageProps>(
  'trips/updateTripMessage',
  async ({id, content}, {getState, rejectWithValue, signal}) => {
    return apiFetch(`${BASE_URL}/api/trip-messages/${id}/`, {
      method: 'PATCH',
      json: {
        content,
      },
      getState,
      rejectWithValue,
      signal
    })
  }
);

export const tripMessagesSlice = createSlice({
  name: 'tripMessagesSlice',
  initialState: initialMessages,
  reducers: {
    clearMessages(state) {
      state.messages = {}
      state.status = FetchState.Idle
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchTripMessages.pending, (state) => {
      state.status = FetchState.Loading;
    });
    builder.addCase(fetchTripMessages.fulfilled, (state, {payload}) => {
      state.status = FetchState.Fulfilled;
      state.messages = payload
    });
    builder.addCase(fetchTripMessages.rejected, ((state, {payload}) => {
      state.status = FetchState.Failed
    }));
    builder.addCase(addTripMessage.fulfilled, (state, {payload}) => {
      state.messages = {[`id-${payload.id}`]: payload, ...state.messages}
      state.status = FetchState.Fulfilled;
    });
    builder.addCase(updateTripMessage.fulfilled, (state, {payload}) => {
      state.messages[`id-${payload.id}`] = {...payload}
      state.status = FetchState.Fulfilled;
    });
    builder.addCase(deleteTripMessage.fulfilled, (state, {payload, meta}) => {
      delete state.messages[`id-${meta.arg}`]
    });
  }
})


export default combineReducers({
  addTripMessage: addTripMessageSlice.reducer,
  tripMessages: tripMessagesSlice.reducer,
  deletingTripMessage: deletingTripMessageSlice.reducer,
})
