import React from 'react'
import {Link} from 'react-router-dom'

const Logo: React.FC = () => {
  return (
     <h1 className="text-xl md:text-2xl text-brand font-extrabold"><Link to="/">Tripfomo</Link></h1>
  )
}

export default Logo
