import React from "react";
import {NavLink} from "react-router-dom";
import Icon from "../Icons/Icon";
import {clearActiveSidebar} from "../../store/ui";
import {useAppDispatch} from "../../store/store";

interface TripSubnavMobileButtonProps {
  onClick?: () => void;
  url: string;
  label: string;
  active?: boolean;
  type: 'NavLink' | 'Button'
  icon: string;
  clearSidebarOnClick?: boolean;
  exact?: boolean | undefined;
  classes?: string;
}

const TripSubnavMobileButton = ({onClick, url, icon, label, clearSidebarOnClick, exact, classes}: TripSubnavMobileButtonProps) => {
  const dispatch = useAppDispatch();

  return (
    <NavLink
      activeClassName="text-brand dark:text-brandLight"
      className={`flex justify-center items-center py-3 px-4 ${classes && classes}`}
      to={url}
      onClick={() => {
      {onClick && onClick()}
      {clearSidebarOnClick && dispatch(clearActiveSidebar())}
    }}
    exact={exact ? exact : false}
    >
      <span className="sr-only">{label}</span>
      <Icon className="w-6 h-6 md:w-8 md:h-8" iconName={icon}/>
    </NavLink>
  )
}

export default TripSubnavMobileButton;
