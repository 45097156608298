import React from "react";
import {useRouteMatch} from "react-router-dom";
import Icon from "../Icons/Icon";
import {SidebarTypes, TripViewType} from "../../types";
import {activeSidebarSelector, clearActiveSidebar, showAccomsForm, showIdeaForm, showTripDetails, showTripMessagesForm} from "../../store/ui";
import {useAppDispatch, useAppSelector} from "../../store/store";
import TripSubnavMobileButton from "./TripSubnavMobileButton";

export interface TripSubnavMobileProps {
  classes?: string;
}

const TripSubnavMobile = ({classes}: TripSubnavMobileProps) => {
  let {path, url} = useRouteMatch();
  const dispatch = useAppDispatch();
  const {tripView} = useAppSelector(state => state.ui.activeTripView)

  // Get active sidebar
  const sidebar = useAppSelector(activeSidebarSelector)

  return (
    <div>
      <nav className={`lg:hidden grid grid-cols-5 justify-center items-center font-semibold md:text-md uppercase dark:border-0 dark:bg-grey-950 ${classes && classes}`}>
        <TripSubnavMobileButton
          url={`${url}`}
          label={"Trip plans"}
          type={'NavLink'}
          icon={'clipboard'}
          clearSidebarOnClick
          exact
        />
        <TripSubnavMobileButton
          url={`${url}/accommodations`}
          label={"Trip Accommodations"}
          type={'NavLink'}
          icon={'accommodation'}
          clearSidebarOnClick/>
        <TripSubnavMobileButton
          url={`${url}/messages`}
          label={"Trip Messages"}
          type={'NavLink'}
          icon={'messages'}
          classes={"border-r border-grey-300 "}
          clearSidebarOnClick/>
        <button
          className={`flex justify-center items-center py-3 px-4 ${sidebar === SidebarTypes.TripDetails && 'bg-brand text-white'}`}
          onClick={() => {
            if (sidebar === SidebarTypes.TripDetails) {
              dispatch(clearActiveSidebar())
            } else {
              dispatch(showTripDetails())
            }
          }}>
          <span className="sr-only">Trip details</span>
          <Icon className="w-6 h-6 md:w-8 md:h-8" iconName="identification"/>
        </button>
        {/* Show active styling if trip details isn't open */}
        <button
          className={`flex justify-center items-center py-3 px-4 ${sidebar !== null && sidebar !== SidebarTypes.TripDetails && 'bg-brand text-white'}`}
          onClick={() => {
            if (sidebar !== null && sidebar !== SidebarTypes.TripDetails) {
                dispatch(clearActiveSidebar())
            } else {
              if (tripView === TripViewType.Idea) {
                dispatch(showIdeaForm())
              } else if (tripView === TripViewType.Accommodation) {
                dispatch(showAccomsForm())
              } else if (tripView === TripViewType.TripMessages) {
                dispatch(showTripMessagesForm())
              } else {
                dispatch(showIdeaForm())
              }
            }
          }}>
          <span className="sr-only">Add {tripView}</span>
          <Icon className="w-6 h-6  md:w-8 md:h-8" iconName="circle-plus"/>
        </button>
      </nav>
    </div>
  )
}

export default TripSubnavMobile;
