import React, {useEffect, useRef, useState} from 'react'
import Dashboard from "../Layout/Dashboard";
import SidebarNav from "../Components/SidebarNav";
import FormField from "../Components/Forms/FormField";
import {useAppDispatch, useAppSelector} from "../store/store";
import Icon from "../Components/Icons/Icon";
import Button from "../Components/Button/Button";
import {fetchProfileNotificationSettings, updateProfileNotificationSettings, updateUser} from "../store/authSlice";
import {FetchState, ProfileNotificationSetting, User} from "../types";
import {Form, Formik, Field} from 'formik';
import * as Yup from 'yup';
import {toast} from "react-toastify";
import {unwrapResult} from "@reduxjs/toolkit";
import Loading from "../Components/Loading/Loading";
import {clearStickyFooter} from "../store/ui";
import ProfileNotificationSettings from "../Components/ProfileNotificationSettings/ProfileNotificationSettings";
import HeadingBlock from "../Components/HeadingBlock/HeadingBlock";
import ContentHeading from "../Components/ContentHeading/ContentHeading";
import Toggle from "../Components/Toggle/Toggle";

interface formValues {
  first_name: string | null;
  last_name: string | null;
  bio: string | null;
  pinterest: string | null;
  instagram: string | null;
  country: string | null;
  traveler_type: string | null;
  dark_mode_enabled: boolean;
  receive_trip_message_emails: boolean;
  receive_trip_invite_emails: boolean;
  receive_buddy_invite_emails: boolean;
}

const EditProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .max(40, 'Name too long'),
  last_name: Yup.string()
    .max(40, 'Name too long'),
  bio: Yup.string()
    .max(250, 'Oops that\'s over the limit, reduce your characters!'),
  pinterest: Yup.string()
    .max(50, 'Too long!'),
  instagram: Yup.string()
    .max(50, 'Too long!'),
  country: Yup.string()
    .max(50, 'Too long!'),
  traveler_type: Yup.string()
    .max(30, 'Too long!')
})

const EditProfile: React.FC = () => {
  const dispatch = useAppDispatch();

  const {user} = useAppSelector((state) => state.auth)
  const profileState = useAppSelector((state) => state.auth.status)
  const stateErrors = useAppSelector(state => state.auth.errors)
  const [saveReminder, setSaveReminder] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>()
  const [selectedFile, setSelectedFile] = useState<File>()
  const fileInput = useRef<HTMLInputElement>(null)
  const [notificationSettings, setNotificationSettings] = useState<ProfileNotificationSetting | null>(null);
  const [initialValues, setInitialValues] = useState<formValues>()


  useEffect(() => {
    setInitialValues(
      {
        first_name: user?.first_name || "",
        last_name: user?.last_name || "",
        bio: user?.profile.bio || "",
        pinterest: user?.profile.pinterest || "",
        instagram: user?.profile.instagram || "",
        traveler_type: user?.profile.traveler_type || "",
        country: '',
        receive_trip_message_emails: notificationSettings?.receive_trip_message_emails || false,
        receive_trip_invite_emails: notificationSettings?.receive_trip_invite_emails || false,
        receive_buddy_invite_emails: notificationSettings?.receive_buddy_invite_emails || false,
        dark_mode_enabled: user?.profile.dark_mode_enabled || false,
      }
    )
  }, [notificationSettings, user])


  const fileSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files
    if (!fileList) return;
    setSelectedFile(fileList[0])
    setPreviewImage(URL.createObjectURL(fileList[0]))
    setSaveReminder(true)
  }

  const onFileSelectClick = () => {
    if (fileInput && fileInput.current) {
      fileInput.current.click()
    }
  }

  const editProfileFields: Record<string, JSX.Element> = {
    first_name: (
      <Field type="text" className="form-input" name="first_name" placeholder="Enter your first name"/>
    ),
    last_name: (
      <Field type="text" className="form-input" name="last_name" placeholder="Enter your last name"/>
    ),
    bio: (
      <Field as="textarea" rows={5} className="form-input" name="bio" placeholder="A little about yourself"/>
    ),
    country: (
      <Field type="text" className="form-input" name="country" placeholder="Your country"/>
    ),
    traveler_type: (
      <Field type="text" className="form-input" name="traveler_type" placeholder="Tourist, Explorer, Planner?"/>
    ),
    pinterest: (
      <Field type="text" className="form-input" name="pinterest" placeholder="Pinterest username"/>
    ),
    instagram: (
      <Field type="text" className="form-input" name="instagram" placeholder="Instagram handle"/>
    )
  }

  function handleSubmit(values: formValues, submitting: { setSubmitting: (isSubmitting: boolean) => void }) {
    dispatch(updateUser({image: selectedFile, ...values}))
      .then(unwrapResult)
      .then(() => {
        if (profileState === FetchState.Fulfilled) {
          toast.success('Profile updated, New year new you!')
        }
      })
      .catch((error) => {
        if (profileState === FetchState.Failed) {
          toast.error('Something is borked 😕')
        }
      })

    if (notificationSettings) {
      const {receive_buddy_invite_emails, receive_trip_invite_emails, receive_trip_message_emails} = values;
      dispatch(updateProfileNotificationSettings({id: notificationSettings.id, receive_trip_message_emails, receive_trip_invite_emails, receive_buddy_invite_emails}))
        .then(unwrapResult)
        .then((result) => {
          setNotificationSettings(result)
        })
        .catch((err) => {
          toast.error('There was an issue saving notification settings, sorry😕')
          console.log(err)
        })
    }

    submitting.setSubmitting(false)
    setSaveReminder(false)
  }

  function renderProfileImage() {
    if (previewImage) {
      return <div className="relative w-24 h-24 md:w-32 md:h-32"><img
        className="absolute rounded-full object-cover h-full w-full left-1/2 top-1/2 min-w-full transform -translate-y-1/2 -translate-x-1/2" src={previewImage} alt=""/></div>
    }

    if (user?.profile.image_thumbnail) {
      return <img src={user?.profile.image_thumbnail} className="w-24 h-24 md:w-32 md:h-32 rounded-full" alt="Your Profile Photo"/>
    }

    return <Icon iconName="person" className="w-24 h-24 md:w-32 md:h-32 text-lightGray"/>

  }

  useEffect(() => {
    dispatch(clearStickyFooter())
  }, [])

  return (
    <Dashboard sidebarChildren={<SidebarNav/>}>
      {initialValues &&
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={EditProfileSchema}
          onSubmit={(values: formValues, {setSubmitting}: any) => {
            handleSubmit(values, {setSubmitting})
          }}>
          {({errors, touched, isSubmitting, setFieldValue, handleBlur, values}) => {

            return (
              <Form method="post" encType="multipart/form-data">
                <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-10">
                  <div className="mb-6 md:mb-0">
                    <ContentHeading style="dark" text="My Account"/>
                    <p className="mb-0 text-gray max-w-sm">Manage your profile and account settings here</p>
                  </div>
                  <Button style="dark" type="submit" onClick={() => {
                  }}>{isSubmitting ? 'Saving..' : 'Save'}</Button>
                </div>

                {profileState === FetchState.Failed &&
                  <div className="text-white p-3 rounded-lg bg-red-500 mb-4">
                    {stateErrors &&
                      Object.values(stateErrors).toString()
                    }
                    {' '}Please try again.
                  </div>
                }

                <div className="grid grid-cols-1 gap-6 md:gap-10">
                  <div>
                    <div className="mb-4">
                      <h4>Profile Settings</h4>
                      <p className="text-sm text-gray">Settings in this section will be visible to the public</p>
                    </div>

                    <div className="shadow-sm rounded-lg p-4 md:p-8 grid grid-cols-1 lg:grid-cols-3 relative border border-lightGray dark:border-grey-600">
                      {profileState === FetchState.Loading &&
                        <div className="absolute inset-0 bg-white opacity-30 w-full h-full z-10 dark:bg-grey-900">
                          <div className="flex justify-center items-center text-brand">
                            <Loading/>
                          </div>
                        </div>
                      }
                      <div className="lg:col-span-1 flex flex-col items-center justify-start pt-4 md:pt-0">
                        <span className="text-sm font-medium text-gray mb-2 inline-block text-center">Username <div className="text-brand md:text-base">{user?.username}</div></span>
                        {renderProfileImage()}
                        <input className="hidden" ref={fileInput} type="file" onChange={(e) => {
                          fileSelectHandler(e)
                        }}/>
                        <Button classes="mt-4" style="brand" onClick={() => (onFileSelectClick())} type="button">Upload file</Button>
                        <span className="text-sm">Max size: 8mb</span>
                        {saveReminder && <span className="text-red-400 text-xs mt-2">Don't forget to save!</span>}
                      </div>
                      <div className="mt-10 lg:mt-0 lg:col-span-2">
                        <div className="flex flex-col md:flex-row md:space-x-4">
                          <FormField label="First Name" classes="mb-4 w-full" field={editProfileFields.first_name} errors={errors.first_name && touched.first_name ? errors.first_name : null}/>
                          <FormField label="Last Name" classes="mb-4 w-full" field={editProfileFields.last_name} errors={errors.last_name && touched.last_name ? errors.last_name : null}/>
                        </div>
                        <div className="mb-4">
                          <FormField label="About me" classes="mb-4 w-full" field={editProfileFields.bio} errors={errors.bio && touched.bio ? errors.bio : null} helpText="Max 250 Characters"/>
                        </div>
                        <div className="flex flex-col md:flex-row md:space-x-4">
                          {/*<FormField label="About me" classes="mb-4 w-full" field={editProfileFields.country} errors={errors.country && touched.country ? errors.country : null}/>*/}
                          <FormField label="Traveler Type" classes="mb-4 w-full" field={editProfileFields.traveler_type}
                                     errors={errors.traveler_type && touched.traveler_type ? errors.traveler_type : null}/>
                        </div>
                        <div className="flex flex-col md:flex-row md:space-x-4">
                          <FormField label="Pinterest" classes="mb-4 w-full" field={editProfileFields.pinterest} errors={errors.pinterest && touched.pinterest ? errors.pinterest : null}/>
                          <FormField label="Instagram" classes="mb-4 w-full" field={editProfileFields.instagram} errors={errors.instagram && touched.instagram ? errors.instagram : null}/>
                        </div>

                        <div className="font-sans  text-dark-grey pb-2 whitespace-nowrap flex flex-col">
                          <span className="text-sm text-brand">Website theme</span>
                          <div className="font-medium">
                            <Toggle label="Dark mode" onChange={(value) => {
                              setFieldValue('dark_mode_enabled', value)
                            }} checked={values.dark_mode_enabled}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ProfileNotificationSettings onBlur={handleBlur} setFieldValue={setFieldValue} values={values} notificationSettings={notificationSettings} onFetchNotificationSettings={(settings) => setNotificationSettings(settings)}/>
                </div>
              </Form>
            )
          }}
        </Formik>
      }
    </Dashboard>
  )
}

export default EditProfile;
