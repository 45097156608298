import {camelizeKeys, decamelizeKeys } from 'humps'
import {getTokenFromLocalStorage} from "./store";

export type ApiFetchOptions<RejectWithValue> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json?: any;
  getState?: any;
  formData?: boolean; // If data is a formData type
  setCamelizeKeys?: boolean; // Temporary param for setting camelize keys on api fetch return
  rejectWithValue?: RejectWithValue;
  token?: string | null;
} & RequestInit;

export async function apiFetch<ReturnedType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  RejectWithValue extends (...args: any) => any = (...args: any) => any,
  >
(
  url: string,
  options: ApiFetchOptions<RejectWithValue> = {setCamelizeKeys: false},
): Promise<ReturnedType | ReturnType<RejectWithValue>> {
  const {json, rejectWithValue, getState, setCamelizeKeys, ...otherOptions} = options;
  const token = getTokenFromLocalStorage();

  function getBody() {
    if (otherOptions.body) {
      return options.body;
    }
    if (json) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return JSON.stringify(decamelizeKeys(options.json as any));
    }
    return undefined;
  }

  const localOptions: RequestInit = {
    ...otherOptions,
    method: options?.method,
    body: getBody(),
    headers: {
      ...(options.headers || {}),
      ...(token && {'Authorization': 'Token ' + token}),
      'Content-Type': (options.formData ? 'multipart/form-data' : 'application/json') ,
    },
  };
  const response = await fetch(url, localOptions);
  if (!response.ok) {
    if (rejectWithValue) {
      try {
        return rejectWithValue(camelizeKeys(await response.json()));
      } catch (e) {
        console.error(e);
      }
    }
    throw Error(`Unexpected status code - ${response.statusText}`);
  }
  if (response.status !== 204) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (options.setCamelizeKeys) {
      return camelizeKeys(await response.json()) as any as ReturnedType;
    } else {
      return await response.json();
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return response.text() as any;
}
