import React, {ChangeEvent, useEffect, useState} from 'react'
import {Formik, Field, Form, useField, FormikHelpers, FormikTouched, useFormikContext} from 'formik';
import * as Yup from "yup";
import {unwrapResult} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import FormField from "../Forms/FormField";
import Button from "../Button/Button";
import {useDispatch} from "react-redux";
import {addAccommodation, updateAccommodation} from "../../store/accommodationSlice";
import {Coordinates, tripId} from "../../types";
import {useAppSelector} from "../../store/store";
import SidebarHeading from "../SidebarHeading/SidebarHeading";
import {clearActiveSidebar} from "../../store/ui"
import moment, {Moment} from "moment";
import {DateRangePicker, FocusedInputShape} from 'react-dates';
import LocationSearchInput from "../LocationSearchInput/LocationSearchInput";
import FormLabel from "../Forms/FormLabel";
import {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import {FormikState} from "formik/dist/types";
import parseGoogleAddressComponent, {parsedAddressProps} from "../../utils/parseAddressComponent";
import AutoCompleteFillableField from "../AutoCompleteFillableField/AutoCompleteFillableField";

interface Props {
  tripId: tripId;
  tripCountries?: string;
  onCancel: () => void;
  accommodationId: number | null;
}

interface formValuesType {
  name: string;
  notes: string;
  website: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  check_in_time: string;
  check_out_time: string;
}

const AccommodationFormSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .max(40, 'Name too long'),
  notes: Yup.string()
    .max(250, 'Notes too long!'),
  address: Yup.string()
    .max(100, 'Address too long!'),
  city: Yup.string()
    .max(60, 'City too long!'),
  state: Yup.string()
    .max(60, 'State / Province too long!'),
  postal_code: Yup.string()
    .max(12, 'Postal code too long!'),
  website: Yup.string().url(),
})

const AccommodationsForm: React.FC<Props> = ({tripId, tripCountries, onCancel, accommodationId}) => {

  const dispatch = useDispatch();
  const {user} = useAppSelector((state) => state.auth)
  //@ts-ignore
  const accommodation = useAppSelector((state) => state.accommodations.accomsObject.accommodations[accommodationId])
  const orientation = window.matchMedia("(max-width: 768px)").matches ? 'vertical' : 'horizontal'
  const [checkInDatePicker, setCheckInDatePicker] = useState<Moment | null>(accommodation?.check_in_date ? moment(accommodation?.check_in_date) : moment())
  const [checkOutDatePicker, setCheckOutDatePicker] = useState<Moment | null>(accommodation?.check_out_date ? moment(accommodation?.check_out_date) : moment())
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)
  const [coordinates, setCoordinates] = useState<Coordinates | null>()
  // Place object with a bunch of good stuff
  const [autoCompletedPlace, setAutoCompletedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [formattedAddress, setFormattedAddress] = useState<typeof parsedAddressProps | null>(null)

  // Used for converting time to utc
  const formatTimeAsUTCString = (time: Moment | string) => moment.utc(time).format("HH:mm")

  // Used for combining date and time (to post to the backend)
  function combineDateAndTime(date: Moment | null, time: string): string {
    const formattedDate = moment(date).format('YYYY-MM-DD')
    return moment.utc(`${formattedDate} ${time}`).format("YYYY-MM-DD HH:mm")
  }

  const initialValues = {
    name: accommodation?.name || '',
    notes: accommodation?.notes || '',
    website: accommodation?.website || '',
    address: accommodation?.address || '',
    city: accommodation?.city || '',
    state: accommodation?.state || '',
    postal_code: accommodation?.postal_code || '',
    check_in_time: formatTimeAsUTCString(accommodation?.check_in_date),
    check_out_time: formatTimeAsUTCString(accommodation?.check_out_date),
  }

  const handleFocusChange = (arg: FocusedInputShape | null) => {
    setFocusedInput(arg)
  }

  // Used to fetch lat lng when an autocomplete selection isn't made
  const fetchLatLng = async (values: formValuesType, nonFormValues: any) => {
    // Don't even try if there is no street address entered or if there are already coordinates from autocomplete
    const {address, city, state, postal_code} = values
    if (!address || !city || !state) {
      return;
    }
    await geocodeByAddress(`${address} ${city} ${state} ${postal_code} ${tripCountries?.split(',')}`)
      .then((results) => getLatLng(results[0]))
      .then(({lat, lng}) => {
        nonFormValues.lng = lng
        nonFormValues.lat = lat
        setCoordinates({lat: lat, lng: lng})
      })
      .catch((error: any) => console.error('Error fetching lat lng', error));
  }

  async function handleSubmit(values: formValuesType, submitting: { setSubmitting: (isSubmitting: boolean) => void, resetForm: (nextState?: Partial<FormikState<formValuesType>>) => void, }) {
    if (!user) {
      return
    }

    const nonFormValues = {
      trip: tripId,
      creator: user.id,
      check_in_date: combineDateAndTime(checkInDatePicker, values.check_in_time),
      check_out_date: combineDateAndTime(checkOutDatePicker, values.check_out_time),
      lat: coordinates?.lat || null,
      lng: coordinates?.lng || null,
    }

    await fetchLatLng(values, nonFormValues);

    //  Create Accommodation
    if (!accommodationId) {
      try {
        // Create values to post
        const createResult: any = await dispatch(addAccommodation(
          {
            ...nonFormValues,
            ...values
          }))
        const createdAccommodation = unwrapResult(createResult)
        toast.success(`🚀 ${createdAccommodation.name} created.`)
        submitting.resetForm()
        onCancel()
      } catch (err) {
        toast.error(`😟 Something went wrong - ${err.message}`)
      }
      //  Update Accommodation
    } else {
      try {
        const updateResult: any = await dispatch(updateAccommodation(
          {
            accommodationId: accommodationId,
            ...nonFormValues,
            ...values
          }))
        const updatedAccommodation = unwrapResult(updateResult)
        toast.success(`🚀 ${updatedAccommodation.name} updated.`)
        submitting.resetForm()
        onCancel()
      } catch (err) {
        toast.error(`😟 Something went wrong - ${err.message}`)
      }
    }
    submitting.setSubmitting(false)
  }

  // Autocomplete search options
  let sessionToken = new google.maps.places.AutocompleteSessionToken();
  const autoCompleteSearchOptions = {
    // If there are countries on the trip restrict zone to that otherwise don't restrict
    ...(tripCountries && {componentRestrictions: { country: tripCountries?.split(',')  }}),
    sessionToken: sessionToken,
    types: ["address"],
  }

  // @ts-ignore
  return (
    <div>
      <SidebarHeading text={accommodationId ? 'Update Stay' : 'Add Stay'} classes="mb-4"/>
      <p className="text-darkGrey leading-snug dark:text-grey-300">Add an accommodation to the trip using the form below.</p>
      <Formik
        initialValues={initialValues}
        validationSchema={AccommodationFormSchema}
        onSubmit={(values: formValuesType, {setSubmitting, resetForm}: FormikHelpers<formValuesType>) => {
          handleSubmit(values, {setSubmitting, resetForm})
        }}>
        {({errors, touched, isSubmitting, setFieldValue, handleChange}) => (
          <Form method="post" encType="multipart/form-data" className="mt-6 md:mt-8">

            {/* Name */}
            <FormField label="Name"
                       classes="w-full mb-4"
                       required
                       field={<Field type="text" className={`form-input ${errors.name && touched.name && 'border-red-500'}`} name="name" placeholder="Hotel name, Bnb or Someones house"/>}
                       errors={errors.name && touched.name ? errors.name : null}/>

            {/* Notes */}
            <FormField label="Notes" classes="mb-4 w-full"
                       field={<Field className={`form-input ${errors.notes && touched.notes && 'border-red-500'}`} type="textarea" name="notes" placeholder="Add notes about the accommodation"/>}
                       errors={errors.notes && touched.notes ? errors.notes : null}
            />

            {/* Date Picker */}
            {/* TODO: Validation for empty dates */}
            <div className="flex flex-col mb-4 relative z-50">
              <span className="text-brand text-sm">Dates of your stay</span>
              <DateRangePicker
                startDate={checkInDatePicker}
                startDateId="start_date"
                endDate={checkOutDatePicker}
                endDateId="end_date"
                onDatesChange={({startDate, endDate}) => {
                  setCheckInDatePicker(startDate)
                  setCheckOutDatePicker(endDate)
                }}
                focusedInput={focusedInput}
                onFocusChange={handleFocusChange}
                orientation={orientation}
              />
            </div>

            {/* Check in time */}
            <FormField label="Check-in Time" classes="mb-4 w-full"
                       field={<Field className={`form-input ${errors.check_in_time && touched.check_in_time && 'border-red-500'}`} id="check_in_time" name="check_in_time" type="time"/>}
                       errors={errors.check_in_time && touched.check_in_time ? errors.check_in_time : null}/>

            {/* Check out time */}
            <FormField label="Checkout Time" classes="mb-4 w-full"
                       field={<Field className={`form-input ${errors.check_out_time && touched.check_out_time && 'border-red-500'}`} id="check_out_time" name="check_out_time" type="time"/>}
                       errors={errors.check_out_time && touched.check_out_time ? errors.check_out_time : null}/>

            {/* Url */}
            <FormField label="Website URL" classes="mb-4 w-full"
                       field={<Field className={`form-input ${errors.website && touched.website && 'border-red-500'}`} type="textarea" name="website" placeholder="https://airbnb.com"/>}
                       errors={errors.website && touched.website ? errors.website : null}
            />

            {/* Address */}
            <div className="mb-4 w-full">
              <FormLabel classes="text-brand text-sm" htmlFor="address" label="Address" required={false}/>
              <Field id="address" name="address">
                {({field}: any) => (
                  <>
                    <LocationSearchInput
                      onChange={((address) => setFieldValue(field.name, address))}
                      searchOptions={autoCompleteSearchOptions}
                      fieldValue={field.value}
                      fieldName="address"
                      fieldPlaceholder="Street address"
                      onGetLatLng={(coordinates) => setCoordinates(coordinates)}
                      onLocationSelected={(place) => {
                        setFormattedAddress(parseGoogleAddressComponent(place.address_components))
                        setAutoCompletedPlace(place)
                        setFieldValue(field.name, formattedAddress?.street)
                      }}/>
                  </>
                )}
              </Field>
              {errors.address && touched.address ? <span className="text-sm text-red-400">{errors.address}</span> : null}
            </div>

            {/* City */}
            <AutoCompleteFillableField autoCompletedValue={formattedAddress?.city} label="City" name="city" placeholder="City name"/>

            <div className="flex flex-row items-center mb-4 space-x-4">
              {/* State */}
              <AutoCompleteFillableField autoCompletedValue={formattedAddress?.state} label="State / Province" name="state" placeholder="State / Province"/>

              {/* Postal Code */}
              <AutoCompleteFillableField autoCompletedValue={formattedAddress?.postal_code} label="Postal Code / Zip Code" name="postal_code"
                                         placeholder="Zip / Postal Code"/>
            </div>

            {/* Actions */}
            <div className="mt-8 pb-4 flex flex-wrap justify-start items-start gap-2">
              <Button style="dark" onClick={() => {
              }} classes="mb-4 xl:mb-0 mr-4">{isSubmitting ? 'Saving..' : 'Save Accommodation'}</Button>
              <button
                type="submit"
                onClick={(e) => {
                  onCancel()
                  e.preventDefault()
                  dispatch(clearActiveSidebar())
                }}
                className="btn btn--light">
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AccommodationsForm
