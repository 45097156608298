import React from 'react'
import Button from "../Button/Button";
import {SidebarTypes} from "../../types";
import {activeSidebarSelector, clearActiveSidebar, showIdeaForm, showTripDetails} from "../../store/ui";
import {useAppDispatch, useAppSelector} from "../../store/store";
import TripSubnavMobile from "../TripSubnavMobile/TripSubnavMobile";

const StickyFooter = () => {
  const dispatch = useAppDispatch()


  return (
    <div className="sticky bottom-0 left-0 bg-white shadow-bottomNav lg:hidden z-40 dark:bg-grey-950">
      <div className="container text-center">
        <TripSubnavMobile/>
      </div>
    </div>
  )
}

export default StickyFooter;
