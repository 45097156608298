import React, {useEffect, useState} from "react"
import {Link, useHistory} from "react-router-dom"
import {useAppDispatch, useAppSelector} from "../store/store";
import AuthLayout from "../Containers/AuthLayout";
import FormField from "../Components/Forms/FormField";
import {login} from "../store/authSlice";
import {Formik, Field, Form, FormikHelpers} from 'formik';
import * as Yup from "yup";
import {AppQueryStrings} from "../types";
import useQuery from "../utils/useQuery";
import {toast} from "react-toastify";

const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required()
})

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  let history = useHistory();
  const query = useQuery();

  const {auth} = useAppSelector((state) => state)
  const [formErrors, setFormErrors] = useState<string[]>(Object.values(auth.errors) || null)

  const initialValues = {
    email: '',
    password: ''
  }

  interface formValuesType {
    email: string;
    password: string;
  }

  // TODO: Update to be work with all query strings
  const linkWithQueryStrings = (linkPath: string) => {
    const publicTripId = query.get(AppQueryStrings.public_trip_referral)
    if (publicTripId) {
      return `${linkPath}?${AppQueryStrings.public_trip_referral}=${publicTripId}`
    } else {
      return`${linkPath}`
    }
  }

  const redirectToDashboard = () => {
    // Check for query strings and append
    // Public trip referral
    const publicTripId = query.get(AppQueryStrings.public_trip_referral)
    if (publicTripId) {
      history.push({
        pathname: '/',
        search: `?${AppQueryStrings.public_trip_referral}=${publicTripId}`
      })
    } else {
      history.push('/')
    }
  }

  function handleSubmit(values: formValuesType, submitting: { setSubmitting: (isSubmitting: boolean) => void, resetForm: any }) {
    try {
      dispatch(login({...values})).then(() => {
        redirectToDashboard();
      })
    } catch (err) {
      console.log(err)
      toast.error('Sorry there was an issue, please try again.')
    }
  }

  return (
    <AuthLayout
      formTitle="Sign in"
      formSubtitle='Your next adventure starts now. 🙂'
      // pageLinkHeading={<Link className="underline" to="forgot-password">Forgot your password?</Link>}
      pageLink={<Link className="w-full inline-flex justify-center text-white font-medium py-2 px-2 text-center bg-black w-full bottom-0 rounded-t-none rounded-b-xl dark:text-white dark:bg-darkGrey hover:bg-darkGrey" to={linkWithQueryStrings("/register")}>Create an account</Link>}
      pageLinkHeading="Don't have an account?"
    >
      <Formik initialValues={initialValues}
              onSubmit={(values: formValuesType, {setSubmitting, resetForm}: FormikHelpers<formValuesType>) => {
                handleSubmit(values, {setSubmitting, resetForm})
              }}
              validationSchema={loginValidationSchema}>
        {({errors, touched, isSubmitting}) => (
          <Form method="post" className="flex flex-wrap">
            <FormField classes="mb-4 w-full"  errors={errors.email && touched.email ? errors.email : null} field={<Field className="form-input" type="text" placeholder="Email" name="email" /> }/>
            <FormField classes="mb-4 w-full"  errors={errors.password && touched.password ? errors.password : null} field={<Field className="form-input" type="password" placeholder="Password" name="password" /> }/>
            <button type="submit" className="btn btn--brand w-full">{isSubmitting ? "Logging in" : "Let me in"}</button>
            <div className="flex justify-center items-center mt-3 text-center w-full">
              <button className="text-brand underline" onClick={() => history.push('/forgot-password')}>Forgot password?</button>
            </div>
            {formErrors &&
                formErrors.map((error) => (<div className="text-red-500 p-2 mt-2 rounded-md w-full text-center">{error}</div>))
            }
          </Form>
        )}
      </Formik>
    </AuthLayout>
  )
}

export default Login;
