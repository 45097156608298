import React, {useEffect, useState} from 'react';
import {NavLink, useHistory} from "react-router-dom";
import {MenuItem} from "../../types";
import {useDispatch} from "react-redux";
import {auth} from "../../store/authSlice";
import {logout} from "../../store/store";
import Toggle from "../Toggle/Toggle";

export interface MenuItemProps {
  menuItems: MenuItem[]
}

const NavbarMenuItems: React.FC<MenuItemProps> = ({menuItems}) => {
  const [darkMode, setDarkMode] = useState(false);
  const dispatch = useDispatch()
  const history = useHistory()

  const signOut = (e: React.MouseEvent) => {
    dispatch(logout())
    history.push('/login')
  }

  return (
    <ul className="absolute right-0 bottom-0 transform translate-y-full rounded-lg shadow-lg flex flex-col w-56 bg-white z-[60]">
      {menuItems.map((item, index) => (
        <li key={`nav-menu-item-${index}`} className="border-b border-lightGray hover:bg-lightGray first:rounded-t-lg last:rounded-b-lg dark:border-grey-600 dark:bg-grey-700">
          <NavLink  className="font-sans font-bold text-dark-grey px-4 py-2 whitespace-nowrap block" to={item.route}>{item.title}</NavLink>
        </li>
      ))}
      <li className="border-b border-lightGray hover:bg-lightGray first:rounded-t-lg last:rounded-b-lg dark:border-grey-600 dark:bg-grey-700 dark:border-b-0">
        <button className="font-sans font-bold px-4 py-2 whitespace-nowrap block w-full text-red-400" onClick={(e) => signOut(e)}>Logout</button>
      </li>
    </ul>
  )
}

export default NavbarMenuItems;
