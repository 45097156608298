import {FetchState, PublicTrip, tripId} from "../types";
import {createAsyncThunk, createSlice, combineReducers} from "@reduxjs/toolkit";
import {BASE_URL} from "./store";
import {reducer} from "react-toastify/dist/hooks/toastContainerReducer";
import {AuthState} from "./authSlice";
import {stickyFooterActive} from "./ui";

const initialPublicTrip = {
  trip: {} as PublicTrip,
  status: FetchState.Idle
}

export const fetchPublicTrip = createAsyncThunk<PublicTrip, tripId>(
  'trips/fetchPublicTrip',
  async (id, rejectWithValue) => {
    const response = await fetch(
      `${BASE_URL}/api/public-trips/${id}/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (!response.ok) {
      throw Error(`Unexpected status code - ${response.statusText}`);
    }
    if (response.status !== 204) {
      return (await response.json() as any) as AuthState;
    }
    return await response.json();
  }
)

const publicTripSlice = createSlice({
    name: 'publicTrip',
    initialState: initialPublicTrip,
    reducers: {
      clearPublicTrip: () => ({trip: {} as PublicTrip, status: FetchState.Idle})
    },
    extraReducers: builder => {
      builder.addCase(fetchPublicTrip.pending, (state) => {
        state.status = FetchState.Loading;
      });
      builder.addCase(fetchPublicTrip.fulfilled, (state, {meta, payload}) => {
        state.status = FetchState.Fulfilled;
        state.trip = payload;
      });
      builder.addCase(fetchPublicTrip.rejected, ((state, {meta, payload}) => {
        state.status = FetchState.Failed
      }));
    }
  }
)

export const {clearPublicTrip} = publicTripSlice.actions

export default combineReducers(
  {trip: publicTripSlice.reducer}
)
