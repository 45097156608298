import {RootState, BASE_URL, getTokenFromLocalStorage, getIdFromLocalStorage} from './store'
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {FetchState, userId, ProfileNotificationSetting, profileSettingsId} from "../types";
import {User} from "../types";
import {apiFetch} from "./apiFetch";

export interface AuthState {
  user: User | null;
  isLoading: boolean | void;
  token: string | null;
  status: FetchState;
  errors: {};
}

export interface loginUserProps {
  email: string | null;
  password: string;
}

const initialState: AuthState = {
  user: null,
  isLoading: false,
  token: null,
  status: FetchState.Idle,
  errors: {}
};

export const fetchUserById = createAsyncThunk<User>(
  'auth/fetchUserById',
  async (v, {rejectWithValue}) => {
    const response = await fetch(
      `${BASE_URL}/api/profile/${getIdFromLocalStorage()}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    )
    if (!response.ok) {
      if (rejectWithValue) {
        try {
          return rejectWithValue(await response.json());
        } catch (e) {
          console.error(e);
        }
      }
      throw Error(`Unexpected status code - ${response.statusText}`);
    }
    if (response.status !== 204) {
      return (await response.json() as any) as AuthState;
    }
    return await response.json();
  }
)

export const fetchPublicUser = createAsyncThunk<User, string, { state: RootState; rejectValue: string[] }>(
  'auth/fetchPublicUser',
  async (id, {rejectWithValue}) => {
    const response = await fetch(
      `${BASE_URL}/api/profile/${id}/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    )
    if (!response.ok) {
      if (rejectWithValue) {
        try {
          return rejectWithValue(await response.json());
        } catch (e) {
          console.error(e);
        }
      }
      throw Error(`Unexpected status code - ${response.statusText}`);
    }
    if (response.status !== 204) {
      return (await response.json() as any) as AuthState;
    }
    return await response.json();
  }
)


export const login = createAsyncThunk<AuthState, loginUserProps, { state: RootState; rejectValue: string[] }>(
  'auth/login',
  async ({email, password}, {getState, rejectWithValue, signal}) => {
    const response = await fetch(
      BASE_URL + '/api/auth/login',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        })
      }
    );
    if (!response.ok) {
      if (rejectWithValue) {
        try {
          return rejectWithValue(await response.json());
        } catch (e) {
          console.error(e);
        }
      }
      throw Error(`Unexpected status code - ${response.statusText}`);
    }
    if (response.status !== 204) {
      return (await response.json() as any) as AuthState;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await response.json();
  }
)

export interface registerUserProps {
  username: string;
  email: string;
  password: string;
}

export const register = createAsyncThunk<AuthState, registerUserProps>(
  'auth/register',
  async ({username, email, password}, {rejectWithValue}) => {
    const response = await fetch(
      BASE_URL + '/api/auth/register',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          username: username,
          password: password,
        })
      }
    );
    if (!response.ok) {
      if (rejectWithValue) {
        try {
          return rejectWithValue(await response.json());
        } catch (e) {
          console.error(e);
        }
      }
      throw Error(`Unexpected status code - ${response.statusText}`);
    }
    if (response.status !== 204) {
      return (await response.json() as any) as AuthState;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return await response.json();
  }
)

export interface UpdateUserProps {
  first_name: string | null;
  last_name: string | null;
  bio: string | null;
  pinterest: string | null;
  instagram: string | null;
  country: string | null;
  traveler_type: string | null;
  image?: File | null;
  dark_mode_enabled: boolean;
}

export const updateUser = createAsyncThunk<User, UpdateUserProps, { state: RootState; rejectValue: string[] }>(
  'auth/updateUser',
  async ({first_name, last_name, country, bio, instagram, pinterest, traveler_type, image, dark_mode_enabled}, {rejectWithValue}) => {

    const data = new FormData();

    //@ts-ignore
    data.append('first_name', first_name);
    //@ts-ignore
    data.append('last_name', last_name);
    //@ts-ignore
    data.append('bio', bio);
    //@ts-ignore
    data.append('instagram', instagram);
    //@ts-ignore
    data.append('pinterest', pinterest);
    //@ts-ignore
    data.append('traveler_type', traveler_type);
    //@ts-ignore
    data.append('dark_mode_enabled', dark_mode_enabled);

    if (image) {
      data.append('image', image, image.name)
    }

    const response = await fetch(
      BASE_URL + '/api/profile/' + getIdFromLocalStorage() + '/',
      {
        method: 'PATCH',
        headers: {
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: data,
      }
    );
    if (!response.ok) {
      if (rejectWithValue) {
        try {
          return rejectWithValue(await response.json());
        } catch (e) {
          console.error(e);
        }
      }
      throw Error(`Unexpected status code - ${response.statusText}`);
    }
    if (response.status !== 204) {
      return (await response.json() as any) as AuthState;
    }
    return await response.json();
  }
)

export const fetchProfileNotificationSettings = createAsyncThunk<ProfileNotificationSetting[], void, { state: RootState; rejectValue: string[] }>(
  'profile/fetchNotificationSettings',
  async (_, {getState, rejectWithValue, signal}) => {
    return apiFetch(`${BASE_URL}/api/notification-settings`, {
      method: 'GET',
      getState,
      rejectWithValue,
      signal
    })
  }
)

interface ProfileNotificationSettingsProps {
  id: string,
  receive_trip_message_emails: boolean,
  receive_trip_invite_emails: boolean,
  receive_buddy_invite_emails: boolean
}

export const updateProfileNotificationSettings = createAsyncThunk<ProfileNotificationSetting, ProfileNotificationSettingsProps, { state: RootState; rejectValue: string[] }>(
  'profile/updateNotificationSettings',
  async ({id, ...props}, {getState, rejectWithValue, signal}) => {
    return apiFetch(`${BASE_URL}/api/notification-settings/${id}/`, {
      method: 'PATCH',
      json: {
        ...props
      },
      getState,
      rejectWithValue,
      signal
    })
  }
)


export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthUser: () => {
      localStorage.removeItem('tripfomo_user')
      localStorage.removeItem('tripfomo_token')
      localStorage.removeItem('tripfomo_dark_mode')
      return initialState
    }
  },
  // TODO: Cleanup in future to remove duplicated code
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, {payload}) => {
      state.token = payload.token;
      state.user = payload.user
      state.status = FetchState.Fulfilled
      localStorage.setItem('tripfomo_userid', JSON.stringify(payload.user?.id))
      localStorage.setItem('tripfomo_token', JSON.stringify(payload.token))
      localStorage.setItem('tripfomo_dark_mode', JSON.stringify(payload.user?.profile.dark_mode_enabled))
    });
    builder.addCase(login.pending, (state) => {
      state.status = FetchState.Loading;
    });
    builder.addCase(login.rejected, (state, {payload}) => {
      localStorage.removeItem('tripfomo_userid')
      localStorage.removeItem('tripfomo_token')
      localStorage.removeItem('tripfomo_dark_mode')
      state.status = FetchState.Failed
      state.errors = payload || []
    });
    builder.addCase(register.fulfilled, (state, {payload}) => {
      state.token = payload.token;
      state.user = payload.user
      state.status = FetchState.Fulfilled
      localStorage.setItem('tripfomo_token', JSON.stringify(payload.token))
      localStorage.setItem('tripfomo_userid', JSON.stringify(payload.user?.id))
      localStorage.setItem('tripfomo_dark_mode', JSON.stringify(payload.user?.profile.dark_mode_enabled))
    });
    builder.addCase(register.pending, (state) => {
      state.status = FetchState.Loading;
      state.errors = []
    });
    builder.addCase(register.rejected, (state) => {
      localStorage.removeItem('tripfomo_token')
      localStorage.removeItem('tripfomo_userid')
      state.status = FetchState.Failed
    });
    builder.addCase(updateUser.fulfilled, (state, {payload}) => {
      state.user = payload
      state.status = FetchState.Fulfilled
    })
    builder.addCase(updateUser.pending, (state, {payload}) => {
      state.status = FetchState.Loading
    })
    builder.addCase(updateUser.rejected, (state, {payload, error}) => {
      state.status = FetchState.Failed
      state.errors = payload || []
    })
    builder.addCase(fetchUserById.pending, (state, {payload}) => {
      state.status = FetchState.Loading
    });
    builder.addCase(fetchUserById.fulfilled, (state, {payload}) => {
      state.user = payload
      localStorage.setItem('tripfomo_dark_mode', JSON.stringify(payload.profile.dark_mode_enabled))
      state.status = FetchState.Fulfilled
    });
    builder.addCase(fetchUserById.rejected, (state, meta) => {
      localStorage.removeItem('tripfomo_userid')
      localStorage.removeItem('tripfomo_token')
      localStorage.removeItem('tripfomo_dark_mode')
      state.status = FetchState.Failed
    });
  }
})

export default auth.reducer
export const {clearAuthUser} = auth.actions;
export const authSelector = (state: RootState) => state.auth
