import React, {useEffect, useState} from 'react'

interface MarkerProps extends google.maps.MarkerOptions {
  onClick?: (marker: google.maps.Marker) => void;
  markerRef?: (marker: google.maps.Marker ) => void;
}

const Marker: React.FC<MarkerProps> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    //A reference to the marker so we can store it outside
    if (options.markerRef && marker) {
      options.markerRef(marker)
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  useEffect(() => {

    if (marker) {
      // The event listeners require slightly more complex code to clear existing listeners when a handler passed as a prop has been updated.
      // https://developers.google.com/maps/documentation/javascript/react-map#react-wrapper
      ["click"].forEach((eventName) =>
        google.maps.event.clearListeners(marker, eventName)
      );

      marker.addListener("click", () => options.onClick?.(marker));
    }
  }, [marker, options.onClick])


  return null;
};

export default Marker
