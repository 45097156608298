import React, {useState} from "react"
import {Link, useHistory} from "react-router-dom"
import AuthLayout from "../Containers/AuthLayout";
import FormField from "../Components/Forms/FormField";
import {register} from "../store/authSlice";
import {useAppDispatch, useAppSelector} from "../store/store";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {AppQueryStrings} from "../types";
import useQuery from "../utils/useQuery";
import {toast} from "react-toastify";

const registerValidationSchema = Yup.object().shape({
  username: Yup.string().required(),
  email: Yup.string().required().email(),
  password: Yup.string().required()
})

const Register = () => {

  const dispatch = useAppDispatch()
  const history = useHistory()
  const {auth} = useAppSelector((state) => state)
  const [formErrors, setFormErrors] = useState<string[]>(Object.values(auth.errors) || null)
  const query = useQuery();

  const initialValues = {
    username: '',
    email: '',
    password: ''
  }

  interface formValuesType {
    username: string;
    email: string;
    password: string;
  }

  // TODO: Update to be work with all query strings
  const linkWithQueryStrings = (linkPath: string) => {
    const publicTripId = query.get(AppQueryStrings.public_trip_referral)
    if (publicTripId) {
      return `${linkPath}?${AppQueryStrings.public_trip_referral}=${publicTripId}`
    } else {
      return`${linkPath}`
    }
  }

  const redirectToDashboard = () => {
    // Check for query strings and append
    // Public trip referral
    const publicTripId = query.get(AppQueryStrings.public_trip_referral)
    if (publicTripId) {
      history.push({
        pathname: '/',
        search: `?${AppQueryStrings.public_trip_referral}=${publicTripId}`
      })
    } else {
      history.push('/')
    }
  }

  function handleSubmit(values: formValuesType, submitting: { setSubmitting: (isSubmitting: boolean) => void, resetForm: any }) {
    try {
      //Convert username to lowercase
      values.username = values.username.toLowerCase()
      dispatch(register({...values})).then(() => {
        redirectToDashboard();
      })
    } catch (err) {
      console.log(err)
      toast.error('Sorry there was an issue, please try again.')
    }
  }

  return (
    <AuthLayout
      formTitle="Create Your Account"
      formSubtitle="Join your travel buddies around the world. 🌎"
      pageLink={
        <Link className="w-full inline-flex justify-center text-white py-2 px-2 text-center font-medium bg-black w-full bottom-0 rounded-t-none rounded-b-xl dark:text-white dark:bg-darkGrey hover:bg-darkGrey hover:translate-y-0" to={linkWithQueryStrings("/login")}>
          Login Here
        </Link>}
      pageLinkHeading="Already have an account?"
    >
      <Formik initialValues={initialValues}
              onSubmit={(values: formValuesType, {setSubmitting, resetForm}: FormikHelpers<formValuesType>) => {
                handleSubmit(values, {setSubmitting, resetForm})
              }}
              validationSchema={registerValidationSchema}>
        {({errors, touched, isSubmitting}) => (
          <Form method="post" className="flex flex-wrap">
            <FormField classes="mb-4 w-full" errors={errors.username && touched.username ? errors.username : null} field={<Field type="text" placeholder="Username" name="username" className="form-input"  /> }/>
            <FormField classes="mb-4 w-full" errors={errors.email && touched.email ? errors.email : null} field={<Field type="email" placeholder="Email" name="email" className="form-input"  />}/>
            <FormField classes="mb-4 w-full" errors={errors.password && touched.password ? errors.password : null} field={<Field type="password" placeholder="Password" name="password" className="form-input"  /> }/>

            <button type="submit" className="btn btn--brand w-full">{isSubmitting ? "Registering.." : "Begin traveling"}</button>
            {formErrors &&
               formErrors.map((error) => (<div className="text-red-500 p-2 mt-2 rounded-md w-full text-center">{error}</div>))
            }
          </Form>
        )}
      </Formik>
    </AuthLayout>
  )
}

export default Register;

