import React, {useState} from 'react';
import Input from "../Forms/Input";
import TravelBuddyCard from "../TravelBuddyCard/TravelBuddyCard";
import {getName} from "../../utils/utils";
import {User, userId} from "../../types"
import {useAppDispatch, useAppSelector} from "../../store/store";
import {sendBuddyRequest} from "../../store/buddySlice";
import {toast} from "react-toastify";
import Loading from "../Loading/Loading";

interface TravelBuddiesSearchProps {
  onEnterSearchTerm: (value: string) => void;
  buddies: User[] | null;
  loading: boolean;
  hasSearched: boolean;
}

const TravelBuddySearch: React.FC<TravelBuddiesSearchProps> = ({onEnterSearchTerm, buddies, loading, hasSearched}) => {
  const dispatch = useAppDispatch()

  async function handleBuddyInvite(receiver: userId) {
    try {
      dispatch(sendBuddyRequest(receiver))
      toast(`Invite sent!`)
      return true
    } catch (err) {
      toast.error(`😟 Something went wrong - ${err.message}`)
    }
  }

  return (
    <>
      <div className="flex flex-row items-center mb-4">
        <Input type="search" placeholder="Search for your travel buddies by name" onChange={(value) => onEnterSearchTerm(value)}/>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {buddies &&
        buddies.map((buddy) => (
          <TravelBuddyCard
            id={buddy.id}
            key={buddy.id}
            name={getName(buddy)}
            image={buddy.profile.image_thumbnail}
            instagram={buddy.profile.instagram}
            pinterest={buddy.profile.pinterest}
            traveler_type={buddy.profile.traveler_type}
            type="invite"
            //@ts-ignore
            onInvite={() => handleBuddyInvite(buddy.id)}
          />
        ))
        }
      </div>
      {buddies && buddies.length < 1 && !loading && hasSearched &&
      <div className="p-6 md:p-8 text-center w-full bg-offWhite rounded-md dark:bg-grey-950">
        <p>
          😭<span className="pl-2 text-gray font-medium">No people found, try again.</span>
        </p>
      </div>
      }
      {loading &&
      <Loading/>
      }
    </>
  )

}
export default TravelBuddySearch
