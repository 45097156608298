import React, {useEffect, useState} from "react";
import Navbar from "../Components/Nav/Navbar";
import Footer from "../Components/Footer/Footer";
import Dashboard from "../Layout/Dashboard";
import {AppQueryStrings, SidebarTypes} from "../types";
import TripForm from "../Components/TripForm/TripForm";
import {activeSidebarSelector, clearActiveSidebar} from "../store/ui";
import SidebarNav from "../Components/SidebarNav";
import {BASE_URL, useAppDispatch, useAppSelector} from "../store/store";
import PublicTripCard from "../Components/PublicTripCard/PublicTripCard";
import {unwrapResult} from "@reduxjs/toolkit";
import {fetchPublicTrip} from "../store/publicTripSlice";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {fetchMyTrips, joinTrip} from "../store/tripSlice";
import {toast} from "react-toastify";

const PublicTrip = () => {
  const {user} = useAppSelector((state) => state.auth);
  const {trip} = useAppSelector((state) => state.publicTripSlice.trip);
  const sidebar = useAppSelector(activeSidebarSelector);
  const history = useHistory()
  let {id} = useParams<Record<string, string>>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchMyTrips());
  }, [])

  useEffect(() => {
    dispatch(fetchPublicTrip(id))
      .then(unwrapResult)
      .catch(rejectedValueOrSerializedError => {
        console.log(rejectedValueOrSerializedError)
        history.push('/')
      })
  }, [id, dispatch])

  const handleJoinTrip = () => {
    if (user && user.id) {
      // @ts-ignore
      dispatch(joinTrip({tripId: trip.id, userId: user.id}))
      toast(`Welcome to ${trip.name}`)
      history.push(`/trip/${trip.id}`)
    }
  }

  const signupUrl = `/register/?${AppQueryStrings.public_trip_referral}=${trip.id}`;

  return (
    <>
      {user ?
        // Logged in view
        <Dashboard
          sidebarChildren={
            <>
              {sidebar === SidebarTypes.Trip ?
                <TripForm onCancel={() => {
                  dispatch(clearActiveSidebar())
                }}/> : <SidebarNav/>
              }
            </>
          }>
          {trip ?
            <PublicTripCard signupUrl={signupUrl} onJoinTrip={() => handleJoinTrip()} isLoggedIn={!!user} trip={trip}/>
            :
            <h2>No trip found</h2>
          }
        </Dashboard>
        :
        // Not logged in view
        <div>
          <Navbar signupQueryParams={`?${AppQueryStrings.public_trip_referral}=${trip.id}`}/>

          <div className="container max-w-4xl px-6">
            {trip ?
              <PublicTripCard signupUrl={signupUrl} isLoggedIn={!!user} trip={trip}/>
              :
              <h2>No trip found</h2>
            }
          </div>

          <Footer/>
        </div>
      }
    </>
  )
}

export default PublicTrip
