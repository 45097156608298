import React from "react";
import {showAccomsForm} from "../../store/ui";

interface EmptyListingMessageProps {
  message: string;
  buttonLabel: string;
  onClick: () => void;
}

const EmptyListingMessage = ({message, buttonLabel, onClick}: EmptyListingMessageProps) => {
  return (

    <div className="p-6 md:p-8 text-center w-full bg-offWhite rounded-md dark:bg-grey-950">
      <p className="mr-2">
        {message}
      </p>
      <button
        className="p-0 bg-transparent border-none underline font-bold text-brand"
        onClick={onClick}>
        {buttonLabel}
      </button>

    </div>
  )
}

export default EmptyListingMessage;
