import React, {useCallback, useEffect, useState} from "react"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";

import Login from "./Pages/Login"
import LogOut from "./Pages/Logout"
import Register from "./Pages/Register";
import ForgotPassword from "./Pages/ForgotPassword"
import EditProfile from "./Pages/EditProfile";
import Icons from "./Components/Icons/Icons";
import PrivateRoute from "./Components/Auth/PrivateRoute";
import MyProfile from "./Pages/MyProfile";
import Trip from "./Pages/Trip";
import PublicTrip from "./Pages/PublicTrip";
import PrivacyPolicyPage from "./Pages/PrivacyPolicyPage";
import NotFound from "./Pages/NotFound";
import {ToastContainer} from 'react-toastify';

// 3rd Party
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/tailwind.css'
import 'swiper/swiper-bundle.css';
import TravelBuddies from "./Pages/TravelBuddies";
import {getTokenFromLocalStorage, getTokenFromLocalStorageAsync, useAppDispatch, useAppSelector} from "./store/store";
import {fetchUserById, clearAuthUser} from "./store/authSlice";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import {activeSidebarSelector, fetchWebsiteContent} from "./store/ui";
import {FetchState, IntervalTime} from "./types";
import ResetPassword from "./Pages/ResetPassword";
import {Status, Wrapper} from "@googlemaps/react-wrapper";
import PublicProfile from "./Pages/PublicProfile";
import {unwrapResult} from "@reduxjs/toolkit";
import useInterval from "react-useinterval";
import {fetchBuddyRequests} from "./store/buddySlice";
import {fetchReceivedTripInvites} from "./store/tripInviteSlice";
import TermsAndConditionsPage from "./Pages/TermsAndConditionsPage";
import VerifyEmail from "./Pages/VerifyEmail";
import SupportPage from "./Pages/SupportPage";


const App: React.FC = () => {

  const dispatch = useAppDispatch()
  const sidebar = useAppSelector(activeSidebarSelector)
  const {auth} = useAppSelector((state => state))
  const history = useHistory();
  const darkMode = useAppSelector((state => auth.user?.profile.dark_mode_enabled))

  useEffect(() => {
    const token = getTokenFromLocalStorage()
    if (token) {
      dispatch(fetchUserById())
    }
  }, [])

  useEffect(() => {
    dispatch(fetchWebsiteContent())
      .then(unwrapResult)
      .catch((err: any) => {
        console.log(err)
      })
  }, [dispatch])

  const disableBodyScroll = () => {
    if (sidebar !== null) {
      document.body.classList.add('sidebar-active')
    } else {
      document.body.classList.remove('sidebar-active')
    }
  }

  const [receivedTripInvitesPolling, setReceivedTripInvitesPolling] = useState<boolean>(false);
  const loadReceivedTripInvites = useCallback(() => {
    const token = getTokenFromLocalStorage()
    if (!token) {
      return
    }
    setReceivedTripInvitesPolling(true);
    dispatch(fetchReceivedTripInvites())
      .then(unwrapResult)
      .then(() => {
        setReceivedTripInvitesPolling(false);
      });
  }, [dispatch]);

  useInterval(() => loadReceivedTripInvites(), IntervalTime.MINUTE * 2);

  useEffect(() => {
    loadReceivedTripInvites();
  }, [loadReceivedTripInvites]);

  // Fetch any buddy requests periodically.
  // Let's be real though, this isn't that useful because I don't have any buddies anyway.
  const [buddyRequestsPolling, setBuddyRequestsPolling] = useState<boolean>(false);
  const loadBuddyRequests = useCallback(() => {
    const token = getTokenFromLocalStorage()
    if (!token) {
      return
    }
    setBuddyRequestsPolling(true);
    dispatch(fetchBuddyRequests())
      .then(unwrapResult)
      .then(() => {
        setBuddyRequestsPolling(false);
      });
  }, [dispatch]);

  useInterval(() => loadBuddyRequests(), IntervalTime.MINUTE * 2);

  useEffect(() => {
    loadBuddyRequests();
  }, [loadBuddyRequests]);

  {/* TODO IMPORTANT: Setting up billing caps on google api: https://cloud.google.com/billing/docs/how-to/notify#cap_disable_billing_to_stop_usage */
  }
  // Google Maps render (shows errors if they happen)
  const googleMapsRender = (status: Status): React.ReactElement | null => {
    // TODO: Return a loading or failure component
    // if (status === Status.LOADING)  console.log(status);
    if (status === Status.FAILURE) console.log(`Google maps status: ${status}`);
    return null;
  };


  useEffect(() => {
    const html = document.querySelector('html');
    if (html) {
      let darkModeLocalStorage;
      try {
        darkModeLocalStorage = JSON.parse(localStorage.getItem('tripfomo_dark_mode') as string)
      } catch (e) {
        console.warn(e);
      }

      darkMode || darkModeLocalStorage ? html.classList.add('dark') : html.classList.remove('dark')
    }
  }, [darkMode])

  return (
    <>
      <Router>
        <ScrollToTop/>
        {disableBodyScroll()}
        <div className="App min-h-screen flex flex-col dark:bg-grey-900 dark:text-white">
          {/* Overlay for sidebar */}
          <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : ''}
            // @ts-ignore
                   render={googleMapsRender} libraries={["places"]}>
            <ToastContainer position="bottom-center" autoClose={5000}/>
            <Switch>
              <PrivateRoute
                exact
                path="/"
              >
                <MyProfile/>
              </PrivateRoute>
              <Route path="/login" initial>
                <Login/>
              </Route>
              <Route path="/logout" initial>
                <LogOut/>
              </Route>
              <Route path="/register">
                <Register/>
              </Route>
              <Route path="/forgot-password">
                <ForgotPassword/>
              </Route>
              <Route path="/reset-password">
                <ResetPassword/>
              </Route>
              <Route path="/verify-email">
                <VerifyEmail/>
              </Route>
              <PrivateRoute path="/account">
                <EditProfile/>
              </PrivateRoute>
              <PrivateRoute path="/trip/:id">
                <Trip/>
              </PrivateRoute>
              <PrivateRoute path="/profile-:id">
                <PublicProfile/>
              </PrivateRoute>
              <Route path="/public-trip/:id">
                <PublicTrip/>
              </Route>
              <PrivateRoute path="/travel-buddies">
                <TravelBuddies/>
              </PrivateRoute>
              <Route path="/privacy-policy">
                <PrivacyPolicyPage/>
              </Route>
              <Route path="/terms-and-conditions">
                <TermsAndConditionsPage/>
              </Route>
              <Route path="/support">
                <SupportPage/>
              </Route>
              <Route>
                <NotFound/>
              </Route>
            </Switch>
          </Wrapper>
        </div>
      </Router>
      <Icons/>
    </>
  )
}

export default App
