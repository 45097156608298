import React from "react";
import Button from "../Button/Button";
import {showIdeaForm} from "../../store/ui";

interface props {
  title: string;
  subtitle?: string;
}

const HeadingBlock: React.FC<props> = ({title, subtitle, children}) => {
  return (
    <div className="flex flex-col md:gap-4 xl:flex-row items-start justify-between mb-8 lg:mb-10">
      <div className="mb-4 md:mb-0">
        <span className="text-xl md:text-2xl font-bold">{title}</span>
        <p className="mb-0 text-gray max-w-sm dark:text-grey-300">{subtitle}</p>
      </div>
      {children}
    </div>
  )
}

export default HeadingBlock
