import React from 'react'
import moment from "moment";
import Button from "../Button/Button";
import {tripInvite} from "../../store/tripInviteSlice";
import {getName} from "../../utils/utils";
import SmallAvatar from "../SmallAvatar/SmallAvatar";

interface TripInviteProps {
  invite: tripInvite;
  onAcceptTripInvite: () => void;
  onRejectTripInvite: () => void;
}

const TripInvite = ({invite, onAcceptTripInvite, onRejectTripInvite}: TripInviteProps) => {
  return (
    <div className="border border-lightGray flex md:flex-row rounded-lg bg-white dark:bg-grey-700 dark:border-0">
      {invite.trip.image &&
      <div className="w-1/5 rounded-l-lg" style={{backgroundImage: "url(" + invite.trip.image + ")", backgroundPosition: "center", backgroundSize: "cover"}}/>
      }
      <div className={`w-full p-4 md:p-6 flex flex-col xl:flex-row xl:items-center ${invite.trip.image && 'md:w-4/5'}`}>
        <div className="flex flex-col justify-center">
          <span className="font-bold text-lg md:text-xl flex flex-col">{invite.trip.name}</span>
          {invite.trip.start_date && invite.trip.end_date &&
          <span className="text-xs text-gray">{moment(invite.trip.start_date).format('MMMM DD, YYYY')} - {moment(invite.trip.end_date).format('MMMM DD, YYYY')}</span>}

          <div className="flex flex-row items-center justify-start my-2">
            <SmallAvatar name={getName(invite.sender)} image={invite.sender.profile.image_thumbnail}/>
            <span className="text-xs text-gray uppercase pl-1 font-semibold">{getName(invite.sender)} invited you</span>
          </div>
        </div>

        <div className="flex md:flex-row gap-2 mt-4 xl:ml-auto">
          <Button onClick={onAcceptTripInvite} style="dark" classes="btn--success">Accept</Button>
          <Button onClick={onRejectTripInvite} style="brand-gradient">Reject</Button>
        </div>

      </div>
    </div>
  )
}

export default TripInvite
