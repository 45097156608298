import React from 'react'
import {MenuItem} from "../../types";
import {NavLink, useHistory} from "react-router-dom";
import LargeNameTag from "../LargeNameTag";
import {logout, useAppSelector} from "../../store/store";
import {auth} from "../../store/authSlice";
import Button from "../Button/Button";
import {useDispatch} from "react-redux";
import {getName} from "../../utils/utils";
import useMenuItems from "../MenuItems/MenuItems";

const SidebarNav: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {user} = useAppSelector((state) => state.auth)
  const menuItems = useMenuItems();

  const signOut = (e: React.MouseEvent) => {
    dispatch(logout())
    history.push('/login')
  }

  return (
    <>
      <LargeNameTag id={user?.id} name={getName(user)} travelerType={user?.profile.traveler_type} image={user?.profile.image_thumbnail} instagram={user?.profile.instagram} pinterest={user?.profile.pinterest}/>
      <ul>
        {menuItems.map((item, index) => {
            return (
              <li className="mb-4 last:mb-0" key={index}>
                <NavLink activeClassName="bg-gray-300 is-active" className="btn btn--light w-full hover:text-brand" key={index} to={item.route} exact>
                  {item.title}
                  {item.notifications && <span className="text-white bg-brand rounded-full px-2 text-center ml-auto absolute right-[20px] dark:bg-red-600 dark:text-white">{item.notifications}</span>}
                </NavLink>
              </li>
            )
          }
        )}
        <li>
          <Button style="link" classes="w-full !text-red-400" onClick={(e) => {signOut(e)}}>Log out</Button>
        </li>
      </ul>
    </>
  )
}

export default SidebarNav
