import React, {useRef} from "react";

export interface InputProps {
  type: string;
  name?: string;
  value?: string;
  disabled?: boolean;
  id?: string;
  className?: string;
  autoCapitalize?: string;
  autocomplete?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  placeholder?: string;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  inputMode?:
    | 'email'
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'numeric'
    | 'decimal'
    | 'search';
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: string) => void;
  step?: string | number;
  min?: string | number;
  pattern?: string;
}


const Input: React.FC<InputProps> = ({
   type,
   name,
   inputMode,
   value,
   autoCapitalize,
   className,
   id,
   placeholder,
   autocomplete,
   required,
   onBlur,
   min,
   inputRef,
   onChange,
   step,
   minLength,
   maxLength,
   pattern,
   disabled,
 }) => {
  const localRef = useRef(null);
  const ref = inputRef || localRef;
  const modifiers = className ? className : '';

  return (
    <input
      minLength={minLength}
      maxLength={maxLength}
      autoCapitalize={autoCapitalize}
      type={type}
      value={value}
      min={min}
      id={id}
      step={step}
      ref={ref}
      inputMode={inputMode}
      placeholder={placeholder}
      autoComplete={autocomplete}
      className={'form-input ' + modifiers}
      required={required}
      onBlur={onBlur}
      onChange={({target}) => onChange ? onChange(target.value) : null}
      pattern={pattern}
      disabled={disabled}
      name={name}
    />
  )
}

export default Input;
