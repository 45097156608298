import React, {useEffect, useState} from 'react';
import AuthLayout from "../Containers/AuthLayout";
import FormField from "../Components/Forms/FormField";
import {Link, useHistory} from "react-router-dom";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {useAppDispatch} from "../store/store";
import {toast} from "react-toastify";
import {verifyResetPasswordToken, resetPassword} from "../store/resetPasswordSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {clearAuthUser} from '../store/authSlice'
import useQuery from "../utils/useQuery";
import {AppQueryStrings} from "../types";

const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().required('This is required'),
})

interface formValuesType {
  password: string;
}

const ResetPassword = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const query = useQuery()
  const [token, setToken] = useState<string | null>(null)
  const [formErrors, setFormErrors] = useState<string[] | null>(null)

  const initialValues = {
    password: '',
  }

  useEffect(() => {
    dispatch(clearAuthUser())
  }, [])

  useEffect(() => {
    //  First check if token is valid
    const urlToken = query.get(AppQueryStrings.reset_pass_token)
    if (urlToken) {
      dispatch(verifyResetPasswordToken(urlToken))
        .then(unwrapResult)
        .then(() => {
          // Hold token in app state to use in form submit
          setToken(urlToken)
        })
        .catch((error) => {
          console.log(error)
          toast.error('Please try sending a new reset password email.')
          history.push('/login')
        })
    } else {
      history.push('/login')
    }
  }, [])

  async function handleSubmit(values: formValuesType, submitting: { setSubmitting: (isSubmitting: boolean) => void, resetForm: any }) {
    try {
      const resetResult: any = await dispatch(resetPassword({...values, token}))
      const resetStatus = unwrapResult(resetResult)
      if (resetStatus) {
        toast.success('Password reset, now login! 😀')
        history.push('/login')
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError)
      setFormErrors(rejectedValueOrSerializedError.password)
      toast.error('Sorry there was an issue, please try again.')
    }
  }

  return (
    <AuthLayout formTitle="Reset Password"
                formSubtitle="Enter in your new password that you will use to login"
                pageLink={<Link
                  className="w-full inline-flex justify-center text-white font-medium py-2 px-2 text-center bg-black w-full bottom-0 rounded-t-none rounded-b-xl dark:text-white dark:bg-darkGrey hover:bg-darkGrey"
                  to="/login">Back to Login</Link>}
    >
      <Formik initialValues={initialValues}
              onSubmit={(values: formValuesType, {setSubmitting, resetForm}: FormikHelpers<formValuesType>) => {
                handleSubmit(values, {setSubmitting, resetForm})
              }}
              validationSchema={resetPasswordValidationSchema}>
        {({errors, touched, isSubmitting}) => (
          <Form method="post" className="flex flex-wrap">
            <FormField classes="mb-4 w-full" errors={errors.password && touched.password ? errors.password : null}
                       field={<Field type="password" placeholder="New Password" name="password" className="form-input"/>}/>

            <button type="submit" className="btn btn--brand w-full">{isSubmitting ? "Changing..." : "Change it!"}</button>
            {formErrors && formErrors.length > 0 &&
              formErrors.map((error) => (<div className="text-red-500 p-2 text-left first:mt-2 rounded-md w-full">{error}</div>))
            }
          </Form>
        )}
      </Formik>
    </AuthLayout>
  )
}

export default ResetPassword;
