import { useState } from 'react'
import { Switch } from '@headlessui/react'

interface ToggleProps {
  label: string,
  screenReaderLabel?: string,
  onChange: (value: boolean) => void,
  onBlur?: (e: any) => void,
  checked: boolean,
}

function Toggle({label, screenReaderLabel, onChange, onBlur, checked}: ToggleProps) {

  return (
    <Switch.Group>
      <Switch.Label className="mr-4">{label}</Switch.Label>
      <Switch
        checked={checked}
        onBlur={onBlur}
        onChange={(value) => onChange(value)}
        className={`${
          checked ? 'bg-brand' : 'bg-grey-600 dark:bg-grey-200'
        } relative inline-flex h-6 w-11 items-center rounded-full`}
      >
        <span className="sr-only">{screenReaderLabel}</span>
        <span
          className={`${
            checked ? 'translate-x-6' : 'translate-x-1'
          } inline-block h-4 w-4 transform rounded-full bg-white dark:bg-grey-950`}
        />
      </Switch>
    </Switch.Group>
  )
}

export default Toggle;
