import React, {useEffect, useRef, useState} from 'react'
import FormField from "../Forms/FormField";
import Modal from "../Modal/Modal";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {createTrip, deleteTrip, updateTrip} from "../../store/tripSlice";
import {DateRangePicker, FocusedInputShape} from 'react-dates';
import {Moment} from "moment";
import moment from "moment";
import Button from "../Button/Button";
import {Formik, Field, Form, useFormikContext, FormikHelpers} from 'formik';
import * as Yup from "yup";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {unwrapResult} from "@reduxjs/toolkit";
import SidebarHeading from "../SidebarHeading/SidebarHeading";
import {clearActiveSidebar} from "../../store/ui";
import ReactTags, {Tag} from 'react-tag-autocomplete'
import {countriesList, countriesObj} from "../../store/countries";
import {FetchState, Trip} from "../../types";

export interface Props {
  onCancel: () => void;
  trip?: Trip;
}

const TripFormSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .max(50, 'Name too long'),
})

const TripForm: React.FC<Props> = ({onCancel, trip}) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)
  const [selectedFile, setSelectedFile] = useState<File>()
  const [previewImage, setPreviewImage] = useState<string>()
  const fileInput = useRef<HTMLInputElement>(null)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const tripUpdatingStatus = useAppSelector((state) => state.tripChanges.updating.status)
  const tripUpdatingErrors = useAppSelector((state) => state.tripChanges.updating.errorDetail)
  const tripCreatingStatus = useAppSelector((state) => state.tripChanges.creating.status)
  const tripCreatingErrors = useAppSelector((state) => state.tripChanges.creating.errorDetail)

  const {user} = useAppSelector((state) => state.auth)
  const orientation = window.matchMedia("(max-width: 768px)").matches ? 'vertical' : 'horizontal'
  const [startDate, setStartDate] = useState<Moment | null>(moment(trip?.start_date))
  const [endDate, setEndDate] = useState<Moment | null>(moment(trip?.end_date))
  const [countryTags, setCountryTags] = useState<Tag[]>([])
  const formikProps = useFormikContext()
  const dispatch = useAppDispatch()
  let history = useHistory();

  // This doesn't include dates because they are done through react dates not formik
  interface formValuesType {
    name: string;
    countries: string;
  }

  // This doesn't include dates because they are done through react dates not formik
  const initialValues = {
    name: trip?.name || '',
    countries: trip?.countries || '',
  }

  useEffect(() => {
    // Set selected country tags on load
    if (trip?.countries) {
      let countryCodes = trip?.countries.split(',')
      setCountryTags(countriesList.filter((country) => (countryCodes.includes(country.id))))
    }
  }, [])

  useEffect(() => {
    if (trip && trip.image_thumbnail) {
      setPreviewImage(trip.image_thumbnail)
    } else if (trip?.stock_image_url) {
      setPreviewImage(trip.stock_image_url)
    }
  }, [])

  const handleDeleteTag = (i: number) => {
    const tags = countryTags.slice(0)
    tags.splice(i, 1)
    setCountryTags(tags)
    return tags;
  }

  const fileSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files
    if (!fileList) return;
    setSelectedFile(fileList[0])
    setPreviewImage(URL.createObjectURL(fileList[0]))
  }

  const onFileSelectClick = () => {
    if (fileInput && fileInput.current) {
      fileInput.current.click()
    }
  }

  const handleFocusChange = (arg: FocusedInputShape | null) => {
    setFocusedInput(arg)
  }

  function openModal() {
    setShowDeleteModal(true);
  }

  function closeModal() {
    setShowDeleteModal(false);
  }

  async function handleDelete() {
    try {
      if (trip) {
        const deleteAction: any = await dispatch(deleteTrip(trip.id))
        toast.success(`💥 Deleted Trip! Start planning your next trip!`)
        history.push('/')
        closeModal()
        onCancel()
      }
    } catch (err) {
      toast.error(`😟 Something went wrong - ${err.message}`)
      closeModal()
      onCancel()
    }
  }

  async function handleSubmit(values: formValuesType, submitting: { setSubmitting: (isSubmitting: boolean) => void, resetForm: any }) {
    if (user && startDate && endDate) {
      if (trip) {
        try {
          const updateResult: any = await dispatch(updateTrip({
            ...values,
            id: trip?.id,
            start_date: startDate.format('YYYY-MM-DD hh:mm'),
            end_date: endDate.format('YYYY-MM-DD hh:mm'),
            image: selectedFile
          }))
          const updatedTrip = unwrapResult(updateResult)
          toast.success(`🦄 ${updatedTrip.name} updated! We hope it\'s better now`)
          onCancel()
        } catch (err) {
          toast.error(`😟 Something went wrong`)
        }
      } else {
        try {
          const createResult: any = await dispatch(createTrip({
            ...values,
            start_date: startDate.format('YYYY-MM-DD hh:mm'),
            end_date: endDate.format('YYYY-MM-DD hh:mm'),
            image: selectedFile
          }))
          const createdTrip = unwrapResult(createResult)
          toast.success(`🚀 ${createdTrip.name} created. Have fun!!`)
          submitting.resetForm()
          onCancel()
        } catch (err) {
          toast.error(`😟 Something went wrong`)
        }
      }
    }
    submitting.setSubmitting(false)
  }

  const renderErrors = () => {
    if (tripUpdatingStatus === FetchState.Failed) {
      return (
        <div className="text-white p-3 rounded-lg bg-red-500 mb-4">
          {tripUpdatingErrors &&
          Object.values(tripUpdatingErrors).toString()
          }
        </div>
      )
    }
    if (tripCreatingStatus === FetchState.Failed) {
      return (
        <div className="text-white p-3 rounded-lg bg-red-500 mb-4">
          {tripCreatingErrors &&
          Object.values(tripCreatingErrors).toString()
          }
        </div>
      )
    }
  }

  return (
    <div className="md:sticky md:top-8">
      <SidebarHeading text={trip ? 'Update Trip' : 'Add a trip'}/>
      <Formik
        initialValues={initialValues}
        validationSchema={TripFormSchema}
        onSubmit={(values: formValuesType, {setSubmitting, resetForm}: FormikHelpers<formValuesType>) => {
          handleSubmit(values, {setSubmitting, resetForm})
        }}>
        {({errors, touched, isSubmitting, setFieldValue}) => (
          <Form method="post" encType="multipart/form-data" className="mt-6 md:mt-8">

            {/* Error Handling */}
            {renderErrors()}

            {/* Image */}
            <div className="relative w-full bg-lightGray h-48 rounded-lg flex flex-col items-center justify-end p-6 mb-6 overflow-hidden dark:bg-black dark:bg-grey-600">
              <span className="text-darkGrey font-bold text-lg text-center mb-6 dark:text-grey-100">Trip Image</span>
              {previewImage && <img className="absolute object-cover h-full w-full left-1/2 top-1/2 min-w-full transform -translate-y-1/2 -translate-x-1/2" src={previewImage} alt=""/>}
              <button className="btn btn--light" type="button" onClick={() => {
                onFileSelectClick()
              }}>{trip?.image || trip?.stock_image_url ? 'Change Image' : 'Upload Image'}
              </button>
            </div>
            <input className="hidden" ref={fileInput} type="file" placeholder="Trip image" onChange={(e) => {
              fileSelectHandler(e)
            }}/>

            {/* Name */}
            <FormField label="Trip Name"
                       classes="mb-4 w-full"
                       required
                       field={<Field type="text" className="form-input" name="name" placeholder="Give your trip a fun name"/>}
                       errors={errors.name && touched.name ? errors.name : null}/>

            {/* Dates */}
            <div className="flex flex-col mb-4 relative z-50">
              <span className="text-brand text-sm">Trip Dates</span>
              <DateRangePicker
                startDate={startDate}
                startDateId="start_date"
                endDate={endDate}
                endDateId="end_date"
                onDatesChange={({startDate, endDate}) => (setStartDate(startDate), setEndDate(endDate))}
                focusedInput={focusedInput}
                onFocusChange={handleFocusChange}
                orientation={orientation}
              />
            </div>

            <FormField
              label="Countries"
              classes="relative z-10"
              field={
                <ReactTags
                  tags={countryTags}
                  suggestions={countriesList}
                  onDelete={(i) => {
                    // Delete tag from local state
                    const updatedTags = handleDeleteTag(i)
                    // Set field value
                    setFieldValue('countries', updatedTags.map((country) => country.id).join(','))
                  }}
                  onAddition={(tag: any) => {
                    setCountryTags([...countryTags, tag])
                    setFieldValue('countries', [...countryTags, tag].map((country) => country.id).join(','))
                  }}
                  placeholderText="Countries you will be visiting"
                />

              }/>

            <div className="mt-8">
              <Button style="success" type="submit" classes="mb-4 xl:mb-0 mr-4">{isSubmitting ? 'Saving..' : 'Save Trip'}</Button>
              <button onClick={(e) => {
                onCancel()
                e.preventDefault()
                dispatch(clearActiveSidebar())
              }} className="btn btn--light">Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>

      {/* Form is in updating mode */}
      {trip &&
      <div className="mt-6 md:mt-8 lg:mt-10">
        <span className="text-gray mb-2 inline-block text-xl">Danger Zone</span>
        <hr className="border-lightGray mb-2 dark:border-grey-700"/>
        <div>
          {trip && user?.id === trip.owner.id &&
          <>
            <button onClick={() => openModal()} className="text-red-400 text-sm hover:cursor-pointer hover:text-red-700 transition">
              Delete Trip
            </button>
            <Modal
              closeTimeoutMS={400}
              isOpen={showDeleteModal}
              onClose={() => closeModal()}
            >
              <div className="mb-6">
                <h2 className="text-lg md:text-xl font-bold mt-6">Are you sure you want to delete {trip.name}?</h2>
                <p className="text-gray">It will be gone forever, so no take backs.</p>
              </div>
              <button className="mr-4 btn btn--danger" onClick={() => handleDelete()}>Yes, Delete it!</button>
              <Button style="light" onClick={() => closeModal()}>Cancel</Button>
            </Modal>
          </>
          }
        </div>
      </div>
      }
    </div>
  )
}

export default TripForm;
