import {BASE_URL, getTokenFromLocalStorage, getIdFromLocalStorage} from './store'
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {FetchState, User, userId} from "../types";

// Fetch your buddies list
export const fetchBuddies = createAsyncThunk<User[]>(
  'buddies/fetchBuddies',
  async (id) => {
    const response = await fetch(
      BASE_URL + '/api/profile/' + getIdFromLocalStorage() + '/buddies/',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    )
    return await response.json();
  }
)

export const searchBuddies = createAsyncThunk<User[] | null, string>(
  'buddies/searchBuddies',
  async (query) => {
    const response = await fetch(
      `${BASE_URL}/api/profile-search/?search=${query}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    )
    return await response.json();
  }
)


// Get buddies you have invited
export const fetchInvitedBuddies = createAsyncThunk<buddyInvite[]>(
  'buddyInvites/fetchInvitedBuddies',
  async () => {
    const response = await fetch(
      `${BASE_URL}/api/buddyrequests/sent/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    )
    return await response.json();
  }
)

// Get buddies that have invited you
export const fetchBuddyRequests = createAsyncThunk<buddyInvite[]>(
  'buddies/fetchBuddyRequests',
  async () => {
    const response = await fetch(
      `${BASE_URL}/api/buddyrequests/received/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    )
    return await response.json();
  }
)


// Remove a travel buddy from your list
export const removeBuddy = createAsyncThunk<userId, userId>(
  'buddies/sendBuddyRequest',
  async (buddyId) => {
    const response = await fetch(
      `${BASE_URL}/api/profile/${getIdFromLocalStorage()}/remove_buddy/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify({id: buddyId})
      },
    )
    return buddyId
  }
)

interface AcceptBuddyRequestProps {
  inviteId: number;
  sender: User;
}

// Add a travel buddy to your list
export const acceptBuddyRequest = createAsyncThunk<AcceptBuddyRequestProps, AcceptBuddyRequestProps>(
  'buddyInvites/acceptBuddyRequest',
  async ({inviteId, sender}) => {
    const response = await fetch(
      `${BASE_URL}/api/buddyrequests/${inviteId}/accept_request/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      },
    )
    return {inviteId, sender}
  }
)

export interface BuddyState {
  buddies: User[];
  status: FetchState;
}

const initialBuddies = {
  buddies: [],
  status: FetchState.Idle
} as BuddyState

export const buddiesSlice = createSlice({
  name: 'buddies',
  initialState: initialBuddies,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchBuddies.pending, (state) => {
      state.status = FetchState.Loading;
    });
    builder.addCase(fetchBuddies.fulfilled, (state, {payload}) => {
      state.status = FetchState.Fulfilled;
      state.buddies = payload
    });
    builder.addCase(fetchBuddies.rejected, ((state, {payload}) => {
      state.status = FetchState.Failed
    }));
    builder.addCase(removeBuddy.fulfilled, (state, {payload}) => {
      state.buddies = state.buddies.filter(({id}) => id !== payload)
    });
    builder.addCase(acceptBuddyRequest.fulfilled, (state, {payload}) => {
      state.buddies = [...state.buddies, payload.sender]
    });
  }
})

export interface buddyRequestProps {
  sender: userId;
  receiver: userId;
}

export interface buddyRequestResponse {
  id: number;
  timestamp: Date;
  sender: User;
  receiver: User;
}

// Send a buddy request to another user
export const sendBuddyRequest = createAsyncThunk<buddyRequestResponse, userId>(
  'buddyInvites/sendBuddyRequest',
  async (receiver) => {
    const response = await fetch(
      `${BASE_URL}/api/buddyrequests/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
          sender: getIdFromLocalStorage(),
          receiver
        })
      },
    )
    return await response.json()
  }
)

export const deleteBuddyRequest = createAsyncThunk<number, number>(
  'buddyInvites/deleteBuddyRequest',
  async (inviteId) => {
    const response = await fetch(
      `${BASE_URL}/api/buddyrequests/${inviteId}/`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      },
    )
    return inviteId
  }
)


// Cancel an invite you sent
export const cancelSentInvite = createAsyncThunk<number, number>(
  'buddyInvites/cancelSentInvite',
  async (inviteId) => {
    const response = await fetch(
      `${BASE_URL}/api/buddyrequests/${inviteId}/`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      },
    )
    return inviteId
  }
)

export interface buddyInvite {
  id: number;
  sender: User;
  receiver: User;
  timestamp: Date;
}

export interface BuddyInvites {
  invites: buddyInvite[];
  requests: buddyInvite[];
  sendBuddyRequestStatus: FetchState;
  requestsStatus: FetchState;
  invitesStatus: FetchState;
}

const initialBuddyInvites = {
  invites: [],
  requests: [],
  sendBuddyRequestStatus: FetchState.Idle,
  invitesStatus: FetchState.Idle, //From you to others
  requestsStatus: FetchState.Idle //From others to you
} as BuddyInvites

// Invites you have sent to others
export const buddyInvitesSlice = createSlice({
  name: 'buddyInvites',
  initialState: initialBuddyInvites,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(sendBuddyRequest.pending, (state) => {
      state.sendBuddyRequestStatus = FetchState.Loading;
    });
    builder.addCase(sendBuddyRequest.fulfilled, (state, {payload}) => {
      state.sendBuddyRequestStatus = FetchState.Fulfilled;
      state.invites = [...state.invites, payload]
    });
    builder.addCase(sendBuddyRequest.rejected, ((state, {payload}) => {
      state.sendBuddyRequestStatus = FetchState.Failed
    }));
    builder.addCase(fetchInvitedBuddies.pending, (state) => {
      state.invitesStatus = FetchState.Loading;
    });
    builder.addCase(fetchInvitedBuddies.fulfilled, (state, {payload}) => {
      state.invitesStatus = FetchState.Fulfilled;
      state.invites = payload
    });
    builder.addCase(fetchInvitedBuddies.rejected, ((state, {payload}) => {
      state.invitesStatus = FetchState.Failed
    }));
    builder.addCase(cancelSentInvite.fulfilled, (state, {payload}) => {
      state.invitesStatus = FetchState.Fulfilled;
      state.invites = state.invites.filter(({id}) => id !== payload)
    });
    builder.addCase(fetchBuddyRequests.pending, (state) => {
      state.requestsStatus = FetchState.Loading;
    });
    builder.addCase(fetchBuddyRequests.fulfilled, (state, {payload}) => {
      state.requestsStatus = FetchState.Fulfilled;
      state.requests = payload
    });
    builder.addCase(fetchBuddyRequests.rejected, ((state, {payload}) => {
      state.requestsStatus = FetchState.Failed
    }));
    builder.addCase(deleteBuddyRequest.fulfilled, (state, {payload}) => {
      state.requestsStatus = FetchState.Fulfilled;
      state.requests = state.requests.filter(({id}) => id !== payload)
    });
    builder.addCase(acceptBuddyRequest.fulfilled, (state, {payload}) => {
      state.requests = state.requests.filter(({id}) => id !== payload.inviteId)
    });
  }
})


export const buddiesList = buddiesSlice.reducer;
export const buddyInvites = buddyInvitesSlice.reducer;


