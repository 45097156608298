import {FetchState, SidebarTypes, StickyFooterTypes, TripViewType} from "../types";
import {BASE_URL, RootState, thunkConfigTypes} from "./store";
import {
  createSlice,
  combineReducers, PayloadAction, createAsyncThunk
} from '@reduxjs/toolkit';
import {apiFetch} from "./apiFetch";
import {addIdea, ideaCreatingState} from "./planSlice";

export interface SidebarActive {
  sidebar: SidebarTypes | null;
}
export const sidebarActive = createSlice({
  name: 'ui/sidebarActive',
  initialState: {sidebar: null} as SidebarActive,
  reducers: {
    showIdeaForm: () => {
      return {sidebar: SidebarTypes.Idea}
    },
    showAccomsForm: () => {
      return {sidebar: SidebarTypes.Accommodation}
    },
    showInviteForm: () => {
      return {sidebar: SidebarTypes.Invite}
    },
    showTripForm: () => {
      return {sidebar: SidebarTypes.Trip}
    },
    showTripDetails: () => {
      return {sidebar: SidebarTypes.TripDetails}
    },
    showTripMessagesForm: () => {
      return {sidebar: SidebarTypes.TripMessages}
    },
    clearActiveSidebar: () => ({sidebar: null})
  },
})

export interface TripView {
  tripView: TripViewType | null;
}

export const activeTripView = createSlice({
  name: 'ui/activeTripView',
  initialState: {tripView: null} as TripView,
  reducers: {
    setTripView: (state, action: PayloadAction<TripViewType>) => {
      return {tripView: action.payload}
    },
    clearTripView: () => ({tripView: null})
  }
})


export interface StickyFooterActive {
  stickyFooter: StickyFooterTypes | null;
}

export const stickyFooterActive = createSlice({
  name: 'ui/stickyFooterActive',
  initialState: {stickyFooter: null} as StickyFooterActive,
  reducers: {
    showTripStickyFooter: () => {
      return {
        stickyFooter: StickyFooterTypes.Trip
      }
    },
    clearStickyFooter: () => ({stickyFooter: null})
  }
})

interface Website {
  privacyPolicy: string;
  termsAndConditions: string;
  status: FetchState;
}

export const fetchWebsiteContent = createAsyncThunk<Website[], void, thunkConfigTypes>(
  'website/fetchWebsiteContent',
  (_, {getState, rejectWithValue}) => {
    return apiFetch(`${BASE_URL}/api/website/`, {
      method: 'GET',
      getState,
      rejectWithValue,
      setCamelizeKeys: true
    })
  }
)

export const websiteContent = createSlice({
  name: 'websiteContent',
  initialState: {} as Website,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchWebsiteContent.pending, (state) => {
      state.status = FetchState.Loading;
    });
    builder.addCase(fetchWebsiteContent.rejected, (state, payload) => {
      state.privacyPolicy = ''
      state.termsAndConditions = ''
      state.status = FetchState.Failed
    });
    builder.addCase(fetchWebsiteContent.fulfilled, (state, {payload}, ) => {
      state.privacyPolicy = payload[0].privacyPolicy
      state.termsAndConditions = payload[0].termsAndConditions
      state.status = FetchState.Fulfilled;
    });
  }
})

export const {showTripStickyFooter, clearStickyFooter} = stickyFooterActive.actions

export const {setTripView, clearTripView} = activeTripView.actions;

export const {showIdeaForm, showInviteForm, showTripForm, showTripDetails, showAccomsForm, showTripMessagesForm, clearActiveSidebar} = sidebarActive.actions
export const activeSidebarSelector = (state: RootState): SidebarTypes|null => state.ui.sidebarActive.sidebar

export default combineReducers({
  sidebarActive: sidebarActive.reducer,
  stickyFooterActive: stickyFooterActive.reducer,
  activeTripView: activeTripView.reducer,
  websiteContent: websiteContent.reducer
});
