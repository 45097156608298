import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {FetchState, tripId, User, userId} from "../types";
import {Trip} from "../types";
import {BASE_URL, getIdFromLocalStorage, getTokenFromLocalStorage} from "./store";


export interface tripInvite {
  id: number;
  sender: User;
  receiver: User;
  trip: Trip;
}

export interface tripInviteState {
  invites: tripInvite[];
  status: FetchState;
}


// Accept an invite to a trip
export const acceptTripInvite = createAsyncThunk<{ inviteId: number, trip: Trip }, number>(
  'tripInvites/acceptTripInvite',
  async (inviteId) => {
    const response = await fetch(
      `${BASE_URL}/api/tripinvites/${inviteId}/accept/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      },
    )
    let trip = await response.json()
    return {inviteId: inviteId, trip}
  }
)


// Accept an invite to a trip
export const rejectTripInvite = createAsyncThunk<number, number>(
  'tripInvites/rejectTripInvite',
  async (inviteId) => {
    const response = await fetch(
      `${BASE_URL}/api/tripinvites/${inviteId}/`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      },
    )
    return inviteId
  }
)

// Get buddies that have invited you
export const fetchReceivedTripInvites = createAsyncThunk<tripInvite[]>(
  'tripInvites/fetchReceivedTripInvites',
  async () => {
    const response = await fetch(
      `${BASE_URL}/api/tripinvites/received/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    )
    return await response.json();
  }
)

// Get people that you have invited to the trip
export const fetchSentTripInvitesByTripId = createAsyncThunk<tripInvite[], tripId>(
  'tripInvites/fetchSentTripInvites',
  async (tripId) => {
    const response = await fetch(
      `${BASE_URL}/api/tripinvites/sent/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
          trip_id: tripId,
        })
      }
    )
    return await response.json();
  }
)

const initialReceivedTripInvites = {
  invites: [],
  status: FetchState.Idle
} as tripInviteState

export const tripInvitesReceivedSlice = createSlice({
  name: 'tripInvitesReceived',
  initialState: initialReceivedTripInvites,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchReceivedTripInvites.pending, (state) => {
      state.status = FetchState.Loading;
    });
    builder.addCase(fetchReceivedTripInvites.fulfilled, (state, {payload}) => {
      state.status = FetchState.Fulfilled;
      state.invites = payload
    });
    builder.addCase(fetchReceivedTripInvites.rejected, ((state, {payload}) => {
      state.status = FetchState.Failed
    }));
    builder.addCase(acceptTripInvite.fulfilled, (state, {payload}) => {
      state.invites = state.invites.filter(({id}) => id !== payload.inviteId)
    });
    builder.addCase(rejectTripInvite.fulfilled, (state, {payload}) => {
      state.invites = state.invites.filter(({id}) => id !== payload)
    });
  }
})

// Cancel an invite you sent
export const cancelSentTripInvite = createAsyncThunk<number, number>(
  'tripInvites/cancelSentTripInvite',
  async (inviteId) => {
    const response = await fetch(
      `${BASE_URL}/api/tripinvites/${inviteId}/`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      },
    )
    return inviteId
  }
)


interface inviteToTripProps {
  trip: tripId;
  receiver: userId;
}

// Send a trip invite request to your travel buddy
export const inviteToTrip = createAsyncThunk<tripInvite, inviteToTripProps>(
  'tripInvites/inviteToTrip',
  async ({trip, receiver}) => {
    const response = await fetch(
      `${BASE_URL}/api/tripinvites/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
          trip,
          sender: getIdFromLocalStorage(),
          receiver
        })
      },
    )
    return await response.json()
  }
)

const initialSentTripInvites = {
  invites: [],
  status: FetchState.Idle
} as tripInviteState

export const tripInvitesSentSlice = createSlice({
  name: 'tripInvitesSent',
  initialState: initialSentTripInvites,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSentTripInvitesByTripId.pending, (state) => {
      state.status = FetchState.Loading;
    });
    builder.addCase(fetchSentTripInvitesByTripId.fulfilled, (state, {payload}) => {
      state.status = FetchState.Fulfilled;
      state.invites = payload
    });
    builder.addCase(fetchSentTripInvitesByTripId.rejected, ((state, {payload}) => {
      state.status = FetchState.Failed
    }));
    builder.addCase(cancelSentTripInvite.fulfilled, (state, {payload}) => {
      state.invites = state.invites.filter(({id}) => id !== payload)
    });
    builder.addCase(inviteToTrip.fulfilled, (state, {payload}) => {
      state.invites = [...state.invites, payload]
      state.status = FetchState.Fulfilled
    });
  }
})


export const tripInvitesReceived = tripInvitesReceivedSlice.reducer;
export const tripInvitesSent = tripInvitesSentSlice.reducer;
