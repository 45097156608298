import React from "react";
import bg from '../img/lost-bg.jpg'
import Button from "../Components/Button/Button";
import {useHistory} from "react-router-dom";

const NotFound = () => {

  const history = useHistory()

  return (
    <div className="h-screen relative"  style={{backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
        <div className="w-full h-full absolute inset-0 opacity-80 bg-white dark:bg-grey-900 backdrop-filter backdrop-blur-lg"/>
      <h1 className="font-black text-brand leading-none text-center mt-8 absolute top-[20px] left-1/2 -translate-x-1/2">Tripfomo</h1>
      <div className="h-full flex flex-col justify-center items-center relative z-20">
            <div className="text-darkGrey text-center flex flex-col items-center">
              <h2 className="text-4xl md:text-7xl font-bold mb-4 dark:text-white">Page not found.</h2>
              <p className="md:text-2xl mb-4 dark:text-grey-200">Looks like you've explored too far and got lost.</p>
            </div>
            <Button classes="mt-4" style='brand' onClick={() => {history.push('/')}}>Go back home</Button>
        </div>
    </div>
  )
}

export default NotFound
