import React from 'react'

// All Icons here
const Icons = () => {
  return (
    <svg style={{display: 'none'}} xmlns="http://www.w3.org/2000/svg">

      <symbol id="person" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
        <g data-name="Group 149" transform="translate(-593 -268)">
          <circle data-name="Ellipse 6" cx="16.5" cy="16.5" r="16.5" transform="translate(593 268)" fill="currentColor"/>
          <path data-name="Icon open-person"
                d="M609.346 277.595a4.258 4.258 0 00-4.183 4.315 4.258 4.258 0 004.183 4.316 4.258 4.258 0 004.184-4.315 4.258 4.258 0 00-4.184-4.316zm-4.367 8.631c-2.217.086-4 1.588-4 3.452v1.726h16.734v-1.726c0-1.864-1.757-3.366-4-3.452a6.4 6.4 0 01-8.734 0z"
                fill="#fff"/>
        </g>
      </symbol>

      <symbol id="plus" viewBox="0 0 20 20">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M10.9 3.8h-2v5.1H3.8v2h5.1V16h2v-5.1H16v-2h-5.1z"
        />
      </symbol>

      <symbol id="dot-menu" viewBox="0 0 16.95 4.253">
        <g data-name="Group 5" transform="rotate(180 8.664 2.256)" fill="currentColor">
          <circle data-name="Ellipse 1" cx="2.127" cy="2.127" r="2.127" transform="translate(.379 .258)"/>
          <circle data-name="Ellipse 2" cx="2.127" cy="2.127" r="2.127" transform="translate(6.695 .258)"/>
          <circle data-name="Ellipse 3" cx="2.127" cy="2.127" r="2.127" transform="translate(13.075 .258)"/>
        </g>
      </symbol>

      <symbol id="chevron-left" viewBox="0 0 12 22">
        <path d="M10.5 20.121l-9-9 9-9" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
      </symbol>

      <symbol id="close" viewBox="0 0 13 13">
        <path d="M8.304 6.711l4.8-4.8A1.124 1.124 0 0011.515.327l-4.8 4.8-4.8-4.8A1.124 1.124 0 10.331 1.911l4.8 4.8-4.8 4.8a1.124 1.124 0 001.584 1.584l4.8-4.8 4.8 4.8a1.124 1.124 0 101.584-1.584z"
              fill="currentColor"/>
      </symbol>

      <symbol id="chevron-right" viewBox="0 0 320 512">
        <path fill="currentColor"
              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/>
      </symbol>

      <symbol id="thumb" viewBox="0 0 11 11">
        <path
          d="M2.118 4.562H.489A.489.489 0 000 5.051v4.888a.489.489 0 00.489.489h1.629a.489.489 0 00.489-.489V5.051a.489.489 0 00-.489-.489zM1.3 9.613a.489.489 0 11.489-.489.489.489 0 01-.489.489zm6.521-7.954c0 .864-.529 1.348-.678 1.926h2.072a1.217 1.217 0 011.213 1.183 1.475 1.475 0 01-.4 1 1.7 1.7 0 01-.19 1.619 1.611 1.611 0 01-.334 1.523 1.081 1.081 0 01-.125.909c-.416.6-1.446.606-2.317.606h-.056a5.847 5.847 0 01-2.435-.646A3.205 3.205 0 003.5 9.452a.244.244 0 01-.24-.244V4.854a.244.244 0 01.072-.174c.807-.8 1.154-1.641 1.815-2.3a2.467 2.467 0 00.517-1.2C5.754.8 5.943 0 6.354 0c.489 0 1.467.163 1.467 1.659z"
          fill="currentColor"/>
      </symbol>

      <symbol id="external-link" viewBox="0 0 30 30">
        <g data-name="Icon feather-external-link" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
          <path data-name="Path 12" d="M24 17.121v9a3 3 0 01-3 3H4.5a3 3 0 01-3-3v-16.5a3 3 0 013-3h9"/>
          <path data-name="Path 13" d="M19.5 2.121h9v9"/>
          <path data-name="Path 14" d="M12 18.621l16.5-16.5"/>
        </g>
      </symbol>

      <symbol id="checkmark" viewBox="0 0 19 14">
        <path data-name="Icon ionic-md-checkmark" d="M5.777 10.86L1.512 6.588 0 8.029l5.777 5.764L18.139 1.441 16.627 0z" fill="currentColor"/>
      </symbol>

      <symbol id="close" viewBox="0 0 14 14">
        <path d="M8.304 6.711l4.8-4.8A1.124 1.124 0 0011.515.327l-4.8 4.8-4.8-4.8A1.124 1.124 0 10.331 1.911l4.8 4.8-4.8 4.8a1.124 1.124 0 001.584 1.584l4.8-4.8 4.8 4.8a1.124 1.124 0 101.584-1.584z"
              fill="currentColor"/>
      </symbol>

      <symbol id="magnifying-glass" viewBox="0 0 13 13">
        <path
          d="M5.567.016a5.567 5.567 0 000 11.134 5.5 5.5 0 002.64-.652 1.59 1.59 0 00.207.207L10 12.295a1.623 1.623 0 102.3-2.29l-1.6-1.591a1.59 1.59 0 00-.254-.207 5.492 5.492 0 00.7-2.64A5.573 5.573 0 005.583 0zm0 1.591a3.958 3.958 0 013.976 3.976 3.988 3.988 0 01-1.05 2.736l-.048.048a1.59 1.59 0 00-.207.207 3.98 3.98 0 01-2.688 1 3.976 3.976 0 010-7.953z"
          fill="currentColor"/>
      </symbol>

      <symbol id="add-user" viewBox="0 0 18 15">
        <path data-name="Path 5" d="M10.81 7.207a3.6 3.6 0 10-3.6-3.6 3.614 3.614 0 003.6 3.6zm0 1.8c-2.387 0-7.207 1.216-7.207 3.6v1.8h14.414v-1.8c0-2.382-4.82-3.598-7.207-3.598z"
              fill="currentColor"/>
        <path data-name="Path 6" d="M3.603 6.005v-2.4h-1.2v2.4h-2.4v1.2h2.4v2.4h1.2v-2.4h2.4v-1.2z" fill="currentColor"/>
      </symbol>

      <symbol id="add-user" viewBox="0 0 15 12">
        <path data-name="Path 5" d="M8.77 5.847a2.923 2.923 0 10-2.922-2.924A2.932 2.932 0 008.77 5.847zm0 1.462c-1.937 0-5.847.987-5.847 2.923v1.462h11.694v-1.462c0-1.932-3.91-2.923-5.847-2.923z"
              fill="#fff"/>
        <path data-name="Path 6" d="M2.923 4.873v-1.95h-.974v1.95H0v.974h1.949v1.949h.974v-1.95h1.952v-.973z" fill="currentColor"/>
      </symbol>

      <symbol id="instagram" viewBox="0 0 15 15" fill="currentColor">
        <path
          d="M7.366 3.588a3.776 3.776 0 103.775 3.774 3.77 3.77 0 00-3.775-3.774zm0 6.232a2.455 2.455 0 112.455-2.458 2.459 2.459 0 01-2.455 2.457zm4.811-6.386a.881.881 0 11-.881-.881.879.879 0 01.881.88zm2.5.894a4.36 4.36 0 00-1.19-3.086A4.386 4.386 0 0010.405.051c-1.216-.069-4.86-.069-6.076 0a4.38 4.38 0 00-3.088 1.187 4.374 4.374 0 00-1.19 3.086c-.069 1.216-.069 4.861 0 6.077a4.36 4.36 0 001.19 3.086 4.392 4.392 0 003.085 1.19c1.216.069 4.86.069 6.076 0a4.357 4.357 0 003.085-1.19 4.388 4.388 0 001.19-3.086c.069-1.216.069-4.858 0-6.074zm-1.572 7.378a2.485 2.485 0 01-1.4 1.4 16.226 16.226 0 01-4.341.3 16.352 16.352 0 01-4.341-.3 2.485 2.485 0 01-1.4-1.4 16.236 16.236 0 01-.3-4.342 16.363 16.363 0 01.3-4.342 2.485 2.485 0 011.4-1.4 16.226 16.226 0 014.341-.3 16.352 16.352 0 014.341.3 2.485 2.485 0 011.4 1.4 16.236 16.236 0 01.3 4.34 16.227 16.227 0 01-.3 4.344z"/>
      </symbol>

      <symbol id="pinterest" viewBox="0 0 496 512">
        <path fill="currentColor"
              d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z"/>
      </symbol>

      <symbol id="map-pin" viewBox="0 0 21 30">
        <path fill="currentColor"
              d="M10.125 0C4.535 0 0 4.212 0 9.4c0 7.313 10.125 19.85 10.125 19.85S20.25 16.713 20.25 9.401C20.25 4.212 15.715 0 10.125 0zm0 13.425a3.3 3.3 0 113.3-3.3 3.3 3.3 0 01-3.3 3.3z"/>
      </symbol>

      <symbol id="external-link-icon" viewBox="0 0 20 18" fill="currentColor">
        <path d="m19.986 6.238-5.063 4.781a.844.844 0 0 1-1.423-.613v-2.53C8.417 7.91 6.273 9.11 7.707 13.9a.563.563 0 0 1-.879.609 6.767 6.767 0 0 1-2.609-5.04c0-5.06 4.134-6.064 9.281-6.093V.844a.844.844 0 0 1 1.423-.613l5.063 4.781a.844.844 0 0 1 0 1.226ZM13.5 13.329v2.421H2.25V4.5h1.79a.422.422 0 0 0 .3-.13 6.854 6.854 0 0 1 1.798-1.324.422.422 0 0 0-.195-.8H1.688A1.687 1.687 0 0 0 0 3.937v12.376A1.687 1.687 0 0 0 1.688 18h12.375a1.687 1.687 0 0 0 1.688-1.687V13.19a.422.422 0 0 0-.563-.4 2.525 2.525 0 0 1-1.2.119.423.423 0 0 0-.488.42Z"/>
      </symbol>

      <symbol id="gear" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
      </symbol>

      <symbol id="checked-clipboard" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"/>
      </symbol>

      {/*  Trip Sub Nav */}
      <symbol id="accommodation" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"/>
      </symbol>

      <symbol id="messages" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"/>
      </symbol>

      <symbol id="clipboard" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"/>
      </symbol>

      <symbol id="identification" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round"
              d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"/>
      </symbol>

      <symbol id="circle-plus" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </symbol>

      <symbol id="arrow-left-small" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"/>
      </symbol>

      <symbol id="heart-outline" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"/>
      </symbol>

      <symbol id="heart" viewBox="0 0 24 24" fill="currentColor">
        <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"/>
      </symbol>

      <symbol id="plus" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6"/>
      </symbol>
    </svg>
  )
}
export default Icons;
