import React from 'react'
import Icon from "../Icons/Icon";

interface Props {
  name: string;
  image?: string | null;
  showNameOnHover?: boolean;
  classes?: string;
}

const SmallAvatar: React.FC<Props> = ({image, name, showNameOnHover, classes}) => {
  return (
    <div className={`text-center first:ml-0 -ml-1.5 group w-auto relative ${classes}`} aria-label={`${name}'s picture`}>
        {image ?
          <img className="w-7 h-7 sm:w-9 sm:h-9 rounded-full border-2 border-white dark:border-darkGrey" src={image} alt=""/> :
          <Icon iconName="person" className="w-8 h-8 sm:w-9 sm:h-9 text-black border-2 border-white rounded-full dark:border-darkGrey"/>
        }
      {showNameOnHover &&
        <div className="text-xs text-center opacity-0 group-hover:opacity-100 transition transform -translate-x-1/2 left-1/2 duration-300 absolute bottom-[-14px]">{name}</div>
      }
    </div>
  )
}

export default SmallAvatar
