import React, {useMemo, useState} from "react";
import {Plan} from "../../types";
import PlanCard from "../PlanCard/PlanCard";
import {showIdeaForm} from "../../store/ui";
import Icon from "../Icons/Icon";
import {useAppSelector} from "../../store/store";

interface PlanGroupProps {
  groupId: number;
  title: string;
  plans?: Plan[] | null;
  onEditPlan: (id: number) => void;
  onSettingsClick: (id: number) => void;
  onLocationClick: (planId: number) => void;
  showAttendeesOnCards: boolean;
}

const PlanGroup = ({groupId, title, plans, onEditPlan, onSettingsClick, onLocationClick, showAttendeesOnCards}: PlanGroupProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className={`w-full ${plans && plans.length > 0 && expanded ? 'mb-8 lg:mb-12' : 'mb-6'}`}>

      {/* Accordion top */}
      <div className="bg-offWhite flex flex-col rounded-md rounded-b-0 w-full overflow-hidden hover:-translate-y-0.5 transition hover:shadow-md dark:bg-grey-800">
        <div className="flex justify-between w-full items-center">
          <button tabIndex={-1} className="p-3 sm:p-4 w-full text-left flex flex-col md:flex-row md:items-center dark:bg-grey-800" aria-expanded={expanded} onClick={() => setExpanded(!expanded)}>
            <span className="font-bold text-md sm:text-lg md:text-xl text-brand">{title}</span>
            {plans && <p
              className={`text-darkGrey uppercase text-xs mt-1 md:mt-0 md:ml-3 mb-0 inline-flex items-center px-2.5 py-0.5 rounded-full font-medium  ${plans.length > 0 ? 'bg-green-100 text-green-800' : 'bg-lightGray'}`}>{plans.length} Plan{plans.length === 1 ? '' : 's'}</p>}
          </button>

          <div className="self-stretch flex flex-row justify-center items-center">
            <button className="group h-full bg-lightGray/50 px-1 md:px-4 dark:bg-grey-700" onClick={() => onSettingsClick(groupId)}>
              <div className="p-1 sm:p-2 rounded-full bg-white border-white border group-hover:border-brand transition dark:bg-brand dark:border-0">
                <Icon className={`w-4 h-4 sm:w-5 sm:h-5 transition text-black dark:text-white`} iconName="gear"/>
              </div>
            </button>

            <button className="bg-lightGray h-full w-[35px] sm:w-[60px] flex justify-center items-center dark:bg-grey-950" aria-expanded={expanded} onClick={() => setExpanded(!expanded)}>
              <span className="sr-only">Show ideas in {title} category</span>
              <Icon className={`w-3 h-3 sm:w-4 sm:h-4 transition text-brand ${expanded ? 'rotate-90' : ''}`} iconName="chevron-right"/>
            </button>
          </div>
        </div>

      </div>

      {/* Accordion Content */}
      {expanded && plans &&
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-10 pt-8">
        {plans.map((plan) => (
          <PlanCard
            key={plan.id}
            id={plan.id}
            name={plan.name}
            notes={plan.notes}
            creator={plan.creator_details}
            attending={plan.attending}
            attending_details={plan.attending_details}
            url={plan.url}
            emoji={plan.emoji}
            address={plan.address}
            state={plan.state}
            city={plan.city}
            postal_code={plan.postal_code}
            onEditIdea={(id) => {
              onEditPlan(id)
            }}
            showAttending={showAttendeesOnCards}
            completed={plan.completed}
            lat={plan.lat}
            lng={plan.lng}
            onLocationClick={(planId) => onLocationClick(planId)}
          />
        ))}
      </div>
      }
    </div>
  )
}

export default PlanGroup
