import React, {useState} from 'react'
import Icon from "../Icons/Icon";
import SmallAvatar from "../SmallAvatar/SmallAvatar";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import {Link} from "react-router-dom";

export interface TravelBuddyCardProps {
  id: string | number;
  name: string;
  traveler_type?: string;
  instagram?: string | null;
  pinterest?: string | null;
  image?: string;
  classes?: string;
  type: 'invite' | 'invited' | 'buddy' | 'request';
  onAcceptRequest?: () => void;
  onCancelRequest?: () => void;
  onCancelInvite?: () => void;
  onRemoveBuddy?: () => void;
  onInvite?: () => boolean;
}

const TravelBuddyCard: React.FC<TravelBuddyCardProps> =
  ({
     id,
     name,
     traveler_type,
     image,
     instagram,
     pinterest,
     classes,
     type,
     onAcceptRequest,
     onCancelRequest,
     onCancelInvite,
     onInvite,
     onRemoveBuddy
   }) => {

    const [sent, setSent] = useState<boolean>()
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

    function renderActions() {
      // Invite a user to be your buddy
      if (type === 'invite') {
        return (
          <div className="flex flex-row items-center ml-auto">
            <button className={`rounded-full bg-brand flex justify-center items-center p-3 ${sent && 'bg-brandLight'}`} onClick={() => setSent(onInvite)} disabled={sent}>
              {sent ?
                <><span className="sr-only">Invited</span><Icon className="w-4 h-4 text-white" iconName="checkmark"/></> :
                <><span className="sr-only">Invite</span><Icon className="w-4 h-4 text-white" iconName="add-user"/></>}
            </button>
          </div>
        )
      }
      // Invited a user to be your buddy
      if (type === 'invited') {
        return (
          <div className="flex flex-row items-center ml-auto">
            <button className="btn btn--brand-gradient text-white font-bold rounded-lg flex justify-center items-center ml-2 dark:text-white" onClick={onCancelInvite} aria-label={`Cancel invite from ${name}`}>
              <span>Cancel</span>
            </button>
          </div>
        )
      }
      // User Requested to be your buddy
      if (type === 'request') {
        return (
          <div className="flex flex-row items-center ml-auto">
            <button className="rounded-full flex justify-center items-center p-2 bg-green-400" onClick={onAcceptRequest}
                    aria-label={`Accept Buddy Request from ${name}`}>
              <Icon className="w-4 h-4 text-white" iconName="checkmark"/>
            </button>
            <button className="rounded-full bg-gradient-to-r from-brand to-pink-500 flex justify-center items-center p-2 ml-2" onClick={onCancelRequest}
                    aria-label={`Reject Buddy Request from ${name}`}>
              <Icon className="w-4 h-4 text-white" iconName="close"/>
            </button>
          </div>
        )
      }
      // User is your buddy
      return (
        <div className="flex flex-row items-center ml-auto">
          <button className="rounded-full bg-lightGray flex justify-center items-center p-3 ml-2 group hover:bg-brandLight hover:text-white transition duration-300 dark:bg-brandLight dark:hover:bg-brand"
                  onClick={() => {
                    setShowDeleteModal(true)
                  }}
                  aria-label={`Remove ${name} from buddies`}>
            <Icon className="w-3 h-3 text-gray group-hover:text-white" iconName="close"/>
          </button>
          <ConfirmationDialog
            confirmText="Yes! Do it!"
            text={`Are you sure you want to remove ${name} from your buddies?`}
            isOpen={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onConfirm={() => {
              if (onRemoveBuddy) {
                onRemoveBuddy()
              }
              setShowDeleteModal(false)
            }}/>
        </div>
      )
    }

    return (
      <div className={`border border-lightGray rounded-lg p-4 md:p-4 w-full flex flex-row items-center dark:bg-grey-800 dark:border-0 ${classes ? classes : ''}`}>
        <div className="flex flex-row items-center">
          <Link to={`/profile-${id}`} className="text-lg leading-none shrink-0">
            {image ?
              <img className="w-12 h-12 rounded-full" src={image} alt={`${name}'s picture`}/> :
              <Icon iconName="person" className="w-12 h-12 text-black border border-white dark:border-grey-600 rounded-full"/>
            }
          </Link>
          <div className="pl-3 flex flex-col">
            <Link to={`/profile-${id}`} className="text-lg leading-none">{name}</Link>
            <div className="space-x-1 flex flex-row items-center">
              <span className="text-sm md:text-md text-brand">{traveler_type}</span>
              {instagram || pinterest ? <span className="mx-3 text-lightGray">|</span> : null}
              {instagram &&
              <a className="group transition" href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noreferrer noopener">
                <Icon className="text-gray w-3 h-3 group-hover:text-brand transition" iconName="instagram"/>
              </a>
              }
              {pinterest &&
              <a className="group transition" href={`https://www.pinterest.com/${pinterest}`} target="_blank" rel="noreferrer noopener">
                <Icon className="text-gray w-3 h-3 group-hover:text-brand transition" iconName="pinterest"/>
              </a>
              }
            </div>
          </div>
        </div>
        {/* Actions */}
        {renderActions()}
      </div>
    )
  }

export default TravelBuddyCard
