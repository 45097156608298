import React from 'react'
import Modal from "../Modal/Modal";
import Button from "../Button/Button";

interface ConfirmationDialogProps {
  text: string | React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onCloseText?: string;
  onConfirm?: () => void;
  confirmText: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({confirmText, text, isOpen, onClose, onConfirm, onCloseText = 'Cancel'}) => {
  return (
    <Modal
      closeTimeoutMS={100}
      isOpen={isOpen}
      onClose={onClose}
    >
      <h2 className="text-lg md:text-xl font-bold mb-6 mt-6 text-center dark:text-white">{text}</h2>
      <div className="text-center">
        {onConfirm && confirmText &&
        <button className="mr-4 btn bg-red-700" onClick={onConfirm}>{confirmText}</button>
        }
        <Button style="light" onClick={onClose}>{onCloseText}</Button>
      </div>

    </Modal>
  )
}

export default ConfirmationDialog
