import React, {useState, useEffect, useMemo} from "react";
import {Formik, Form, Field, FormikHelpers} from "formik";
import Button from "../Button/Button";
import * as Yup from "yup";
import {addPlanGroup, updatePlanGroup} from "../../store/planGroupSlice";
import {tripId} from "../../types";
import {toast} from "react-toastify";
import {unwrapResult} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../../store/store";
import Icon from "../Icons/Icon";

const IdeaFormSchema = Yup.object().shape({
  title: Yup.string()
    .required('Name is required')
    .max(40, 'Name too long'),
})

interface formValuesType {
  title: string;
}

interface PlanGroupFormProps {
  tripId: tripId
  onAddOrUpdateCategory: (id: number) => void;
  categoryId: number | null
}

const PlanGroupForm = ({tripId, onAddOrUpdateCategory, categoryId}: PlanGroupFormProps) => {

  const {planGroups} = useAppSelector((state) => state.planGroups)

  // TODO: Store planGroups by ID in redux state so we can use the key to select it.
  // Select the category (group) if we are in updating mode
  const group = useMemo(() => {
    return planGroups.find((group) => group.id === categoryId)
  }, [planGroups, categoryId])
  const dispatch = useAppDispatch()
  // Used for disabling the save button
  const [saved, setSaved] = useState<boolean>(false)

  function handleSubmit(values: formValuesType, submitting: { setSubmitting: (isSubmitting: boolean) => void, resetForm: any }) {

    if (!categoryId) {
      dispatch(addPlanGroup({title: values.title, trip: tripId}))
        .then(unwrapResult)
        .then(result => {
          if (result)
          toast.success(`${result.title} created!`)
          onAddOrUpdateCategory(result.id)
        })
        .catch(rejectedValueOrSerializedError => {
          console.log(rejectedValueOrSerializedError)
          toast.error('There was an issue updating, please try again later')
        })
      setSaved(true);
    } else {
      dispatch(updatePlanGroup({id: categoryId, title: values.title, trip: tripId}))
        .then(unwrapResult)
        .then(result => {
          toast.success(`${result.title} updated!`)
          onAddOrUpdateCategory(result.id)
        })
        .catch(rejectedValueOrSerializedError => {
          console.log(rejectedValueOrSerializedError)
          toast.error('There was an issue updating, please try again later')
        })
      setSaved(true);
    }
  }

  const initialValues = {
    title: group?.title || '',
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={IdeaFormSchema}
        onSubmit={(values: formValuesType, {setSubmitting, resetForm}: FormikHelpers<formValuesType>) => {
          handleSubmit(values, {setSubmitting, resetForm})
        }}>
        {({errors, touched, handleChange, isSubmitting}) => (
          <Form method="post" className="mt-6 md:mt-8">
            <div className="relative">
              <Field className="form-input py-4 pr-20 w-full focus:outline-none focus:ring-brand focus:ring-2" name="title" placeholder="Enter the category name" onChange={(e: any) => {
                setSaved(false)
                handleChange(e)
              }}/>
              <div className=" absolute right-[20px] top-1/2 -translate-y-1/2 ">
                <button type="submit" className="btn btn--dark disabled:!bg-lightGray disabled:!text-gray disabled:hover:!translate-y-0 dark:disabled:!bg-grey-700 dark:disabled:!text-grey-400" disabled={saved}>{saved ? 'Saved' : 'Save'}</button>
              </div>
            </div>
            <span className="text-red-400">{errors.title && touched.title ? errors.title : null}</span>
          </Form>
        )}
      </Formik>
    </div>

  )
}

export default PlanGroupForm
