import {Link} from "react-router-dom";
import {useAppSelector} from "../../store/store";

const Footer = ({className}: any) => {
  const year = () => {
    const d = new Date();
    return d.getFullYear();
  }

  return (
    <footer className={`bg-offWhite mt-10 md:mt-14 lg:mt-20 p-4 md:p-6 dark:bg-grey-950 relative z-10 ${className}`}>
      <div className="container flex flex-col lg:flex-row justify-between">
        <p className="text-gray text-xs md:text-sm">&copy; {year()} Tripfomo, Inc. All rights reserved</p>
        <div className={'flex flex-col md:flex-row align-center gap-6'}>
          <p className="text-gray text-xs md:text-sm">This product is currently in beta, use at your own risk.</p>
          <div className="gap-2 md:gap-6 flex flex-col sm:flex-row">
              <Link className="text-xs sm:text-sm underline text-brand dark:text-brandLight hover:text-brandPink" to={'/support'}>Contact & Support</Link>
              <Link className="text-xs sm:text-sm underline text-brand dark:text-brandLight hover:text-brandPink" to={'/privacy-policy'}>Privacy Policy</Link>
              <Link className="text-xs sm:text-sm underline text-brand dark:text-brandLight hover:text-brandPink" to={'/terms-and-conditions'}>Terms & Conditions</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
