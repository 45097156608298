import React from "react";
import moment from "moment";
import Button from "../Button/Button";
import {Trip} from '../../types';
import {countryNames} from "../../utils/utils";
import cardDefault from "../../img/card-default.jpg"


interface TripDetailsSidebarProps {
  trip: Trip;
  onEditTripDetails: () => void;
  showOwnerActions?: boolean;
}

const TripDetailsSidebar = ({trip, onEditTripDetails, showOwnerActions}: TripDetailsSidebarProps) => {

  const imagePath = () => {
    // Could also be a switch statement here but yolo
    if (trip.stock_image_url) {
      return trip.stock_image_url
    }
    if (trip.image_thumbnail) {
      return trip.image_thumbnail
    }
    return cardDefault
  }

  return (
    <>
      <h2 className="mb-6 lg:hidden">Trip Details</h2>
      <div className="relative w-full bg-lightGray h-32 md:h-48 rounded-lg flex flex-col items-center justify-end p-6 mb-6 overflow-hidden dark:bg-grey-800">
        <img className={`absolute object-cover h-full w-full left-1/2 top-1/2 min-w-full transform -translate-y-1/2 -translate-x-1/2 ${!trip.image_thumbnail && !trip.stock_image_url ? 'dark:opacity-30' : ''}`}
             src={imagePath()} alt=""/>
      </div>
      <div className="flex flex-col items-start">
        <h3 className="text-xl md:text-2xl font-semibold text-brand">{trip.name}</h3>
        <p className="text-gray text-sm md:text-base">
          <span>{moment(trip.start_date).format('MMMM DD')}</span>
          <span className="px-2">-</span>
          <span>{moment(trip.end_date).format('MMMM DD')}</span>
        </p>
        {trip.countries &&
          <span className="text-brand text-sm md:text-base">{countryNames(trip.countries)}</span>
        }
        {showOwnerActions && <Button classes="mt-4" onClick={onEditTripDetails} style="light">Edit trip details</Button>}
      </div>
    </>
  )
}

export default TripDetailsSidebar
