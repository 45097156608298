import React from "react"
import {useAppDispatch, useAppSelector} from "../../store/store";
import Icon from "../Icons/Icon";
import {activeSidebarSelector, clearActiveSidebar} from "../../store/ui";


const Sidebar: React.FC = ({children}) => {
  const dispatch = useAppDispatch()
  const sidebar = useAppSelector(activeSidebarSelector)

  return (
    <>
      <div
        className={`${sidebar !== null ? 'active-mobile' : ''} sidebar lg:sticky lg:pt-6 h-full`}>
        <button aria-label="Close sidebar" className="absolute right-[30px] top-[15px] lg:hidden" onClick={() => {
          dispatch(clearActiveSidebar())
        }}>
          <Icon className="w-4 h-4 text-darkGrey dark:text-white" iconName="close"/>
        </button>
        {children}
      </div>
      <button onClick={() => {dispatch(clearActiveSidebar())}} className={`lg:hidden inset-0 bg-black/50 w-full h-full fixed z-sidebarOverlay ${sidebar !== null ? 'block' : 'hidden'}`}/>
    </>
  )
}

export default Sidebar
