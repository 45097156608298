import React, {useEffect, useState} from "react";
import SmallAvatar from "../SmallAvatar/SmallAvatar";
import cardDefault from "../../img/card-default.jpg";
import {countryNames} from "../../utils/utils";
import {Link} from "react-router-dom";
import Button from "../Button/Button";
import {AppQueryStrings, tripId, User} from "../../types";
import moment from "moment";
import {useAppDispatch, useAppSelector} from "../../store/store";

export interface PublicTripProps {
  isLoggedIn: boolean;
  trip: {
    id: tripId;
    name: string;
    countries?: string;
    start_date?: string;
    end_date?: string;
    owner: User;
    attending_count?: number;
    image?: string | null;
    image_thumbnail?: string | null;
    stock_image_url?: string | null;
  },
  onJoinTrip?: () => void;
  signupUrl: string;
}

const PublicTripCard = ({isLoggedIn, trip, onJoinTrip, signupUrl}: PublicTripProps) => {
  const {tripList} = useAppSelector((state) => state.trips)
  const [tripExists, setTripExists] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  let ownerName = '';
  if (trip.owner) {
    ownerName = trip.owner.first_name ? trip.owner.first_name : trip.owner.username;
  }

  useEffect(() => {
    if (tripList.length > 0) {
      const existingTrip = tripList.find((item) => item.id === trip.id)
      if (existingTrip) {
        setTripExists(true)
      }
    }
  }, [tripList])

  const imagePath = () => {
    // Could also be a switch statement here but yolo
    if (trip.stock_image_url) {
      return trip.stock_image_url
    }
    if (trip.image_thumbnail) {
      return trip.image_thumbnail
    }
    return cardDefault
  }

  return (
    <>
      <div className="py-8 md:py-12 flex flex-col justify-center items-center w-full">

        <div className="flex-col justify-center items-center mb-8 md:mb-10 text-center">
          {trip.owner && trip.owner.profile.image_thumbnail &&
          <SmallAvatar key={`person-${trip.owner.id}`} name={ownerName} classes="flex justify-center" image={trip.owner.profile.image_thumbnail}/>
          }
          <span className="text-gray text-xl md:text-3xl inline-block mt-2">Checkout {ownerName}'s trip</span>
          <h1 className="text-brand font-black text-4xl md:text-5xl">{trip.name}</h1>
        </div>

        <div className="w-full bg-white rounded-lg overflow-hidden shadow-md dark:bg-black">
          {/* Image */}
          <div className="relative h-52 lg:h-56 overflow-hidden rounded-t-lg">
            <img className="absolute object-cover h-full w-full left-1/2 top-1/2 min-w-full transform -translate-y-1/2 -translate-x-1/2" src={imagePath()}
                 alt=""/>
          </div>

          {/* Card Content */}
          <div className="grid grid-cols-2 gap-4 p-6 md:p-10">
            {trip.countries &&
            <div>
              <h2 className="text-sm md:text-md uppercase text-black font-bold dark:text-white">
                Where
              </h2>
              <span className="text-brand text-xl md:text-2xl">{trip.countries && countryNames(trip.countries)}</span>
            </div>
            }
            {trip.start_date &&
            <div>
              <h2 className="text-sm md:text-md uppercase text-black font-bold dark:text-white">
                When
              </h2>
              <span className="text-brand text-lg md:text-2xl inline-block">{moment(trip.start_date).format('MMMM DD, YYYY')} -</span>
              <span className="text-brand text-lg md:text-2xl inline-block">{moment(trip.end_date).format('MMMM DD, YYYY')}</span>
            </div>
            }
          </div>

          {/*Bottom signup section */}
          <div className="bg-offWhite p-6 md:p-10 dark:bg-grey-800">
            {!isLoggedIn ?
              <div>
                <h2 className="text-sm md:text-md uppercase text-black font-bold mb-2 dark:text-white">
                  Signup or login to join
                </h2>
                <Link className="btn btn--dark bg-black w-full md:w-auto" to={signupUrl}>Signup / Login</Link>
              </div>
              :
              <>
                {tripExists ?
                  <>
                    <p className="mb-4 text-gray dark:text-gray-50">You are already a part of this trip</p>
                    <Link className="btn btn--dark bg-black w-full md:w-auto" to={`/trip/${trip.id}`}>View trip</Link>
                  </>
                  :
                  <Button style="dark" classes="btn btn--dark bg-black w-full md:w-auto" onClick={() => {
                    if (onJoinTrip) {
                      onJoinTrip()
                    }
                  }}>Join trip</Button>
                }
              </>
            }
          </div>
        </div>

      </div>
      <div>
        {!isLoggedIn ?
          <div className="text-center">
            <h3 className="text-sm md:text-md uppercase text-black font-bold dark:text-white">
              Note
            </h3>
            <p className="text-orange">After signing in you will be redirected this page to request access.</p>
          </div>
          :
          <div className="text-center md:text-left">
            <Link className="text-brand" to={'/'}>Back to trips</Link>
          </div>
        }
      </div>
    </>
  )
}

export default PublicTripCard
