import React, {useEffect, useState} from "react";
import HeadingBlock from "../HeadingBlock/HeadingBlock";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {Accommodation, FetchState, TripViewType} from "../../types";
import {setTripView, showAccomsForm, showIdeaForm, showTripStickyFooter} from "../../store/ui";
import {fetchTrip} from "../../store/tripSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {useHistory} from "react-router-dom";
import {fetchAccommodationsForTrip} from "../../store/accommodationSlice";
import AccommodationCard from "../AccommodationCard/AccommodationCard";
import Loading from "../Loading/Loading";
import Button from "../Button/Button";
import EmptyListingMessage from "../EmptyListingMessage/EmptyListingMessage";

export interface AccommodationsProps {
  onEditAccommodation: (id: number) => void,
  tripId: string
}

const Accommodations: React.FC<AccommodationsProps> = ({onEditAccommodation, tripId}) => {
  const {accommodations} = useAppSelector((state) => state.accommodations.accomsObject)
  const {status: accomsStatus} = useAppSelector((state) => state.accommodations.accomsObject)
  const [accomsArray, setAccomsArray] = useState<Accommodation[]>();
  const dispatch = useAppDispatch()
  const history = useHistory()

  useEffect(() => {
    //@ts-ignore
    dispatch(fetchTrip(tripId))
      .then(unwrapResult)
      .catch(rejectedValueOrSerializedError => {
        console.log(rejectedValueOrSerializedError)
        history.push('/')
      })
    //TODO: Make this rely on updating state to force rerender instead of fetching again
  }, [tripId, dispatch])

  useEffect(() => {
    dispatch(fetchAccommodationsForTrip(tripId))
  }, [tripId, dispatch])

  // Convert accoms object into an array to work with
  useEffect(() => {
    setAccomsArray(Object.entries(accommodations).map(([key, value]) => ({key, ...value})))
  }, [accommodations])

  const handleEditAccom = (id: number) => {
    dispatch(showAccomsForm())
    onEditAccommodation(id)
  }

  // Set trip sticky footer to show
  useEffect(() => {
    dispatch(showTripStickyFooter())
    // Set trip view type so things like mobile subnav can utilize
    dispatch(setTripView(TripViewType.Accommodation))
  }, [])

  return (
    <>
      <div className="mt-8 md:mt-12">
        <HeadingBlock title="Accommodations" subtitle="Below is a list of places that you will stay while on your trip. Hotel, bnb or even someones couch.">
           <Button style="dark" onClick={() => dispatch(showAccomsForm())}>Add Stay</Button>
        </HeadingBlock>
      </div>

      {accomsStatus === FetchState.Loading && accomsArray && accomsArray.length < 1 &&
      <Loading/>
      }

      <div className="grid grid-cols-1 gap-8">
        {accomsArray && accomsArray.length > 0 && accomsArray.map((accom) => (
          <AccommodationCard
            key={accom.id}
            name={accom.name}
            id={accom.id}
            creator={accom.creator_details}
            notes={accom.notes}
            check_in_date={accom.check_in_date}
            check_out_date={accom.check_out_date}
            onEditAccom={(id) => handleEditAccom(id)}
            address={accom.address}
            city={accom.city}
            postal_code={accom.postal_code}
            latLng={accom.lat && accom.lng ? {lat: accom.lat, lng: accom.lng}: null}
            website={accom.website}/>
        ))}

        {accomsArray && accomsArray.length < 1 &&
          <EmptyListingMessage message={`🏠 This trip doesn't have any accommodations yet.`} buttonLabel="Add an accommodation" onClick={() =>dispatch(showAccomsForm())}/>
        }
      </div>
    </>
  )
}

export default Accommodations
