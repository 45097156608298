import React, {useEffect, useRef, useState} from 'react';
import Icon from "../Icons/Icon";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";

interface ActionMenuProps {
  name: string;
  onEdit: () => void;
  onDelete: () => void;
  classes?: string;
}

const ActionMenu: React.FC<ActionMenuProps> = ({name, onEdit, onDelete, classes}) => {
  const node = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const handleClickOutside = (e: any) => {
    if (node.current !== null && node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  }

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const edit = () => {
    onEdit()
    setOpen(false)
  }

  const handleDelete = () => {
    onDelete()
    setShowDeleteModal(false);
  }

  return (
    <div ref={node} className={`relative flex items-center justify-end ${classes}`}>
      <button onClick={() => {
        setOpen(!open)
      }} className="outline-none ml-2 w-7 h-7 sm:w-8 sm:h-8 border-2 border-offWhite bg-white hover:border-brand transition rounded-full flex justify-center items-center focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-brand dark:bg-grey-600 dark:border-0 dark:hover:bg-brand dark:hover:text-white">
        <Icon iconName="dot-menu" className="w-4 h-3 text-darkGrey transform rotate-90 dark:text-white"/>
      </button>
      {open && (
        <ul className="absolute right-0 bottom-0 transform translate-y-full rounded-lg shadow-lg flex flex-col w-28 bg-white z-[60] dark:bg-grey-900 dark:border-grey-800">
          <li className="border-b border-lightGray hover:bg-lightGray first:rounded-t-lg last:rounded-b-lg dark:bg-grey-900 dark:border-grey-800">
            <button className="font-sans font-bold text-dark-grey px-4 py-2 whitespace-nowrap text-center block w-full text-base" onClick={() => edit()}>Edit</button>
          </li>
          <li className="border-b border-lightGray hover:bg-lightGray first:rounded-t-lg last:rounded-b-lg dark:bg-grey-900 dark:border-grey-800">
            <button className="font-sans font-bold text-dark-grey px-4 py-2 whitespace-nowrap text-center block w-full text-red-400 text-base" onClick={e => {
              setShowDeleteModal(true);
              setOpen(false)
            }}>Delete
            </button>
          </li>
        </ul>
      )}

      {/* Delete Modal */}
      <ConfirmationDialog confirmText="Yes! Do it!" text={`Are you sure you want to delete ${name}?`} isOpen={showDeleteModal} onClose={() => setShowDeleteModal(false)} onConfirm={() => handleDelete()}/>
    </div>
  )
}

export default ActionMenu;
