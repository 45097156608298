import React from "react";
import background from '../img/4-auth-bg.jpg'
import AuthHeader from "../Components/Auth/AuthHeader";
import * as Yup from "yup";
import Footer from "../Components/Footer/Footer";

export interface AuthLayoutProps {
  formTitle: string;
  pageLink?: React.ReactNode;
  pageLinkHeading?: string | React.ReactNode;
  formSubtitle?: string;
  hideSupportingText?: boolean;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({children, formTitle, pageLink, pageLinkHeading, formSubtitle, hideSupportingText}) => {
  return (
    <div className="h-screen flex flex-col justify-between bg-cover bg-no-repeat bg-center" style={{backgroundImage: `url(${background})`}}>
      <div className="h-full p-4 md:p-8 lg:p-14 flex items-center">
        <div className="bg-brand bg-gradient-to-bl from-brand to-brandPink opacity-80 inset-0 absolute z-10 backdrop-filter min-h-screen w-full h-full top-0 left-0"/>
        <div className="max-w-6xl lg:max-w-5xl sm:max-w-2xl mx-auto w-full flex flex-col justify-center pb-3">
          <AuthHeader classes="pb-10 md:pb-12 relative z-20 max-w-2xl items-center justify-center"/>
          <div className={`${!hideSupportingText ? 'lg:gap-10 lg:grid-cols-2' : 'lg:grid-cols-1'} flex-col justify-center md:grid relative z-20`}>
            <div className={`flex flex-col mx-auto lg:m-0 ${!hideSupportingText ? 'sm:max-w-md' : ''}`}>
              <div className="bg-offWhite w-full rounded-t-xl p-6 md:p-8 dark:bg-black shadow-lg">
                <h2 className={`text-3xl font-bold text-brand text-center leading-snug dark:text-white ${formSubtitle ? 'mb-0' : 'mb-2'}`}>{formTitle}</h2>
                {formSubtitle && <p className="text-sm text-center mb-4 text-darkGrey dark:text-white">{formSubtitle}</p>}
                {children}
              </div>
              <div className="bg-offWhite rounded-b-2xl dark:bg-black">
                {pageLinkHeading && <div className="text-center text-gray-500 dark:text-white pb-2">{pageLinkHeading}</div>}
                {pageLink}
              </div>
            </div>
            {!hideSupportingText &&
              <div className="hidden lg:flex justify-center flex-col max-w-2xl mt-14 lg:mt-0">
                <h2 className="text-3xl lg:text-4xl font-bold text-white mb-2">Fear of missing out?</h2>
                <p className="text-lg md:text-xl md:leading-snug mt-2 text-lightGray">Tripfomo makes group travel fun and easy. It can be your one place for all your trip related information. Share activity ideas, vote
                  on your favourites or create a travel wish list for the future. </p>
              </div>
            }
          </div>
        </div>
      </div>
      <div>
        <Footer className={"!mt-0"}/>
      </div>
    </div>
  )
}

export default AuthLayout;
