import React, {useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "../store/store";
import {Link, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import Icon from "../Components/Icons/Icon";
import TripAttendee from "../Components/TripAttendee/TripAttendee";
import {getName} from "../utils/utils";
import ActionMenu from "../Components/ActionMenu/ActionMenu";
import {showIdeaForm} from "../store/ui";
import {deletePlan} from "../store/planSlice";
import Map from "../Components/Map/Map";
import {darkModeStyles} from "../Components/Map/darkModeStyles";
import Marker from "../Components/Map/Makrer";
import SmallAvatar from "../Components/SmallAvatar/SmallAvatar";

export interface IdeaPageProps {
  onEditPlan: (id: number) => void,
}

const IdeaPage = ({onEditPlan}: IdeaPageProps) => {

  const history = useHistory();
  const dispatch = useAppDispatch();
  const {plans} = useAppSelector((state) => state.plans.plansObject)
  const {owner: tripOwner} = useAppSelector((state) => state.trip.trip)
  const {user} = useAppSelector(auth => auth.auth)
  const {planGroups} = useAppSelector((state) => state.planGroups)

  // Map Settings
  const [zoom, setZoom] = useState(9); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral | null>(null);
  const [bounds, setBounds] = useState(new google.maps.LatLngBounds());
  const mapWrapperRef = useRef<HTMLDivElement | null>(null)
  const darkMode = useAppSelector((state) => state.auth.user?.profile.dark_mode_enabled)

  let {id: planIdString} = useParams<Record<string, string>>();
  const planId = parseInt(planIdString, 10);

  // State to track if the planId is invalid.
  const [invalidPlanId, setInvalidPlanId] = useState(false);

  // Retrieve the plan by its ID.
  const plan = useMemo(() => {
    if (isNaN(planId) || !plans[planId]) {
      setInvalidPlanId(true);
      return null;
    }
    return plans[planId];
  }, [plans, planId]);

  // Find the group name for a plan.
  const groupName = useMemo(() => {
    if (!plan) return null;
    const groupObj = planGroups.find((group) => group.id === plan.group);
    return groupObj?.title || null;
  }, [planGroups, plan]);

  // Redirect user if the plan ID is invalid.
  useEffect(() => {
    if (invalidPlanId) {
      history.goBack();
    }
  }, [invalidPlanId, history]);

  // Don't render the component if the planId is invalid.
  if (invalidPlanId || !plan) {
    return null;
  }

  // Extract plan details
  const {id, name, creator_details, address, city, state, postal_code, lat, lng, url, notes, creator, emoji, completed, group, attending, attending_details} = plan;
  const canEdit = (creator === user?.id) || (tripOwner?.id === user?.id)
  const fullAddress = `${address}+${city}+${state}+${postal_code}`

  function handleDelete(id: number) {
    try {
      dispatch(deletePlan(id))
      toast.success(`💥 Deleted Idea.`)
    } catch (err) {
      toast.error(`😟 Something went wrong - ${err.message}`)
    }
  }

  const onEditIdea = (id: number) => {
    dispatch(showIdeaForm());
    onEditPlan(id);
  }

  return (
    <div className={"mt-6"}>
      <button className={'dark:text-white flex items-center group hover:underline'} onClick={() => history.goBack()}>
        <Icon className={'mr-2 w-4 h-4 dark:text-white transition group-hover:-translate-x-0.5'} iconName={'arrow-left-small'}/>
        Back to ideas
      </button>

      {/* Plan details */}
      <div className={'mt-6 space-y-4 flex flex-col'}>
        {lat && lng &&
          <div ref={mapWrapperRef}>
            <Map
              center={{lat: lat, lng: lng}}
              zoom={15}
              fullscreenControl={false}
              mapTypeControl={false}
              streetViewControl={false}
              controlSize={38}
              className="grow min-h-[250px] lg:min-h-[300px] w-full rounded-lg mb-4 md:mb-6"
              styles={darkMode ? darkModeStyles : null}
            >
              <Marker
                position={{lat: lat, lng: lng}}
                icon={{
                  path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
                  fillColor: completed ? "#5CC379" : "#836bd4",
                  fillOpacity: 0.9,
                  strokeWeight: 1,
                  strokeColor: 'white',
                  rotation: 0,
                  scale: 1,
                  labelOrigin: new google.maps.Point(0, -28)
                }}
                // If plan emoji show one
                label={(emoji && {text: emoji, fontSize: "20px"})}
              />
            </Map>
          </div>
        }
        <div className={"flex justify-between items-center"}>
          <div>
            <h2>{emoji ? `${emoji} ` : null}{name}</h2>
            {groupName && <p><span className="text-darkGrey dark:text-brandLight">Category: </span>{groupName}</p>}
          </div>
          {/* Show menu if you are the plan creator or trip owner */}
          {canEdit && <ActionMenu name={name} onEdit={() => onEditIdea(id)} onDelete={() => handleDelete(id)}/>}
        </div>

        <div className={'lg:w-1/2'}>
          <h3 className={"font-medium text-darkGrey dark:text-brandLight text-h4"}>Idea added by</h3>
          <TripAttendee
            id={creator_details.id}
            name={getName(creator_details)}
            image={creator_details.profile.image_thumbnail}
            traveler_type={creator_details.profile.traveler_type}
          />
        </div>

        <div>
          {attending.length > 0 &&
            <div className="mb-1 mt-2 md:mt-4 pb-0 md:pb-4">
              <h3 className={"font-medium text-darkGrey dark:text-brandLight text-h4"}>People interested</h3>
              <div className="flex items-center mt-2">
                {attending_details && attending_details.length > 0 &&
                  attending_details.map((attendee) => (
                    <Link key={`${attendee.id}-profile-link`} to={`/profile-${attendee.id}`}>
                      <span className="sr-only">View {attendee.first_name ? attendee.first_name : attendee.username}'s Profile</span>
                      <SmallAvatar key={`${attendee.id}-attendee`} name={attendee.first_name ? attendee.first_name : attendee.username} image={attendee.profile?.image_thumbnail} showNameOnHover/>
                    </Link>
                  ))}
              </div>
            </div>
          }
        </div>

        {notes &&
          <div>
            <h3 className={"font-medium text-darkGrey dark:text-brandLight text-h4"}>Notes</h3>
            <p>{notes}</p>
          </div>
        }

        <div>
          <h3 className={"font-medium text-darkGrey dark:text-brandLight text-h4"}>Address</h3>
          {(address || city || state || postal_code) ?
            <div>
              <p className="text-sm font-medium">{address}</p>
              <p className="text-sm font-medium">{city}{state && city && ', '} {state} {postal_code}</p>

              {(address || city || state || postal_code) && !lat && !lng && fullAddress &&
                <a className="flex flex-row items-center dark:text-white underline hover:text-brand"
                   href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`} target="_blank" rel="noreferrer noopener">
                  <Icon className="w-4 h-4 transition pr-1" iconName="map-pin"/>
                  <span>Location in google maps</span>
                </a>
              }
            </div>
            :
            <p>No address information added.</p>
          }
        </div>

        <div className={'flex flex-row gap-4'}>
          {url &&
            <a className="flex flex-row items-center dark:text-white underline hover:text-brand" href={url} target="_blank" rel="noopener noreferrer">
              <Icon className="w-4 h-4 pr-1" iconName="external-link"/>
              <span className="pt-1">Website</span>
            </a>
          }
        </div>


      </div>
    </div>
  )
}

export default IdeaPage;


