import React from 'react'
import TripAttendee from "../TripAttendee/TripAttendee";
import {getName} from "../../utils/utils";
import {User, userId} from "../../types"
import {Trip} from "../../types";
import {tripInvite} from "../../store/tripInviteSlice";

interface TripAttendingProps {
  trip: Trip;
  user: User;
  attending: User[];
  invites: tripInvite[];
  onRemove: (id: userId, name: string) => void;
  onCancelInvite: (id: number, name: string) => void;
}

const TripAttending: React.FC<TripAttendingProps> = ({trip, user, attending, invites, onRemove, onCancelInvite}) => {
  return (
    <div className="mt-8 md:mt-10">
      <h3 className="text-base text-gray font-medium">{attending.length + 1} Attending</h3>
      <div className="grid grid-cols-1 gap-3 mt-2">

        {/* Trip Owner */}
        {trip.owner &&
        <TripAttendee
          id={trip.owner.id}
          name={getName(trip.owner)}
          traveler_type={trip.owner.profile.traveler_type}
          image={trip.owner.profile.image_thumbnail}
          instagram={trip.owner.profile.instagram}
          pinterest={trip.owner.profile.pinterest}
          showStar/>
        }

        {/* Attendees */}
        {attending && attending.length > 0 && attending.map(person => (
          <TripAttendee
            id={person.id}
            key={person.id}
            name={getName(person)}
            traveler_type={person.profile.traveler_type}
            image={person.profile.image_thumbnail}
            instagram={person.profile.instagram}
            pinterest={person.profile.pinterest}
            showRemove={trip.owner.id === user?.id}
            onRemove={() => onRemove(person.id, getName(person))}/>
        ))}
      </div>

      {/* Invited */}
      {invites.length > 0 &&
      <div className="mt-4">
        <h3 className="text-base text-gray font-medium">{invites.length} Invited</h3>
        <div className="grid grid-cols-1 gap-3 mt-2">
          {invites.map((invite) => (
            <TripAttendee
              id={invite.receiver.id}
              key={invite.id}
              name={getName(invite.receiver)}
              image={invite.receiver.profile.image_thumbnail}
              instagram={invite.receiver.profile.instagram}
              pinterest={invite.receiver.profile.pinterest}
              traveler_type={invite.receiver.profile.traveler_type}
              showCancelInvite
              onCancelInvite={() => onCancelInvite(invite.id, getName(invite.receiver))}/>
          ))}
        </div>
      </div>
      }

    </div>
  )
}

export default TripAttending
