import React, {useEffect, useRef, useState} from 'react'
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";

interface MapProps extends google.maps.MapOptions {
  style?: { [key: string]: string };
  className?: string;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  onSetBounds?: (map: google.maps.Map) => void;
}

const Map: React.FC<MapProps> = ({
   onClick,
   onIdle,
   children,
   style,
   className,
   onSetBounds,
   ...options
 }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  const deepCompareEqualsForMaps = createCustomEqual(
    (deepEqual: (arg0: any, arg1: any) => any) => (a: any, b: any) => {
      if (
        isLatLngLiteral(a) ||
        a instanceof google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof google.maps.LatLng
      ) {
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
      }

      // TODO extend to other types

      // use fast-equals for other objects
      return deepEqual(a, b);
    }
  );

  function useDeepCompareMemoize(value: any) {
    const ref = useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
      ref.current = value;
    }

    return ref.current;
  }

  function useDeepCompareEffectForMaps(
    callback: React.EffectCallback,
    dependencies: any[]
  ) {
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
  }


  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  // The onClick, onIdle, and google.maps.MapOptions require useEffect hooks to imperatively apply updates to the google.maps.Map.
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  // The event listeners require slightly more complex code to clear existing listeners when a handler passed as a prop has been updated.
  // https://developers.google.com/maps/documentation/javascript/react-map#react-wrapper
  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }

      if (onSetBounds) {
        onSetBounds(map)
      }
    }
  }, [map, onClick, onIdle, onSetBounds]);

  return (
    <>
      <div ref={ref} style={style} className={className}/>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, {map});
        }
      })}
    </>
  )
}

export default Map
