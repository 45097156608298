import React from "react";
import {User, Creator} from "../types";
import {countriesObj} from "../store/countries";
import {UnprivilegedEditor} from "react-quill";


// Get a first name and last name or username based off user
export const getName = (user: User | Creator | undefined | null) => {
  if (user) {
    if (user.first_name || user.last_name) {
      return user.first_name + ' ' + user.last_name
    }
    return user.username
  }
  return ""
}

// Convert a list of countries CA,USA to array and get the associated name from obj
export const countryNames = (countries: string) => {
  let countryArray = countries.split(',')
  return countryArray.map((code) => {
    return countriesObj[code];
  }).join(', ')
}

// transform to array
export const arraySelector = (object: any) => {
  return Object.keys(object)
    .map((key) => object[key]);
};

export const isQuillEmpty = (quill: UnprivilegedEditor) => {
  return quill.getText().trim().length === 0
}
