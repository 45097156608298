import {MenuItem} from "../../types";
import {useAppSelector} from "../../store/store";

const useMenuItems = () => {
  const {requests} = useAppSelector((state => state.buddyInvites))
  const {invites} = useAppSelector(((state) => state.tripInvitesReceived))

  const menuItems: MenuItem[] = [
    {
      title: 'Trips',
      route: '/',
      notifications: invites.length > 0 ? invites.length : null,
    },
    {
      title: 'Travel Buddies',
      route: '/travel-buddies',
      notifications: requests.length > 0 ? requests.length : null,
    },
    {
      title: 'Account',
      route: '/account',
    },
  ];

  return menuItems
}

export default useMenuItems
