import {BASE_URL, getTokenFromLocalStorage} from './store'
import {combineReducers, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {FetchState, groupId, Plan, planId, tripId, userId} from "../types";

type PlansState = {
    plans: Record<planId, Plan>;
    status: FetchState;
};

interface IdeaProps {
    planId?: number;
    trip: tripId;
    creator: number | undefined;
    name: string;
    notes: string;
    url: string;
    emoji?: string | null;
    address?: string;
    city?: string;
    postal_code?: string;
    state?: string;
    group?: groupId;
    lat: number | null;
    lng: number | null;
}

// Ideas are just plans without all the information (like date)
export const addIdea = createAsyncThunk<Plan, IdeaProps>(
    'plans/addIdea',
    async (props) => {
        const response = await fetch(
            BASE_URL + '/api/plans/',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + getTokenFromLocalStorage()
                },
                body: JSON.stringify({
                    ...props
                })
            }
        )
        return await response.json();
    }
)

export interface ideaCreatingState {
    status: FetchState;
    errorDetail: any;
}

export const addIdeaSlice = createSlice({
    name: 'addIdea',
    initialState: {} as ideaCreatingState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(addIdea.pending, (state) => {
            state.status = FetchState.Loading;
        });
        builder.addCase(addIdea.fulfilled, (state, {payload}) => {
            state.status = FetchState.Fulfilled;
        });
        builder.addCase(addIdea.rejected, ((state, {payload}) => {
            state.status = FetchState.Failed
            state.errorDetail = payload || []
        }));
    }
})

interface updateIdeaProps {
    planId?: number;
    trip?: tripId;
    creator?: number | undefined;
    name?: string;
    notes?: string;
    url?: string;
    emoji?: string | null;
    address?: string;
    city?: string;
    postal_code?: string;
    state?: string;
    completed?: boolean;
    group?: groupId;
    lat?: number | null;
    lng?: number | null;
}

export const updateIdea = createAsyncThunk<Plan, updateIdeaProps>(
    'plans/updateIdea',
    async (props) => {
        const response = await fetch(
            `${BASE_URL}/api/plans/${props.planId}/`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + getTokenFromLocalStorage()
                },
                body: JSON.stringify({
                    ...props
                })
            }
        )
        return await response.json();
    }
)

const initialPlans = {
    plans: {} as Record<planId, Plan>,
    status: FetchState.Idle
} as PlansState

export const fetchPlansForTrip = createAsyncThunk<Plan[], string>(
    'plans/fetchPlans',
    async (tripId) => {
        const response = await fetch(
            BASE_URL + '/api/trips/' + tripId + '/plans/',
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + getTokenFromLocalStorage()
                },
            }
        )
        return await response.json();
    }
)

export const deletePlan = createAsyncThunk<planId, planId>(
    'plans/deletePlan',
    async (id) => {
        await fetch(
            `${BASE_URL}/api/plans/${id}/`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + getTokenFromLocalStorage()
                },
            }
        );
        return id;
    }
)

interface AttendingUserRemoveResponse {
    message: string;
    pla: number;
}

export const removeAttendingUserFromPlan = createAsyncThunk<AttendingUserRemoveResponse, { planId: number, userId: userId }>(
    'plans/removeAttendingUserFromPlan',
    async ({planId, userId}) => {
        const response = await fetch(
            `${BASE_URL}/api/plans/${planId}/remove_attendee/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + getTokenFromLocalStorage()
                },
                body: JSON.stringify({id: userId})
            }
        );
        return await response.json();
    }
)

export const addAttendingUserToPlan = createAsyncThunk<Plan, { planId: number, userId: userId }>(
    'plans/addAttendingUserToPlan',
    async ({planId, userId}) => {
        const response = await fetch(
            `${BASE_URL}/api/plans/${planId}/add_attendee/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + getTokenFromLocalStorage()
                },
                body: JSON.stringify({id: userId})
            }
        );
        return await response.json();
    }
)

export const addPlanToGroup = createAsyncThunk<Plan, { planId: number, groupId: number }>(
    'plans/addPlanToGroup',
    async ({planId, groupId}) => {
        const response = await fetch(
            `${BASE_URL}/api/plans/${planId}/`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + getTokenFromLocalStorage()
                },
                body: JSON.stringify({group: groupId})
            }
        );
        return await response.json();
    }
)

export const plansSlice = createSlice({
    name: 'plansObject',
    initialState: initialPlans,
    reducers: {
        clearPlans(state) {
            state.plans = {}
            state.status = FetchState.Idle
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchPlansForTrip.pending, (state) => {
            state.status = FetchState.Loading;
        });
        builder.addCase(fetchPlansForTrip.fulfilled, (state, {payload}) => {
            state.status = FetchState.Fulfilled;
            state.plans = {}
            if (payload.length) {
                payload.forEach((plan) => {
                    plan.objectState = FetchState.Fulfilled;
                    state.plans[plan.id] = {...plan}
                });
            }
        });
        builder.addCase(fetchPlansForTrip.rejected, ((state, {payload}) => {
            state.status = FetchState.Failed
        }));
        builder.addCase(addIdea.fulfilled, (state, {payload}) => {
            state.plans = {...state.plans, [payload.id]: payload}
            state.status = FetchState.Fulfilled;
        });
        builder.addCase(updateIdea.fulfilled, (state, {payload}) => {
            state.plans[payload.id] = {...payload}
            state.status = FetchState.Fulfilled;
        });
        builder.addCase(deletePlan.fulfilled, (state, {payload}) => {
            delete state.plans[payload]
        });
        builder.addCase(removeAttendingUserFromPlan.fulfilled, (state, action) => {
            const plan = state.plans[action.meta.arg.planId]
            // Delete Id from attending id list
            plan.attending = plan.attending.filter((id) => id !== action.meta.arg.userId)
            // Remove user details from attending details
            plan.attending_details = plan.attending_details.filter((user) => user.id !== action.meta.arg.userId)
        });
        builder.addCase(addAttendingUserToPlan.fulfilled, (state, action) => {
            const plan = state.plans[action.meta.arg.planId]
            // Add user id to list
            plan.attending.push(action.meta.arg.userId)
            // Filter out user details
            const attendingUser = action.payload.attending_details.filter((user) => user.id === action.meta.arg.userId)
            //@ts-ignore
            // Add the user in
            plan.attending_details = [...plan.attending_details, attendingUser[0]]
        });
        builder.addCase(addPlanToGroup.fulfilled, (state, {payload, meta}) => {
            const {arg} = meta
            state.plans[arg.planId].group = arg.groupId
        });
    }
})

export interface PlanDeleteState {
    status: FetchState;
    errorDetail: string[]
}

export const deletingPlan = createSlice(
    {
        name: 'deletingPlan',
        initialState: {} as Record<number, PlanDeleteState>,
        reducers: {},
        extraReducers: (builder) => {
            builder.addCase(deletePlan.pending, (state, action) => {
                state[action.meta.arg] = {
                    status: FetchState.Loading,
                    errorDetail: []
                }
            });
            builder.addCase(deletePlan.fulfilled, (state, action) => {
                state[action.meta.arg] = {
                    status: FetchState.Fulfilled,
                    errorDetail: [],
                }
            })
            builder.addCase(deletePlan.rejected, (state, action) => {
                state[action.meta.arg] = {
                    status: FetchState.Failed,
                    //@ts-ignore
                    errorDetail: action.payload || [],
                };
            });
        }
    }
)

export const {clearPlans} = plansSlice.actions

// export const plansSelector = (state: RootState) => state.plans.planList
export default combineReducers({
    addIdea: addIdeaSlice.reducer,
    plansObject: plansSlice.reducer,
    deletingPlan: deletingPlan.reducer,
})

