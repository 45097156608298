export interface Country {
  id: string,
  name: string,
}

export const countriesList: Country[] = [
  {id: "AF", name: "Afghanistan"},
  {id: "AL", name: "Albania"},
  {id: "DZ", name: "Algeria"},
  {id: "AS", name: "American Samoa"},
  {id: "AD", name: "Andorra"},
  {id: "AO", name: "Angola"},
  {id: "AI", name: "Anguilla"},
  {id: "AQ", name: "Antarctica"},
  {id: "AG", name: "Antigua and Barbuda"},
  {id: "AR", name: "Argentina"},
  {id: "AM", name: "Armenia"},
  {id: "AW", name: "Aruba"},
  {id: "AU", name: "Australia"},
  {id: "AT", name: "Austria"},
  {id: "AZ", name: "Azerbaijan"},
  {id: "BS", name: "Bahamas (the)"},
  {id: "BH", name: "Bahrain"},
  {id: "BD", name: "Bangladesh"},
  {id: "BB", name: "Barbados"},
  {id: "BY", name: "Belarus"},
  {id: "BE", name: "Belgium"},
  {id: "BZ", name: "Belize"},
  {id: "BJ", name: "Benin"},
  {id: "BM", name: "Bermuda"},
  {id: "BT", name: "Bhutan"},
  {id: "BO", name: "Bolivia (Plurinational State of)"},
  {id: "BQ", name: "Bonaire, Sint Eustatius and Saba"},
  {id: "BA", name: "Bosnia and Herzegovina"},
  {id: "BW", name: "Botswana"},
  {id: "BV", name: "Bouvet Island"},
  {id: "BR", name: "Brazil"},
  {id: "IO", name: "British Indian Ocean Territory (the)"},
  {id: "BN", name: "Brunei Darussalam"},
  {id: "BG", name: "Bulgaria"},
  {id: "BF", name: "Burkina Faso"},
  {id: "BI", name: "Burundi"},
  {id: "CV", name: "Cabo Verde"},
  {id: "KH", name: "Cambodia"},
  {id: "CM", name: "Cameroon"},
  {id: "CA", name: "Canada"},
  {id: "KY", name: "Cayman Islands (the)"},
  {id: "CF", name: "Central African Republic (the)"},
  {id: "TD", name: "Chad"},
  {id: "CL", name: "Chile"},
  {id: "CN", name: "China"},
  {id: "CX", name: "Christmas Island"},
  {id: "CC", name: "Cocos (Keeling) Islands (the)"},
  {id: "CO", name: "Colombia"},
  {id: "KM", name: "Comoros (the)"},
  {id: "CD", name: "Congo (the Democratic Republic of the)"},
  {id: "CG", name: "Congo (the)"},
  {id: "CK", name: "Cook Islands (the)"},
  {id: "CR", name: "Costa Rica"},
  {id: "HR", name: "Croatia"},
  {id: "CU", name: "Cuba"},
  {id: "CW", name: "Curaçao"},
  {id: "CY", name: "Cyprus"},
  {id: "CZ", name: "Czechia"},
  {id: "CI", name: "Côte d'Ivoire"},
  {id: "DK", name: "Denmark"},
  {id: "DJ", name: "Djibouti"},
  {id: "DM", name: "Dominica"},
  {id: "DO", name: "Dominican Republic (the)"},
  {id: "EC", name: "Ecuador"},
  {id: "EG", name: "Egypt"},
  {id: "SV", name: "El Salvador"},
  {id: "GQ", name: "Equatorial Guinea"},
  {id: "ER", name: "Eritrea"},
  {id: "EE", name: "Estonia"},
  {id: "SZ", name: "Eswatini"},
  {id: "ET", name: "Ethiopia"},
  {id: "FK", name: "Falkland Islands (the) [Malvinas]"},
  {id: "FO", name: "Faroe Islands (the)"},
  {id: "FJ", name: "Fiji"},
  {id: "FI", name: "Finland"},
  {id: "FR", name: "France"},
  {id: "GF", name: "French Guiana"},
  {id: "PF", name: "French Polynesia"},
  {id: "TF", name: "French Southern Territories (the)"},
  {id: "GA", name: "Gabon"},
  {id: "GM", name: "Gambia (the)"},
  {id: "GE", name: "Georgia"},
  {id: "DE", name: "Germany"},
  {id: "GH", name: "Ghana"},
  {id: "GI", name: "Gibraltar"},
  {id: "GR", name: "Greece"},
  {id: "GL", name: "Greenland"},
  {id: "GD", name: "Grenada"},
  {id: "GP", name: "Guadeloupe"},
  {id: "GU", name: "Guam"},
  {id: "GT", name: "Guatemala"},
  {id: "GG", name: "Guernsey"},
  {id: "GN", name: "Guinea"},
  {id: "GW", name: "Guinea-Bissau"},
  {id: "GY", name: "Guyana"},
  {id: "HT", name: "Haiti"},
  {id: "HM", name: "Heard Island and McDonald Islands"},
  {id: "VA", name: "Holy See (the)"},
  {id: "HN", name: "Honduras"},
  {id: "HK", name: "Hong Kong"},
  {id: "HU", name: "Hungary"},
  {id: "IS", name: "Iceland"},
  {id: "IN", name: "India"},
  {id: "ID", name: "Indonesia"},
  {id: "IR", name: "Iran (Islamic Republic of)"},
  {id: "IQ", name: "Iraq"},
  {id: "IE", name: "Ireland"},
  {id: "IM", name: "Isle of Man"},
  {id: "IL", name: "Israel"},
  {id: "IT", name: "Italy"},
  {id: "JM", name: "Jamaica"},
  {id: "JP", name: "Japan"},
  {id: "JE", name: "Jersey"},
  {id: "JO", name: "Jordan"},
  {id: "KZ", name: "Kazakhstan"},
  {id: "KE", name: "Kenya"},
  {id: "KI", name: "Kiribati"},
  {id: "KP", name: "Korea (the Democratic People's Republic of)"},
  {id: "KR", name: "Korea (the Republic of)"},
  {id: "KW", name: "Kuwait"},
  {id: "KG", name: "Kyrgyzstan"},
  {id: "LA", name: "Lao People's Democratic Republic (the)"},
  {id: "LV", name: "Latvia"},
  {id: "LB", name: "Lebanon"},
  {id: "LS", name: "Lesotho"},
  {id: "LR", name: "Liberia"},
  {id: "LY", name: "Libya"},
  {id: "LI", name: "Liechtenstein"},
  {id: "LT", name: "Lithuania"},
  {id: "LU", name: "Luxembourg"},
  {id: "MO", name: "Macao"},
  {id: "MG", name: "Madagascar"},
  {id: "MW", name: "Malawi"},
  {id: "MY", name: "Malaysia"},
  {id: "MV", name: "Maldives"},
  {id: "ML", name: "Mali"},
  {id: "MT", name: "Malta"},
  {id: "MH", name: "Marshall Islands (the)"},
  {id: "MQ", name: "Martinique"},
  {id: "MR", name: "Mauritania"},
  {id: "MU", name: "Mauritius"},
  {id: "YT", name: "Mayotte"},
  {id: "MX", name: "Mexico"},
  {id: "FM", name: "Micronesia (Federated States of)"},
  {id: "MD", name: "Moldova (the Republic of)"},
  {id: "MC", name: "Monaco"},
  {id: "MN", name: "Mongolia"},
  {id: "ME", name: "Montenegro"},
  {id: "MS", name: "Montserrat"},
  {id: "MA", name: "Morocco"},
  {id: "MZ", name: "Mozambique"},
  {id: "MM", name: "Myanmar"},
  {id: "NA", name: "Namibia"},
  {id: "NR", name: "Nauru"},
  {id: "NP", name: "Nepal"},
  {id: "NL", name: "Netherlands (the)"},
  {id: "NC", name: "New Caledonia"},
  {id: "NZ", name: "New Zealand"},
  {id: "NI", name: "Nicaragua"},
  {id: "NE", name: "Niger (the)"},
  {id: "NG", name: "Nigeria"},
  {id: "NU", name: "Niue"},
  {id: "NF", name: "Norfolk Island"},
  {id: "MP", name: "Northern Mariana Islands (the)"},
  {id: "NO", name: "Norway"},
  {id: "OM", name: "Oman"},
  {id: "PK", name: "Pakistan"},
  {id: "PW", name: "Palau"},
  {id: "PS", name: "Palestine, State of"},
  {id: "PA", name: "Panama"},
  {id: "PG", name: "Papua New Guinea"},
  {id: "PY", name: "Paraguay"},
  {id: "PE", name: "Peru"},
  {id: "PH", name: "Philippines (the)"},
  {id: "PN", name: "Pitcairn"},
  {id: "PL", name: "Poland"},
  {id: "PT", name: "Portugal"},
  {id: "PR", name: "Puerto Rico"},
  {id: "QA", name: "Qatar"},
  {id: "MK", name: "Republic of North Macedonia"},
  {id: "RO", name: "Romania"},
  {id: "RU", name: "Russian Federation (the)"},
  {id: "RW", name: "Rwanda"},
  {id: "RE", name: "Réunion"},
  {id: "BL", name: "Saint Barthélemy"},
  {id: "SH", name: "Saint Helena, Ascension and Tristan da Cunha"},
  {id: "KN", name: "Saint Kitts and Nevis"},
  {id: "LC", name: "Saint Lucia"},
  {id: "MF", name: "Saint Martin (French part)"},
  {id: "PM", name: "Saint Pierre and Miquelon"},
  {id: "VC", name: "Saint Vincent and the Grenadines"},
  {id: "WS", name: "Samoa"},
  {id: "SM", name: "San Marino"},
  {id: "ST", name: "Sao Tome and Principe"},
  {id: "SA", name: "Saudi Arabia"},
  {id: "SN", name: "Senegal"},
  {id: "RS", name: "Serbia"},
  {id: "SC", name: "Seychelles"},
  {id: "SL", name: "Sierra Leone"},
  {id: "SG", name: "Singapore"},
  {id: "SX", name: "Sint Maarten (Dutch part)"},
  {id: "SK", name: "Slovakia"},
  {id: "SI", name: "Slovenia"},
  {id: "SB", name: "Solomon Islands"},
  {id: "SO", name: "Somalia"},
  {id: "ZA", name: "South Africa"},
  {id: "GS", name: "South Georgia and the South Sandwich Islands"},
  {id: "SS", name: "South Sudan"},
  {id: "ES", name: "Spain"},
  {id: "LK", name: "Sri Lanka"},
  {id: "SD", name: "Sudan (the)"},
  {id: "SR", name: "Suriname"},
  {id: "SJ", name: "Svalbard and Jan Mayen"},
  {id: "SE", name: "Sweden"},
  {id: "CH", name: "Switzerland"},
  {id: "SY", name: "Syrian Arab Republic"},
  {id: "TW", name: "Taiwan"},
  {id: "TJ", name: "Tajikistan"},
  {id: "TZ", name: "Tanzania, United Republic of"},
  {id: "TH", name: "Thailand"},
  {id: "TL", name: "Timor-Leste"},
  {id: "TG", name: "Togo"},
  {id: "TK", name: "Tokelau"},
  {id: "TO", name: "Tonga"},
  {id: "TT", name: "Trinidad and Tobago"},
  {id: "TN", name: "Tunisia"},
  {id: "TR", name: "Turkey"},
  {id: "TM", name: "Turkmenistan"},
  {id: "TC", name: "Turks and Caicos Islands (the)"},
  {id: "TV", name: "Tuvalu"},
  {id: "UG", name: "Uganda"},
  {id: "UA", name: "Ukraine"},
  {id: "AE", name: "United Arab Emirates"},
  {id: "GB", name: "United Kingdom"},
  {id: "US", name: "United States"},
  {id: "UY", name: "Uruguay"},
  {id: "UZ", name: "Uzbekistan"},
  {id: "VU", name: "Vanuatu"},
  {id: "VE", name: "Venezuela (Bolivarian Republic of)"},
  {id: "VN", name: "Vietnam"},
  {id: "VG", name: "Virgin Islands (British)"},
  {id: "VI", name: "Virgin Islands (U.S.)"},
  {id: "WF", name: "Wallis and Futuna"},
  {id: "EH", name: "Western Sahara"},
  {id: "YE", name: "Yemen"},
  {id: "ZM", name: "Zambia"},
  {id: "ZW", name: "Zimbabwe"},
  {id: "AX", name: "Åland Islands"},
]

export const countriesObj: Record<string, string> = {
  "AF": "Afghanistan",
  "AL": "Albania",
  "DZ": "Algeria",
  "AS": "American Samoa",
  "AD": "Andorra",
  "AO": "Angola",
  "AI": "Anguilla",
  "AQ": "Antarctica",
  "AG": "Antigua and Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BS": "Bahamas (the)",
  "BH": "Bahrain",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarus",
  "BE": "Belgium",
  "BZ": "Belize",
  "BJ": "Benin",
  "BM": "Bermuda",
  "BT": "Bhutan",
  "BO": "Bolivia (Plurinational State of)",
  "BQ": "Bonaire, Sint Eustatius and Saba",
  "BA": "Bosnia and Herzegovina",
  "BW": "Botswana",
  "BV": "Bouvet Island",
  "BR": "Brazil",
  "IO": "British Indian Ocean Territory (the)",
  "BN": "Brunei Darussalam",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "CV": "Cabo Verde",
  "KH": "Cambodia",
  "CM": "Cameroon",
  "CA": "Canada",
  "KY": "Cayman Islands (the)",
  "CF": "Central African Republic (the)",
  "TD": "Chad",
  "CL": "Chile",
  "CN": "China",
  "CX": "Christmas Island",
  "CC": "Cocos (Keeling) Islands (the)",
  "CO": "Colombia",
  "KM": "Comoros (the)",
  "CD": "Congo (the Democratic Republic of the)",
  "CG": "Congo (the)",
  "CK": "Cook Islands (the)",
  "CR": "Costa Rica",
  "HR": "Croatia",
  "CU": "Cuba",
  "CW": "Curaçao",
  "CY": "Cyprus",
  "CZ": "Czechia",
  "CI": "Côte d'Ivoire",
  "DK": "Denmark",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "Dominican Republic (the)",
  "EC": "Ecuador",
  "EG": "Egypt",
  "SV": "El Salvador",
  "GQ": "Equatorial Guinea",
  "ER": "Eritrea",
  "EE": "Estonia",
  "SZ": "Eswatini",
  "ET": "Ethiopia",
  "FK": "Falkland Islands (the) [Malvinas]",
  "FO": "Faroe Islands (the)",
  "FJ": "Fiji",
  "FI": "Finland",
  "FR": "France",
  "GF": "French Guiana",
  "PF": "French Polynesia",
  "TF": "French Southern Territories (the)",
  "GA": "Gabon",
  "GM": "Gambia (the)",
  "GE": "Georgia",
  "DE": "Germany",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GR": "Greece",
  "GL": "Greenland",
  "GD": "Grenada",
  "GP": "Guadeloupe",
  "GU": "Guam",
  "GT": "Guatemala",
  "GG": "Guernsey",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HM": "Heard Island and McDonald Islands",
  "VA": "Holy See (the)",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Hungary",
  "IS": "Iceland",
  "IN": "India",
  "ID": "Indonesia",
  "IR": "Iran (Islamic Republic of)",
  "IQ": "Iraq",
  "IE": "Ireland",
  "IM": "Isle of Man",
  "IL": "Israel",
  "IT": "Italy",
  "JM": "Jamaica",
  "JP": "Japan",
  "JE": "Jersey",
  "JO": "Jordan",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "KP": "Korea (the Democratic People's Republic of)",
  "KR": "Korea (the Republic of)",
  "KW": "Kuwait",
  "KG": "Kyrgyzstan",
  "LA": "Lao People's Democratic Republic (the)",
  "LV": "Latvia",
  "LB": "Lebanon",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libya",
  "LI": "Liechtenstein",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MO": "Macao",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malta",
  "MH": "Marshall Islands (the)",
  "MQ": "Martinique",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "YT": "Mayotte",
  "MX": "Mexico",
  "FM": "Micronesia (Federated States of)",
  "MD": "Moldova (the Republic of)",
  "MC": "Monaco",
  "MN": "Mongolia",
  "ME": "Montenegro",
  "MS": "Montserrat",
  "MA": "Morocco",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Netherlands (the)",
  "NC": "New Caledonia",
  "NZ": "New Zealand",
  "NI": "Nicaragua",
  "NE": "Niger (the)",
  "NG": "Nigeria",
  "NU": "Niue",
  "NF": "Norfolk Island",
  "MP": "Northern Mariana Islands (the)",
  "NO": "Norway",
  "OM": "Oman",
  "PK": "Pakistan",
  "PW": "Palau",
  "PS": "Palestine, State of",
  "PA": "Panama",
  "PG": "Papua New Guinea",
  "PY": "Paraguay",
  "PE": "Peru",
  "PH": "Philippines (the)",
  "PN": "Pitcairn",
  "PL": "Poland",
  "PT": "Portugal",
  "PR": "Puerto Rico",
  "QA": "Qatar",
  "MK": "Republic of North Macedonia",
  "RO": "Romania",
  "RU": "Russian Federation (the)",
  "RW": "Rwanda",
  "RE": "Réunion",
  "BL": "Saint Barthélemy",
  "SH": "Saint Helena, Ascension and Tristan da Cunha",
  "KN": "Saint Kitts and Nevis",
  "LC": "Saint Lucia",
  "MF": "Saint Martin (French part)",
  "PM": "Saint Pierre and Miquelon",
  "VC": "Saint Vincent and the Grenadines",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "Sao Tome and Principe",
  "SA": "Saudi Arabia",
  "SN": "Senegal",
  "RS": "Serbia",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SX": "Sint Maarten (Dutch part)",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "SB": "Solomon Islands",
  "SO": "Somalia",
  "ZA": "South Africa",
  "GS": "South Georgia and the South Sandwich Islands",
  "SS": "South Sudan",
  "ES": "Spain",
  "LK": "Sri Lanka",
  "SD": "Sudan (the)",
  "SR": "Suriname",
  "SJ": "Svalbard and Jan Mayen",
  "SE": "Sweden",
  "CH": "Switzerland",
  "SY": "Syrian Arab Republic",
  "TW": "Taiwan",
  "TJ": "Tajikistan",
  "TZ": "Tanzania, United Republic of",
  "TH": "Thailand",
  "TL": "Timor-Leste",
  "TG": "Togo",
  "TK": "Tokelau",
  "TO": "Tonga",
  "TT": "Trinidad and Tobago",
  "TN": "Tunisia",
  "TR": "Turkey",
  "TM": "Turkmenistan",
  "TC": "Turks and Caicos Islands (the)",
  "TV": "Tuvalu",
  "UG": "Uganda",
  "UA": "Ukraine",
  "AE": "United Arab Emirates",
  "GB": "United Kingdom",
  "UM": "United States Minor Outlying Islands (the)",
  "US": "United States",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VE": "Venezuela (Bolivarian Republic of)",
  "VN": "Vietmam",
  "VG": "Virgin Islands (British)",
  "VI": "Virgin Islands (U.S.)",
  "WF": "Wallis and Futuna",
  "EH": "Western Sahara",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe",
  "AX": "Åland Islands"
}

