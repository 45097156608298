import React, {useEffect, useMemo, useRef, useState, useCallback} from "react";
import HeadingBlock from "../HeadingBlock/HeadingBlock";
import Button from "../Button/Button";
import 'react-quill/dist/quill.snow.css';
import {Link, useHistory} from "react-router-dom";
import Icon from "../Icons/Icon";
import {unwrapResult} from "@reduxjs/toolkit";
import useInterval from "react-useinterval";
import {FetchState, IntervalTime, TripMessage, FieldQuill, FIELD_ERRORS, TripViewType} from "../../types";
import {addTripMessage, fetchTripMessages, deleteTripMessage, updateTripMessage} from "../../store/tripMessagesSlice";
import {useAppDispatch, useAppSelector} from "../../store/store";
import Loading from "../Loading/Loading";
import DOMPurify from 'dompurify'
import {arraySelector, getName, isQuillEmpty} from "../../utils/utils";
import moment from "moment/moment";
import {toast} from "react-toastify";
import ActionMenu from "../ActionMenu/ActionMenu";
import ReactQuill, {UnprivilegedEditor} from 'react-quill'
import h3Icon from "../../img/h3-icon"
import {setTripView, showAccomsForm, showTripMessagesForm} from "../../store/ui";
import EmptyListingMessage from "../EmptyListingMessage/EmptyListingMessage";
import {fetchTrip} from "../../store/tripSlice";
import TripMessagesForm from "../TripMessagesForm/TripMessagesForm";

export interface TripMessagesProps {
  tripId: string
}

export interface TripMessageProps {
  message: TripMessage,
  currentContent: FieldQuill | null,
  onDelete: (id: number) => void,
  // Return a boolean to close the form after
  onUpdateMessage: (id: number, content: FieldQuill | null) => boolean,
}

// Add custom icon to the toolbar (it has to be an svg as a string)
var icons = ReactQuill.Quill.import('ui/icons'); icons.header[3] = h3Icon;

const quillModules = {
  toolbar: [
    [{ 'header': 3 }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['link'], ['clean']
  ],
};

const quillFormats = [
  'header', 'bold', 'italic', 'underline', 'list', 'bullet', 'link',
]

const Message = ({message, onDelete, onUpdateMessage, currentContent}: TripMessageProps) => {
  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(message.content_html)
  })
  const {user} = useAppSelector((state) => state.auth)
  const postedByUser = message.posted_by_profile;
  const canEdit = postedByUser?.id === user?.id;
  const [updatedContent, setUpdatedContent] = useState<FieldQuill | null>(currentContent);
  const [isEditing, setIsEditing] = useState<boolean>()
  const [fieldErrors, setFieldErrors] = useState<string[]>([])
  const [isMessageEmpty, setIsMessageEmpty] = useState<boolean>(false);

  return (
    <div className="p-4 md:p-6 lg:p-8 rounded-lg bg-offWhite dark:bg-grey-800">
      <div className="flex flex-row items-center justify-between w-full mb-4">

        <div className="flex flex-row items-center ">
          <Link to={`/profile-${postedByUser.id}`}>
            {postedByUser.profile.image_thumbnail ?
              <img className="w-8 h-8 rounded-full" src={postedByUser.profile.image_thumbnail} alt={`${getName(postedByUser)}'s picture`}/> :
              <Icon iconName="person" className="w-8 h-8 text-black border border-white rounded-full"/>
            }
          </Link>
          <div className="pl-3 flex flex-col">
            <Link to={`/profile-${postedByUser.id}`} className="text-md lg:text-base leading-none font-semibold">{getName(postedByUser)}</Link>
            <span className="text-sm text-gray">{moment.utc(message.created_at).format(`MMM DD, YYYY | hh:mm A`)}</span>
          </div>
        </div>

        {/* Show menu if you are the plan creator or trip owner */}
        {canEdit && <ActionMenu name={'this message'} onEdit={() => setIsEditing(!isEditing)} onDelete={() => onDelete(message.id)}/>}
      </div>
      <hr className="border-lightGray mb-2 dark:border-grey-700"/>

      {isEditing &&
        <div>
          <ReactQuill
            className="rounded-md bg-white prose dark:border-grey-800 dark:text-white dark:bg-grey-700"
            theme="snow"
            value={updatedContent?.html}
            /* Convert to this object for django to accept it as a quill object */
            onChange={(value, delta, sources, editor) => {
              setUpdatedContent({delta: delta, html: value})
              setFieldErrors([])
              setIsMessageEmpty(isQuillEmpty(editor))
            }}
            onBlur={(range, sources, editor) => setIsMessageEmpty(isQuillEmpty(editor))}
            formats={quillFormats}
            modules={quillModules}
            placeholder={'Enter your message here'}
          />
          {fieldErrors.length > 0 && fieldErrors.map((error,index) => <p key={`message-error-${index}`} className="text-sm text-red-400 my-0">{error}</p>)}
          <div className="flex gap-4">
            <Button style="brand" classes="mt-6" onClick={() => {
              setFieldErrors([])
              if (isMessageEmpty) {
                setFieldErrors([FIELD_ERRORS.empty])
                return;
              }
              const isEditing = onUpdateMessage(message.id, updatedContent);
              setIsEditing(isEditing)
            }}>Update</Button>
            <Button style="light" classes="mt-6" onClick={() => setIsEditing(false)}>Cancel</Button>
          </div>
        </div>
      }

      {!isEditing &&
        <div className="prose" dangerouslySetInnerHTML={sanitizedData()}/>
      }
    </div>
  );
}

export const TripMessages = ({tripId}: TripMessagesProps) => {
  const [messagesPolling, setMessagesPolling] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const {status: messagesStatus} = useAppSelector((state) => state.tripMessages)
  const {messages} = useAppSelector((state) => state.tripMessages)
  const [messagesArray, setMessagesArray] = useState<TripMessage[]>([])
  const [isMessageEmpty, setIsMessageEmpty] = useState<boolean>(true);
  const dispatch = useAppDispatch()
  const history = useHistory()

  useEffect(() => {
    // Set trip view type so things like mobile subnav can utilize
    dispatch(setTripView(TripViewType.TripMessages))
  }, [])

  // Get trip when visiting page
  useEffect(() => {
    //@ts-ignore
    dispatch(fetchTrip(tripId))
      .then(unwrapResult)
      .catch((err: any) => {
        console.log(err)
        history.push('/')
      })
  }, [tripId, dispatch])

  useEffect(() => {
    setMessagesArray(arraySelector(messages))
  }, [messages])

  // Load messages and load them in intervals after that
  const loadMessages = useCallback(() => {
    setMessagesPolling(true);
    dispatch(fetchTripMessages(tripId))
      .then(unwrapResult)
      .then(() => {
        setMessagesPolling(false);
        setFirstLoad(false);
      });
  }, [tripId]);

  useInterval(() => loadMessages(), IntervalTime.HALF_MINUTE);

  useEffect(() => {
    loadMessages();
  }, [loadMessages]);


  const handleUpdateMessage = (id: number, content: FieldQuill | null) => {
    const contentString = JSON.stringify(content)
    dispatch(updateTripMessage({id, content: contentString}))
      .then(unwrapResult)
      .then(() => {
        toast.success(`Updated message! `)
      }).catch((err) => {
      toast.error(`😟 oops something went wrong - ${err.message}`)
    })

    return false;
  }

  const handleDeleteMessage = (id: number) => {
    try {
      dispatch(deleteTripMessage(id))
      toast.success(`💥 Deleted message.`)
    } catch (err) {
      toast.error(`😟 oops something went wrong - ${err.message}`)
    }
  }

  return (
    <div className="mt-8 md:mt-12">
      <HeadingBlock title="Messages" subtitle="Post a global message here for everyone attending the trip to see.">
        <Button classes="lg:hidden" style="dark" onClick={() => dispatch(showTripMessagesForm())}>Add Message</Button>
      </HeadingBlock>

      <div className="hidden lg:block">
        <TripMessagesForm tripId={tripId}/>
      </div>

      {/* Show the loading indicator on first load and not on polling requests */}
      {messagesStatus !== FetchState.Fulfilled && firstLoad &&
        <Loading/>
      }

      {messagesArray && messagesArray.length > 0 &&
        <div className="space-y-4 md:space-y-6 mt-6 md:mt-10">
          <h3 className="mb-0">Recent Messages</h3>
          <hr className="border-lightGray mb-2 dark:border-grey-600"/>
          <div className="space-y-4 md:space-y-6">
            {messagesArray.map((message) => (
              <Message
                key={`trip-message-${message.id}`}
                message={message}
                onDelete={(id: number) => handleDeleteMessage(id)}
                onUpdateMessage={(id, content) => handleUpdateMessage(id, content)}
                currentContent={{delta: null, html: message.content_html}}
              />)
            )}
          </div>
        </div>
      }

      {!firstLoad && messagesArray && messagesArray.length < 1 &&
        <EmptyListingMessage message={`✉️ This trip doesn't have any messages yet.`} buttonLabel="Add a message" onClick={() =>dispatch(showTripMessagesForm())}/>
      }
    </div>
  )
}

export default TripMessages;
