import React from 'react'
import FormLabel from "./FormLabel";

export interface FormFieldProps {
  id?: string;
  field?: React.ReactNode;
  errors?: any;
  label?: string;
  required?: boolean;
  helpText?: string;
  classes?: string;
}

const FormField: React.FC<FormFieldProps> = ({id, field, errors, label, required, helpText, classes}) => {

  function renderErrors() {
    return <span className="text-sm text-red-400">{errors}</span>
  }

  function renderField() {
    if (field) {
      return field;
    }
  }

  function renderLabel() {
    return <FormLabel classes="text-brand text-sm" htmlFor={id} label={label} required={required} />;
  }

  function renderHelpText() {
    if (helpText) {
      return <span className="text-gray text-sm text-right block">{helpText}</span>
    }
  }

  return (
    <div className={classes}>
      {renderLabel()}
      {renderField()}
      {renderHelpText()}
      {renderErrors()}
    </div>
  )
}

export default FormField;
