import React, {useState} from 'react';
import AuthLayout from "../Containers/AuthLayout";
import FormField from "../Components/Forms/FormField";
import {Link, useHistory} from "react-router-dom";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {useAppDispatch} from "../store/store";
import {resetPasswordEmail} from "../store/resetPasswordSlice";
import {toast} from "react-toastify";
import {unwrapResult} from "@reduxjs/toolkit";


const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
})

interface formValuesType {
  email: string;
}

const ForgotPassword = () => {

  const dispatch = useAppDispatch()
  const history = useHistory();
  const [formErrors, setFormErrors] = useState<string | null>(null)

  const initialValues = {
    email: '',
  }

  async function handleSubmit(values: formValuesType, submitting: { setSubmitting: (isSubmitting: boolean) => void, resetForm: any }) {
    try {
      const resetResult: any = await dispatch(resetPasswordEmail({...values}))
      const resetStatus = unwrapResult(resetResult)
      toast.success('Email sent, check your inbox 😀')
      submitting.setSubmitting(false)
      history.push('/login')
    } catch (rejectedValueOrSerializedError) {
      setFormErrors(rejectedValueOrSerializedError.message)
      submitting.setSubmitting(false)
      toast.error('Sorry there was an issue, please try again.')
    }
  }

  return (
    <AuthLayout formTitle="Forgot Password"
                formSubtitle="It's okay, don't be sad. Enter your email and we will send you instructions on how to reset it."
                pageLink={<Link className="w-full inline-flex justify-center text-white font-medium py-2 px-2 text-center bg-black w-full bottom-0 rounded-t-none rounded-b-xl dark:text-white dark:bg-darkGrey hover:bg-darkGrey" to="/login">Back to Login</Link>}
    >
      <Formik initialValues={initialValues}
              onSubmit={(values: formValuesType, {setSubmitting, resetForm}: FormikHelpers<formValuesType>) => {
                handleSubmit(values, {setSubmitting, resetForm})
              }}
              validationSchema={forgotPasswordValidationSchema}>
        {({errors, touched, isSubmitting}) => (
          <Form method="post" className="flex flex-wrap">
            <FormField classes="mb-4 w-full" errors={errors.email && touched.email ? errors.email : null} field={<Field type="email" placeholder="Email" name="email" className="form-input"/>}/>
            <button type="submit" className="btn btn--brand w-full">{isSubmitting ? "Sending..." : "Email me"}</button>
            {formErrors && <div className="text-red-500 p-2 mt-2 rounded-md w-full text-center">{formErrors}</div>}
          </Form>
        )}
      </Formik>
    </AuthLayout>
  )
}

export default ForgotPassword;
