import React, {ReactHTMLElement} from 'react'
import Icon from "../Icons/Icon";
import {Link} from "react-router-dom";
import {userId} from "../../types";

export interface LargeNameTagProps {
  id?: userId;
  name?: string | JSX.Element;
  travelerType?: string;
  image?: string;
  instagram?: string | null;
  pinterest?: string | null;
}

const LargeNameTag: React.FC<LargeNameTagProps> = ({id, name, travelerType, image, instagram, pinterest}) => {
  return (
    <div className="flex flex-row items-center mb-8">
      <div className="flex-shrink-0">
        <Link to={`/profile-${id}`}>
        {image ?
          <img src={image} className="w-24 h-24 md:w-32 md:h-32 rounded-full" alt="Your Profile Photo"/> :
          <Icon iconName="person" className="w-24 h-24 md:w-32 md:h-32 text-lightGray"/>
        }
        </Link>
      </div>
      <div className="flex flex-col ml-4">
        <Link to={`/profile-${id}`}>
          <h3 className="font-bold text-lg md:text-2xl mb-0 leading-snug">{name}</h3>
        </Link>
        <span className="text-brand md:text-lg leading-snug">{travelerType}</span>

        <div className="py-2 space-x-1 flex flex-row">
          {instagram &&
          <a className="group transition" href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noreferrer noopener">
            <Icon className="text-darkGrey w-5 h-5 group-hover:text-brand transition dark:text-grey-300" iconName="instagram"/>
          </a>
          }
          {pinterest &&
          <a className="group transition" href={`https://www.pinterest.com/${pinterest}`} target="_blank" rel="noreferrer noopener">
            <Icon className="text-darkGrey w-5 h-5 group-hover:text-brand transition dark:text-grey-300" iconName="pinterest"/>
          </a>
          }
        </div>

      </div>
    </div>

  )
}

export default LargeNameTag
