import React, {useEffect, useState} from 'react'
import {Formik, Field, Form, FormikHelpers} from 'formik';
import * as Yup from "yup";
import {unwrapResult} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import FormField from "../Forms/FormField";
import Button from "../Button/Button";
import {addIdea, updateIdea} from "../../store/planSlice";
import {useDispatch} from "react-redux";
import {Coordinates, tripId} from "../../types";
import {useAppSelector} from "../../store/store";
import SidebarHeading from "../SidebarHeading/SidebarHeading";
import Picker, {IEmojiData} from 'emoji-picker-react';
import {clearActiveSidebar} from "../../store/ui"
import NameTag from "../NameTag";
import {getName} from "../../utils/utils";
import {geocodeByAddress, getLatLng, PropTypes} from 'react-places-autocomplete';
import AutoCompleteFillableField from "../AutoCompleteFillableField/AutoCompleteFillableField";
import parseGoogleAddressComponent, {parsedAddressProps} from "../../utils/parseAddressComponent";
import LocationSearchInput from "../LocationSearchInput/LocationSearchInput";

interface Props {
  tripId: tripId
  onCancel: () => void;
  planId: number | null;
  tripCountries?: string;
}

interface formValuesType {
  name: string;
  notes: string;
  url: string;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  group: number;
}

const IdeaFormSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .max(40, 'Name too long'),
  notes: Yup.string()
    .max(100, 'Notes too long!'),
  address: Yup.string()
    .max(100, 'Address too long!'),
  city: Yup.string()
    .max(60, 'City too long!'),
  state: Yup.string()
    .max(60, 'State / Province too long!'),
  postal_code: Yup.string()
    .max(12, 'Postal code too long!'),
  url: Yup.string().url(),
  group: Yup.number().nullable(true)
})

const IdeaForm: React.FC<Props> = ({tripId, onCancel, planId, tripCountries}) => {

  const dispatch = useDispatch();
  const {user} = useAppSelector((state) => state.auth)
  const {planGroups} = useAppSelector((state) => state.planGroups)
  const [emojiPickerOpen, setEmojiPickerOpen] = useState<boolean>(false)
  //@ts-ignore
  const plan = useAppSelector((state) => state.plans.plansObject.plans[planId])
  const [chosenEmoji, setChosenEmoji] = useState<string | null>(plan?.emoji || null)
  const [coordinates, setCoordinates] = useState<Coordinates | null>()

  useEffect(() => {
    console.log(coordinates)
  }, [coordinates])

  // Place object with a bunch of good stuff
  const [autoCompletedPlace, setAutoCompletedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [formattedAddress, setFormattedAddress] = useState<typeof parsedAddressProps | null>(null)

  const onEmojiClick = (event: React.MouseEvent, emojiObject: IEmojiData) => {
    setChosenEmoji(emojiObject.emoji);
    setEmojiPickerOpen(false);
  };

  const initialValues = {
    name: plan?.name || '',
    notes: plan?.notes || '',
    url: plan?.url || '',
    address: plan?.address || '',
    city: plan?.city || '',
    state: plan?.state || '',
    postal_code: plan?.postal_code || '',
    group: plan?.group || ''
  }

  // Used to fetch lat lng when an autocomplete selection isn't made
  const fetchLatLng = async (values: formValuesType, nonFormValues: any) => {
    // Don't even try if there is no street address entered or if there are already coordinates from autocomplete
    const {address, city, state, postal_code} = values
    if (!address || !city || !state) {
      return;
    }
    await geocodeByAddress(`${address} ${city} ${state} ${postal_code} ${tripCountries?.split(',')}`)
      .then((results) => getLatLng(results[0]))
      .then(({lat, lng}) => {
        nonFormValues.lng = lng
        nonFormValues.lat = lat
        setCoordinates({lat: lat, lng: lng})
      })
      .catch((error: any) => console.error('Error fetching lat lng', error));
  }

  async function handleSubmit(values: formValuesType, submitting: { setSubmitting: (isSubmitting: boolean) => void, resetForm: any }) {
    if (!user) {
      return
    }

    const nonFormValues = {
      trip: tripId,
      creator: plan?.creator || user.id,
      emoji: chosenEmoji,
      lat: coordinates?.lat || null,
      lng: coordinates?.lng || null,
    }

    await fetchLatLng(values, nonFormValues);

    if (!planId) {
      try {
        const createResult: any = await dispatch(addIdea({...nonFormValues, ...values}))
        const createdIdea = unwrapResult(createResult)
        toast.success(`🚀 ${createdIdea.name} created.`)
        submitting.resetForm()
        onCancel()
      } catch (err) {
        toast.error(`😟 Something went wrong - ${err.message}`)
      }
    } else {
      try {
        const updateResult: any = await dispatch(updateIdea({planId: planId, ...nonFormValues, ...values}))
        const updatedIdea = unwrapResult(updateResult)
        toast.success(`🚀 ${updatedIdea.name} updated.`)
        submitting.resetForm()
        onCancel()
      } catch (err) {
        toast.error(`😟 Something went wrong - ${err.message}`)
      }
    }
    submitting.setSubmitting(false)
  }

  // Autocomplete search options
  let sessionToken = new google.maps.places.AutocompleteSessionToken();
  const autoCompleteSearchOptions = {
    // If there are countries on the trip restrict zone to that otherwise don't restrict
    ...(tripCountries && {componentRestrictions: {country: tripCountries?.split(',')}}),
    sessionToken: sessionToken
  }

  return (
    <div>
      <SidebarHeading text={planId ? 'Update Idea' : 'Add Idea'} classes="mb-4"/>
      <p className="text-darkGrey leading-snug dark:text-grey-300">
        {planId
          ? 'Make any changes you need and don\'t forget to save.'
          : 'Add an idea to the trip using the form below. People will be able to vote on the idea.'}
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={IdeaFormSchema}
        onSubmit={(values: formValuesType, {setSubmitting, resetForm}: FormikHelpers<formValuesType>) => {
          handleSubmit(values, {setSubmitting, resetForm})
        }}>
        {({errors, touched, isSubmitting, setFieldValue}) => (
          <Form method="post" encType="multipart/form-data" className="mt-6 md:mt-8">

            {plan && plan?.creator !== user?.id &&
            <>
              <span className="uppercase text-xs font-medium text-brand">Editing</span>
              <div className="p-2 rounded-md bg-offWhite mb-3 dark:bg-grey-950">
                <div className="flex justify-start items-center">
                  <NameTag image={plan.creator_details.profile.image_thumbnail}
                           name={plan.creator_details.first_name ? plan.creator_details.first_name : plan.creator_details.username}
                           textClasses="!text-base font-medium"
                  />
                  <span className="text-base font-medium">'s idea</span>
                </div>
              </div>
            </>
            }

            <FormField
              label="Name"
              classes="w-full mb-4"
              required
              field={
                <Field id="name" name="name">
                  {({field}: any) => (
                    <>
                      <LocationSearchInput
                        onChange={((name) => setFieldValue(field.name, name))}
                        searchOptions={autoCompleteSearchOptions}
                        fieldValue={field.value}
                        fieldName="name"
                        fieldPlaceholder="Enter a name or search for it"
                        onGetLatLng={(coordinates) => setCoordinates(coordinates)}
                        onLocationSelected={(place, suggestion) => {
                          setAutoCompletedPlace(place)
                          setFormattedAddress(parseGoogleAddressComponent(place.address_components))
                          // Set the place name based off of selected item
                          if (suggestion.formattedSuggestion.mainText) {
                            setFieldValue(field.name, suggestion.formattedSuggestion.mainText)
                          }
                        }}/>
                    </>
                  )}
                </Field>
              }
              errors={errors.name && touched.name ? errors.name : null}/>

            <div className="flex flex-row items-center mb-4">
              {/* Notes */}
              <FormField label="Notes" classes="w-full"
                         field={<Field className={`form-input ${errors.notes && touched.notes && 'border-red-500'}`} type="textarea" name="notes" placeholder="Add notes about the idea"/>}
                         errors={errors.notes && touched.notes ? errors.notes : null}
              />

              {/* Emoji Field */}
              <div className="flex flex-col ml-3 relative">
                <span className="text-brand text-sm">Emoji</span>
                <button type="button"
                        className={`btn btn--light dark:!bg-grey-600 ${errors.notes && 'mb-4'}`}
                        onClick={() => {
                          setEmojiPickerOpen(!emojiPickerOpen)
                        }}>
                  <span>{chosenEmoji ? chosenEmoji : <span className="opacity-80 dark:opacity-100">😎</span>}</span>
                </button>
                {emojiPickerOpen && <Picker pickerStyle={{position: 'absolute', right: '0px', top: '65px', zIndex: '1'}} onEmojiClick={onEmojiClick}/>}
              </div>
            </div>

            {/* Group (category) */}
            <FormField label="Category" classes="mb-4 w-full"
                       field={
                         <Field className={`form-input ${errors.group && touched.group && 'border-red-500'}`} component="select" name="group">
                           <option value="">Select a category</option>
                           {planGroups.map((group, i) => <option key={`category-${i}-${group.id}`} value={group.id}>{group.title}</option>)}
                         </Field>
                       }
                       errors={errors.group && touched.group ? errors.group : null}
            />

            {/* Url */}
            <FormField label="Website URL" classes="mb-4 w-full"
                       field={<Field className={`form-input ${errors.url && touched.url && 'border-red-500'}`} type="textarea" name="url" placeholder="https://coolwebsite.com"/>}
                       errors={errors.url && touched.url ? errors.url : null}
            />

            {/* Address */}
            <AutoCompleteFillableField autoCompletedValue={formattedAddress?.street} label="Address" name="address" placeholder="123 fun street"/>


            {/* City */}
            <AutoCompleteFillableField autoCompletedValue={formattedAddress?.city} label="City" name="city" placeholder="City or town name"/>

            <div className="flex flex-row items-center mb-4 space-x-4">
              {/* State */}
              <AutoCompleteFillableField autoCompletedValue={formattedAddress?.state} label="State / Province" name="state" placeholder="State / Province"/>

              {/* Postal Code */}
              <AutoCompleteFillableField autoCompletedValue={formattedAddress?.postal_code} label="Postal Code / Zip Code" name="postal_code" placeholder="Zip / Postal Code"/>
            </div>

            <div className="mt-8 pb-4">
              <Button style="dark" onClick={() => {
              }} classes="mb-4 xl:mb-0 mr-4">{isSubmitting ? 'Saving..' : 'Save Idea'}</Button>
              <button
                type="submit"
                onClick={(e) => {
                  onCancel()
                  e.preventDefault()
                  dispatch(clearActiveSidebar())
                }}
                className="btn btn--light">
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default IdeaForm
