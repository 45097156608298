import React from 'react'

export interface FormLabelProps {
  classes?: string;
  htmlFor?: string;
  label?: string;
  required?: boolean;
}

const FormLabel: React.FC<FormLabelProps> = ({classes, htmlFor, label, required}) => {
  return (
      <label htmlFor={htmlFor} className={classes}>
        {label}
        {required && (
          <span className="text-red-400">*</span>
        )}
      </label>
  )
}

export default FormLabel;
