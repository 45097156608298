import React from 'react'
import {acceptTripInvite, rejectTripInvite, tripInvite} from "../../store/tripInviteSlice";
import TripInvite from "../TripInvite/TripInvite";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {getName} from "../../utils/utils";

interface TripInvitesProps {
  tripInvites: tripInvite[]
}

const TripInvites = ({tripInvites}: TripInvitesProps) => {
  const dispatch = useDispatch();

  function handleAcceptTripInvite(inviteId: number,  tripName: string) {
    dispatch(acceptTripInvite(inviteId))
    toast(`🎉 You are now attending ${tripName}!`)
  }

  function handleRejectTripInvite(inviteId: number, name: string) {
    dispatch(rejectTripInvite(inviteId))
    toast(`Rejected trip invite from ${name}  💔`)
  }

  return (
    <div className="mb-10 md:mb-12">
      <div className="mb-4">
        <h2 className="font-bold text-xl md:text-2xl text-brand">Trip Invites</h2>
        <p className="text-gray">You've been invited to some trips! 🎉</p>
      </div>
      <div className="grid grid-cols-1 gap-6 bg-offWhite p-4 md:p-6 rounded-md dark:bg-grey-950">
        {tripInvites.map((invite) => (
          <TripInvite
            key={invite.id}
            invite={invite}
            onAcceptTripInvite={() => handleAcceptTripInvite(invite.id, invite.trip.name)}
            onRejectTripInvite={() => handleRejectTripInvite(invite.id, getName(invite.sender))}/>
        ))}
      </div>
    </div>
  )
}

export default TripInvites;
