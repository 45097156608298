import React from 'react';
import NameTag from '../NameTag';
import NavbarMenu from "./NavbarMenu";
import Logo from "../Logo";
import useMenuItems from '../MenuItems/MenuItems'
import {Link} from "react-router-dom";
import {useAppSelector} from "../../store/store";
import {getName} from "../../utils/utils";
import {FetchState} from "../../types";
import Loading from "../Loading/Loading";

interface Props {
  name?: string;
  image?: string | null;
  signupQueryParams?: string;
}

const Navbar: React.FC<Props> = ({name, image,  signupQueryParams}) => {
  const menuItems = useMenuItems();
  const {auth} = useAppSelector(state => state)
  const loading = auth.status === FetchState.Loading;

  return (
    <nav className="shadow-lg py-2 bg-white dark:bg-grey-950">
      <div className="container mx-auto px-6">
        <div className="flex justify-between items-center">
          <Logo/>
          {loading &&
          <div className='flex ml-4 p-2 border border-lightGray rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-brand dark:border-grey-600'>
            <Loading className={'mt-0 [&>div]:w-3 [&>div]:h-3'}/>
          </div>
          }
          {!loading &&
            <>
              {!auth.user ?
                <Link className="btn btn--dark bg-black" to={signupQueryParams ? `/register/${signupQueryParams}` : '/register'}>Signup / Login</Link>
                :
                <div className="flex items-center">
                  <NavbarMenu menuItems={menuItems} image={auth.user.profile.image_thumbnail} name={getName(auth.user)} />
                </div>
              }
            </>
          }
        </div>
      </div>
    </nav>
  )
}

export default Navbar;
