import React, {useEffect, useState} from "react";
import SidebarHeading from "../SidebarHeading/SidebarHeading";
import Button from "../Button/Button";
import {activeSidebarSelector, clearActiveSidebar} from "../../store/ui";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {addTripMessage} from "../../store/tripMessagesSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import ReactQuill from "react-quill";
import {FIELD_ERRORS, FieldQuill, SidebarTypes} from "../../types";
import {isQuillEmpty} from "../../utils/utils";

export interface TripMessagesFormProps {
  messageId?: string;
  onCancel?: () => void;
  tripId: string;
}

const quillFormats = [
  'header', 'bold', 'italic', 'underline', 'list', 'bullet', 'link',
]

const quillModules = {
  toolbar: [
    [{ 'header': 3 }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['link'], ['clean']
  ],
};

const TripMessagesForm = ({messageId, onCancel, tripId}: TripMessagesFormProps) => {
  const dispatch = useAppDispatch();
  const [content, setContent] = useState<FieldQuill | null>(null);
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);
  const [isMessageEmpty, setIsMessageEmpty] = useState<boolean>(true);
  const {user} = useAppSelector((state) => state.auth);
  const [submitTimeout, setSubmitTimeout] = useState(false);

  // Get active sidebar
  const sidebar = useAppSelector(activeSidebarSelector)

  const handleAddMessage = () => {
    setFieldErrors([])

    if (!content || isMessageEmpty) {
      setFieldErrors([FIELD_ERRORS.empty])
      return;
    }

    if (user && content && !submitTimeout && content.html) {
      // Stringify this for django
      const stringifiedContent = JSON.stringify(content)
      dispatch(addTripMessage({content: stringifiedContent, posted_by: user.id, tripId}))
        .then(unwrapResult)
        .then(() => {
          toast.success('Your message has been posted!')

          if (sidebar === SidebarTypes.TripMessages) {
            dispatch(clearActiveSidebar())
          }

          setSubmitTimeout(true)
          setContent(null)
          // Avoids people spamming messages too fast
          // TODO: Add proper validation for empty submits here
          setTimeout(() => {
            setSubmitTimeout(false)
          }, 20000)
        }).catch((err) => {
          console.log(err)
          toast.error('Something has gone wrong')
      })
    }

    if (submitTimeout) {
      toast('You must wait a few seconds before posting another message')
    }
  }

  return (
    <div>

      {sidebar === SidebarTypes.TripMessages &&
      <div className="mb-8">
        <SidebarHeading text="Add a message" classes="mb-2"/>
        <p className="text-darkGrey leading-snug dark:text-grey-300">Use this form to post a global message here for everyone attending the trip to see.</p>
      </div>
      }

      <div>
        <ReactQuill
          className="rounded-md prose dark:bg-grey-700 dark:text-white"
          theme="snow"
          value={content?.html}
          /* Convert to this object for django to accept it as a quill object */
          onChange={(value, delta, sources, editor) => {
            setContent({delta: delta, html: value})
            setFieldErrors([])
            setIsMessageEmpty(isQuillEmpty(editor))
          }}
          onBlur={(range, sources, editor) => setIsMessageEmpty(isQuillEmpty(editor))}
          formats={quillFormats}
          modules={quillModules}
          placeholder={'Enter your message here'}
        />
        {fieldErrors.length > 0 && fieldErrors.map((error,index) => <p key={`message-error-${index}`} className="text-sm text-red-400 my-0">{error}</p>)}

        {/* Actions */}
        <div className="mt-8 pb-4 flex flex-wrap justify-start items-start gap-4">
          <Button style="dark" classes="xl:mb-0" onClick={() => handleAddMessage()} aria-label={'Post message'}>Post</Button>

          {sidebar !== null &&
            <button
              type="submit"
              onClick={(e) => {
                {onCancel && onCancel()}
                e.preventDefault()
                dispatch(clearActiveSidebar())
              }}
              className="btn btn--light">
              Cancel
            </button>
          }
        </div>
      </div>
    </div>
  )
}

export default TripMessagesForm;
