import React from 'react'

interface ContentHeadingProps {
 style: 'dark' | 'brand',
text: string;
 classes?: string;
}

const ContentHeading: React.FC<ContentHeadingProps> = ({text, children, style, classes}) => {
  return (
    <div className={`flex flex-col items-start ${classes}`}>
        <h2 className={`text-3xl font-extrabold md:text-4xl text-${style}`}>{text}</h2>
        {children}
    </div>
  )
}

export default ContentHeading
