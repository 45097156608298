import React from "react"
import Navbar from "../Components/Nav/Navbar";
import {useAppSelector} from "../store/store";
import Sidebar from "../Components/Sidebar";
import Content from "../Containers/Content/Content";
import Footer from "../Components/Footer/Footer";
import StickyFooter from "../Components/StickyFooter/StickyFooter";
import {StickyFooterTypes} from "../types";
import Loading from "../Components/Loading/Loading";

export interface DashboardProps {
  sidebarChildren?: React.ReactElement;
  loading?: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({sidebarChildren, loading, children}) => {

  const {user} = useAppSelector((state) => state.auth)
  const {stickyFooter} = useAppSelector((state) => state.ui.stickyFooterActive)

  const getName = () => {
    if (user) {
      if (user.first_name) {
        return user.first_name
      }
      return user.username
    }
    return ""
  }

  return (
    <>
      <Navbar name={getName()} image={user?.profile.image_thumbnail ? user?.profile.image_thumbnail: null}/>
      <div className="pt-8 md:pt-10 app-height">
        <div className="container px-4 sm:px-6 bg-white dark:bg-grey-900 relative z-40">
          <div className="flex flex-col md:flex-row">
            <Sidebar>
              {loading ? <Loading/> : sidebarChildren}
            </Sidebar>
            <Content>
              {loading ? <Loading/> : children}
            </Content>
          </div>
        </div>
        <Footer></Footer>
      </div>
      {/* Note: right now there is only a sticky footer on mobile for trip pages, however in the future we can use the slice for this in ui.ts*/}
      { stickyFooter === StickyFooterTypes.Trip &&
        <StickyFooter/>
      }
    </>
  )
}

export default Dashboard
