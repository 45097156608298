import React, {useMemo, useState} from 'react'
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import PlanGroupForm from "../PlanGroupForm/PlanGroupForm";
import {tripId} from "../../types";
import {useAppSelector} from "../../store/store";

interface CategoryModalProps {
  tripId: string;
  categoryId: number | null;
  isOpen: boolean;
  onClose: () => void;
  onAfterClose: () => void;
  onAddOrUpdateCategory: (id: number) => void; // This gets passed all the way back up
  onDelete: (id: number) => void;
}

const CategoryModal: React.FC<CategoryModalProps> = ({tripId, isOpen, onClose, onAddOrUpdateCategory, onDelete, onAfterClose, categoryId}) => {

  return (
    <Modal
      closeTimeoutMS={100}
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={onAfterClose}
    >
      <div className="mb-6 mt-6">
        <h2 className="text-lg md:text-xl font-bold ">{categoryId ? 'Update' : 'Create'} a category</h2>
        <span className="text-sm text-darkGrey dark:text-grey-300">To add ideas to this category, edit the idea and select a category</span>
      </div>

      <PlanGroupForm onAddOrUpdateCategory={(id) => onAddOrUpdateCategory(id)} categoryId={categoryId} tripId={tripId}/>

      {/* TODO: Add plans to category */}
      {/*<div>*/}
      {/*<fieldset>*/}
      {/*  <legend className="text-lg font-medium text-gray-900">Members</legend>*/}
      {/*  <div className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">*/}

      {/*    <div className="relative flex items-start py-4">*/}
      {/*      <div className="min-w-0 flex-1 text-sm">*/}
      {/*        <label for="person-1" className="font-medium text-gray-700 select-none">Annette Black</label>*/}
      {/*      </div>*/}
      {/*      <div className="ml-3 flex items-center h-5">*/}
      {/*        <input id="person-1" name="person-1" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*  </div>*/}
      {/*</fieldset>*/}
      {/*</div>*/}


      <div className="mt-6 flex flex-row justify-between items-center">
        <Button style="light" onClick={onClose}>Close</Button>
        {categoryId &&
          <button className="text-red-500 underline hover:text-red-700" onClick={() => onDelete(categoryId)}>Delete</button>
        }
      </div>
    </Modal>
  )
}

export default CategoryModal
