import React, {useEffect, useState, useMemo, useRef} from 'react'
import Dashboard from "../Layout/Dashboard";
import {useParams} from "react-router";
import Button from "../Components/Button/Button";
import {useAppDispatch, useAppSelector} from "../store/store";
import DatesRow from "../Components/DatesRow";
import TripForm from "../Components/TripForm/TripForm";
import IdeaForm from "../Components/IdeaForm/IdeaForm";
import ContentHeading from "../Components/ContentHeading/ContentHeading";
import {Plan, userId} from "../types";
import TripAttending from "../Components/TripAttending/TripAttending";
import Icon from "../Components/Icons/Icon";
import InviteAttendeesForm from "../Components/InviteAttendeesForm/InviteAttendeesForm";
import {cancelSentTripInvite, fetchSentTripInvitesByTripId} from "../store/tripInviteSlice";
import TripDetailsSidebar from "../Components/TripDetailsSidebar/TripDetailsSidebar";
import {toast} from "react-toastify";
import {removeAttendingUserFromTrip, leaveTrip, setTripPublic, setTripPrivate} from '../store/tripSlice'
import {useHistory, useRouteMatch, Switch, Route, NavLink} from "react-router-dom";
import ConfirmationDialog from "../Components/ConfirmationDialog/ConfirmationDialog";
import {SidebarTypes} from "../types";
import {activeSidebarSelector, showInviteForm, showTripForm, clearActiveSidebar, showTripStickyFooter} from "../store/ui";
import Accommodations from "../Components/Accommodations/Accommodations";
import Plans from "../Components/Plans/Plans";
import AccommodationsForm from "../Components/AccommodationsForm/AccommodationsForm";
import ShareTripForm from "../Components/ShareTripForm/ShareTripForm";
import TripMessages from "../Components/TripMessages/TripMessages";
import {unwrapResult} from "@reduxjs/toolkit";
import TripSubnavMobile from "../Components/TripSubnavMobile/TripSubnavMobile";
import TripMessagesForm from "../Components/TripMessagesForm/TripMessagesForm";
import IdeaPage from "./IdeaPage";
import PrivateRoute from "../Components/Auth/PrivateRoute";

const Trip: React.FC = (props) => {

  let {id} = useParams<Record<string, string>>();

  // Used for the subnav
  let {path, url} = useRouteMatch();

  const {trip} = useAppSelector((state) => state.trip)
  const {attending} = useAppSelector((state) => state.trip.trip)
  const {user} = useAppSelector((state) => state.auth)
  const {invites} = useAppSelector((state) => state.tripInvitesSent)
  const [showLeaveTripModal, setShowLeaveTripModal] = useState<boolean>(false)
  const [showShareTripModal, setShowShareTripModal] = useState<boolean>(false)
  // Pass the user to remove in this case
  const [removeFromTripUserModal, setRemoveFromTripUserModal] = useState<{ id: userId, name: string; }>()
  const [cancelInviteUserModal, setCancelInviteUserModal] = useState<{ id: number, name: string; }>()
  const [editPlanId, setEditPlanId] = useState<number | null>(null)
  const [editAccomId, setEditAccomId] = useState<number | null>(null)

  const sidebar = useAppSelector(activeSidebarSelector)
  const history = useHistory()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchSentTripInvitesByTripId(id))
  }, [id, dispatch])

  function handleRemoveUserFromTrip(userId: userId, name: string) {
    dispatch(removeAttendingUserFromTrip({userId, tripId: trip.id}))
      .then(unwrapResult)
      .then(() => {
        setRemoveFromTripUserModal(undefined)
        toast(`Removed ${name} from trip 💔`)
      })
      .catch(err => {
        toast.error(`Error removing user. Try again later.`)
      })
  }

  function handleCancelSentTripInvite(id: number, name: string) {
    dispatch(cancelSentTripInvite(id))
      .then(unwrapResult)
      .then(() => {
        toast(`Canceled trip invite to ${name} 💔`)
        setCancelInviteUserModal(undefined)
      })
      .catch(err => {
        toast.error(`Error canceling request. Try again later.`)
      })
  }

  function handleLeaveTrip(userId: userId) {
    dispatch(leaveTrip({userId, tripId: trip.id}))
    toast(`You are no longer attending ${trip.name}`)
    history.push('/')
  }

  function handleTripPermission() {
    if (!trip.public) {
      dispatch(setTripPublic(trip.id))
      toast(`Your trip is now public 👀`)
    } else {
      dispatch(setTripPrivate(trip.id))
      toast(`Your trip is now private 🔒`)
    }
  }

  useEffect(() => {
    dispatch(showTripStickyFooter())
  }, [])

  function renderSidebar() {
    if (sidebar === SidebarTypes.Trip) {
      return <TripForm
        onCancel={() => {
          dispatch(clearActiveSidebar())
        }}
        trip={trip}
      />
    }
    if (sidebar === SidebarTypes.Idea) {
      return (
        <IdeaForm
          tripId={trip.id}
          tripCountries={trip.countries}
          planId={editPlanId}
          onCancel={() => {
            dispatch(clearActiveSidebar())
            setEditPlanId(null)
          }}/>
      )
    }
    if (sidebar === SidebarTypes.Invite) {
      return (
        <InviteAttendeesForm onCancel={() => dispatch(clearActiveSidebar())} tripId={trip.id}/>
      )
    }
    if (sidebar === SidebarTypes.Accommodation) {
      return (
        <AccommodationsForm
          tripId={trip.id}
          tripCountries={trip.countries}
          accommodationId={editAccomId}
          onCancel={() => {
            dispatch(clearActiveSidebar())
            setEditAccomId(null)
          }}/>
      )
    }
    if (sidebar === SidebarTypes.TripMessages) {
      return (
        <TripMessagesForm tripId={trip.id}/>
      )
    }
    // @ts-ignore
    return (
      <>
        <TripDetailsSidebar
          trip={trip}
          onEditTripDetails={() => {
            dispatch(showTripForm())
          }}
          showOwnerActions={user?.id === trip.owner?.id}/>

        {user && attending &&
          <>
            <TripAttending
              trip={trip}
              user={user}
              attending={attending}
              invites={invites}
              onRemove={(id, name) => setRemoveFromTripUserModal({id, name})}
              onCancelInvite={(id, name) => setCancelInviteUserModal({id, name})}/>
          </>
        }

        {/* Actions */}
        <div className="flex flex-wrap justify-start items-center mt-6 gap-4">
          <Button style="dark" onClick={() => dispatch(showInviteForm())}>
            Invite Buddies
            <Icon className="w-4 h-4 text-white ml-2 dark:text-grey-200" iconName="add-user"/>
          </Button>

          {user?.id === trip.owner?.id &&
            <>
              <Button onClick={() => setShowShareTripModal(true)} style="light">
                Share
                <Icon className="w-4 h-4 text-black ml-2 dark:text-white" iconName="external-link-icon"/>
              </Button>
              <ConfirmationDialog
                confirmText="Update"
                text={
                  <ShareTripForm
                    tripId={trip.id}
                    publicTrip={trip.public}
                    onSetPermission={(value) => {
                      handleTripPermission()
                    }}/>
                }
                isOpen={showShareTripModal}
                onCloseText="Done"
                onClose={() => {
                  setShowShareTripModal(false)
                }}
              />
            </>
          }

          {trip.owner?.id !== user?.id &&
            <>
              <Button style="outline-dark" onClick={() => setShowLeaveTripModal(true)}>
                Leave Trip
              </Button>
              <ConfirmationDialog
                confirmText="Yes! Do it!"
                text={`Are you sure you want to leave ${trip.name}?`}
                isOpen={showLeaveTripModal}
                onClose={() => setShowLeaveTripModal(false)}
                onConfirm={() => {
                  if (user) {
                    handleLeaveTrip(user.id)
                  }
                  setShowLeaveTripModal(false)
                }}/>
            </>
          }
        </div>

        {/* Remove from trip confirmation */}
        <ConfirmationDialog
          confirmText="Yes"
          text={`Are you sure you want to remove ${removeFromTripUserModal?.name}?`}
          isOpen={!!removeFromTripUserModal}
          onClose={() => setRemoveFromTripUserModal(undefined)}
          onConfirm={() => {
            if (removeFromTripUserModal) {
              const {id, name} = removeFromTripUserModal;
              handleRemoveUserFromTrip(id, name)
            }
          }}/>

        {/* Cancel invite confirmation */}
        <ConfirmationDialog
          confirmText="Yes"
          text={`Are you sure you want to cancel the invite to ${cancelInviteUserModal?.name}?`}
          isOpen={!!cancelInviteUserModal}
          onClose={() => setCancelInviteUserModal(undefined)}
          onConfirm={() => {
            if (cancelInviteUserModal) {
              const {id, name} = cancelInviteUserModal;
              handleCancelSentTripInvite(id, name)
            }
          }}/>
      </>
    )
  }


  return (
    <Dashboard sidebarChildren={<>{renderSidebar()}</>}>
      <div>
        <ContentHeading style="brand" text={trip.name} classes="mb-4"/>

        {/* TODO: Add this when plan dates are enabled to sort ideas by date */}
        {/*<span className="text-xl md:text-2xl font-bold">Dates</span>*/}
        {/*<p className="mb-6 text-gray">Select a date to view plans for that day</p>*/}
        {/*<DatesRow tripId={trip.id} start_date={trip.start_date} end_date={trip.end_date}/>*/}
      </div>

      {/* Desktop Subnav */}
      <div>
        {/* TODO: Put this in its own component */}
        <nav className="hidden shadow-md border border-offWhite rounded-md p-6 lg:flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4 font-semibold md:text-md uppercase dark:border-0 dark:bg-grey-800">
          <div className="">
            <NavLink activeClassName="text-brand" to={`${url}`} exact>💡 Ideas</NavLink>
          </div>
          <div className="md:border-l border-lightGray md:pl-4 dark:border-grey-300">
            <NavLink activeClassName="text-brand" to={`${url}/accommodations`}>🏡 Accommodations</NavLink>
          </div>
          <div className="md:border-l border-lightGray md:pl-4 dark:border-grey-300">
            <NavLink activeClassName="text-brand" to={`${url}/messages`}>✉️ Messages</NavLink>
          </div>
        </nav>

        <Switch>
          <PrivateRoute exact path={path}>
            <Plans onEditPlan={(id) => setEditPlanId(id)} tripId={id}/>
          </PrivateRoute>
          <PrivateRoute path={`${path}/plan/:id`}>
            <IdeaPage onEditPlan={(id) => setEditPlanId(id)} />
          </PrivateRoute>
          <PrivateRoute path={`${path}/accommodations`}>
            <Accommodations onEditAccommodation={(id) => setEditAccomId(id)} tripId={id}/>
          </PrivateRoute>
          <PrivateRoute path={`${path}/messages`}>
            <TripMessages tripId={id}/>
          </PrivateRoute>
        </Switch>
      </div>

    </Dashboard>
  )
}
export default Trip;
