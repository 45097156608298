import React from 'react'

const address = {
  name: '',
  street: '',
  postal_code: '',
  city: '',
  country: '',
  state: '',
}
export let parsedAddressProps: typeof address;

const parseGoogleAddressComponent = (address_components: google.maps.GeocoderAddressComponent[] | undefined) => {

  let address1 = ""; //For building a longer address with route
  let postcode = ""; //For building a longer address with route

  if (!address_components) {
    return null
  }

  for (const component of address_components) {
    // @ts-ignore remove once typings fixed
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        address1 = `${component.long_name} ${address1}`;
        break;
      }

      case "route": {
        address1 += component.short_name;
        break;
      }

      case "postal_code": {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case "postal_code_suffix": {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }

      // State
      case "administrative_area_level_1": {
        address.state =
          component.short_name;
        break;
      }

      // City
      case "locality":
        address.city =
          component.long_name;
        break;

      case "country":
        address.country =
          component.long_name;
        break;
    }
  }
  address.postal_code = postcode
  address.street = address1

  return address
}

export default parseGoogleAddressComponent;
