import React, {useEffect, useMemo, useState} from "react";
import Button from "../Components/Button/Button";
import TripCard from "../Components/TripCard/TripCard";
import {BASE_URL, useAppDispatch, useAppSelector} from "../store/store";
import Dashboard from "../Layout/Dashboard";
import TripForm from "../Components/TripForm/TripForm";
import {fetchMyTrips} from "../store/tripSlice";
import {clearPlans} from "../store/planSlice"
import {clearAccoms} from "../store/accommodationSlice"
import SidebarNav from "../Components/SidebarNav";
import {fetchReceivedTripInvites} from "../store/tripInviteSlice";
import TripInvites from "../Components/TripInvites/TripInvites";
import {activeSidebarSelector, showTripForm, clearActiveSidebar, clearStickyFooter} from "../store/ui";
import {AppQueryStrings, FetchState, SidebarTypes, Trip} from "../types";
import Loading from "../Components/Loading/Loading";
import moment, {Moment} from "moment/moment";
import {useHistory} from "react-router-dom";
import useQuery from "../utils/useQuery";
import {fetchBuddies, fetchBuddyRequests} from "../store/buddySlice";

// My profile acts as the homepage for the app. It shows all your trips when loggging in.
const MyProfile: React.FC = () => {
  const {tripList} = useAppSelector((state) => state.trips)
  const {invites} = useAppSelector((state) => state.tripInvitesReceived)
  const {status: tripStatus} = useAppSelector((state) => state.trips)
  const publicTrip = useAppSelector((state) => state.publicTripSlice.trip.trip);

  const history = useHistory();
  const query = useQuery()
  const dispatch = useAppDispatch();
  const sidebar = useAppSelector(activeSidebarSelector)

  const sortedTripsByDate: Trip[] = useMemo(() => {
      if (!tripList.length) {
        return []
      }

      return tripList.slice().sort((a, b) => {
        if (a.start_date && b.start_date && b.end_date && a.end_date) {
          const endA = moment(new Date(a.end_date));
          const endB = moment(new Date(b.end_date));

          if (endA.isAfter(endB)) {
            // A finished but B isn't: B appears sooner
            return 1;
          }
          if (endB.isAfter(endA)) {
            // B finished but A isn't: A appears sooner
            return -1;
          }
          if (endA.isAfter(new Date()) && endB.isAfter(new Date())) {
            // Both A and B finished, sort by end date in a descending
            // order.
            return endB.milliseconds() - endA.milliseconds();
          }
          // Both are not finished - sort by their start date in a
          // descending order.
          const startA = new Date(a.start_date).getTime();
          const startB = new Date(b.start_date).getTime();
          return startB - startA;
        }
        return 0
      })
    }
    , [tripList],
  );
  const upcomingTrips = useMemo(() =>
      sortedTripsByDate.filter((trip) => (
        moment(trip.end_date).isAfter(new Date())
      ))
    , [sortedTripsByDate]);

  const pastTrips = useMemo(() =>
      sortedTripsByDate.filter((trip) => (
        moment(trip.end_date).isBefore(new Date())
      ))
    , [sortedTripsByDate]);


  // Fetch trips and reset plans and Accoms state
  useEffect(() => {
    dispatch(fetchMyTrips())
    dispatch(clearPlans())
    dispatch(clearAccoms())
    dispatch(fetchBuddyRequests())
    dispatch(fetchReceivedTripInvites())
  }, [dispatch])

  useEffect(() => {
    dispatch(clearStickyFooter())
  }, [])

  // Redirect to public trip if there is a referral query string in url
  useEffect(() => {
    if (query) {
      const publicTripId = query.get(AppQueryStrings.public_trip_referral)
      if (publicTripId) {
        history.push(`/public-trip/${publicTripId}`)
      }
    }
  }, [])

  return (
    <Dashboard
      sidebarChildren={
        <>
          {sidebar === SidebarTypes.Trip ?
            <TripForm onCancel={() => {
              dispatch(clearActiveSidebar())
            }}/> : <SidebarNav/>
          }
        </>
      }>
      <>
        {invites && invites.length > 0 &&
        <TripInvites tripInvites={invites}/>
        }

        {sortedTripsByDate.length > 0 &&
        <>
          {tripStatus === FetchState.Loading &&
          <Loading/>
          }
          {tripStatus === FetchState.Fulfilled &&
          <>
            {/* Upcoming Trips*/}
            {upcomingTrips.length > 0 &&
            <div>
              <div className="flex flex-col md:flex-row items-start justify-between mb-10 md:mb-6">
                <div>
                  <h2 className="text-xl font-extrabold md:text-2xl lg:text-4xl lg:mb-0">Upcoming & current trips</h2>
                  <p className="mb-6 text-gray">Trips you have created or been invited to attend</p>
                </div>
                <Button style="dark" onClick={() => {
                  dispatch(showTripForm())
                }}>Add Trip</Button>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-10">
                {upcomingTrips.map((trip, index) => {
                  return <TripCard
                    key={`trip-${trip.id}-upcoming`}
                    id={`${trip.id}`}
                    title={trip.name}
                    startDate={trip.start_date}
                    endDate={trip.end_date}
                    countries={trip.countries}
                    image_thumbnail={trip.image_thumbnail}
                    stock_image_url={trip.stock_image_url}
                    owner={trip.owner}
                    attending={trip.attending}/>
                })}
              </div>
            </div>
            }

            {/* Past trips */}

            {pastTrips.length > 0 &&
            <div className="mt-10 lg:mt-20 first:mt-0">
              <div className="flex flex-col md:flex-row items-start justify-between mb-4">
                <div>
                  <h2 className="text-xl font-extrabold md:text-2xl lg:text-4xl lg:mb-0">Your past trips</h2>
                  <p className="mb-6 text-gray">Trips that have been completed</p>
                </div>
                {upcomingTrips.length < 1 &&
                <Button style="dark" onClick={() => {
                  dispatch(showTripForm())
                }}>Add Trip</Button>
                }
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-10">
                {pastTrips.map((trip, index) => {
                  return <TripCard
                    key={`trip-${trip.id}-past`}
                    id={`${trip.id}`}
                    title={trip.name}
                    startDate={trip.start_date}
                    endDate={trip.end_date}
                    countries={trip.countries}
                    image_thumbnail={trip.image_thumbnail}
                    stock_image_url={trip.stock_image_url}
                    owner={trip.owner}
                    attending={trip.attending}/>
                })}
              </div>
            </div>
            }
          </>
          }

          {tripStatus === FetchState.Failed &&
          <h2>There was an error getting your trips. We are the sorriest 😫</h2>
          }
        </>
        }

        {sortedTripsByDate.length < 1 &&
        <>
          {tripStatus !== FetchState.Loading &&
          <div className="flex flex-col items-start mb-6 md:mb-10">
            <div>
              <h2 className="text-xl font-bold md:text-2xl lg:text-4xl mb-4">You have no upcoming trips 😫</h2>
              <p className="mb-6 text-gray">Click below to create your first trip and start planning ideas! Also if a travel buddy invites you to a trip those trips will appear below.</p>
            </div>
            <Button style="dark" onClick={() => {
              dispatch(showTripForm())
            }}>Create a trip</Button>
          </div>
          }
          {tripStatus === FetchState.Loading &&
          <Loading/>
          }
        </>
        }
      </>

    </Dashboard>
  )
}

export default MyProfile
