import React, {useEffect} from "react";
import Navbar from "../Components/Nav/Navbar";
import Footer from "../Components/Footer/Footer";
import {fetchWebsiteContent} from "../store/ui";
import {unwrapResult} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../store/store";
import DOMPurify from "dompurify";
import Loading from "../Components/Loading/Loading";
import {FetchState} from "../types";


const PrivacyPolicyPage = () => {
  const dispatch = useAppDispatch()
  const {privacyPolicy, status} = useAppSelector(state => state.ui.websiteContent)

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(privacyPolicy)
  })

  useEffect(() => {
    dispatch(fetchWebsiteContent())
      .then(unwrapResult)
      .catch((err: any) => {
        console.log(err)
      })
  }, [])

  return (
    <div>
      <Navbar/>
      <section className="mt-12 md:mt-14 lg:mt-20 h-full">
        <div className="max-w-4xl container px-6 md:px-8">
          <h1 className="text-brand mb-6 md:mb-8">Privacy Policy 📜</h1>
          {status === FetchState.Loading &&
            <Loading/>
          }
          {privacyPolicy && status === FetchState.Fulfilled &&
            <div className="prose" dangerouslySetInnerHTML={sanitizedData()}/>
          }
          {status === FetchState.Failed &&
            <p>Error fetching privacy policy. Please come back later. Sorry 😢</p>
          }
        </div>
      </section>
      {status !== FetchState.Loading &&
        <Footer/>
      }
    </div>
  )
}

export default PrivacyPolicyPage;
