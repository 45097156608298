import React from 'react'

const Content: React.FC = ({children}) => {
  return (
    <div className="w-full lg:w-2/3 lg:pl-20 lg:pt-6 lg:border-l lg:border-lightGray dark:lg:border-grey-600">
      {children}
    </div>
  )
}

export default Content
