import React from 'react'
import Icon from "../Icons/Icon";

export interface LikeButtonProps {
  onLikeAction: () => void;
  active: boolean;
}

const LikeButton = ({onLikeAction, active}: LikeButtonProps) => {

  return (
    <div className="bottom-[-20px] right-[15px] absolute flex flex-row">
      <button onClick={onLikeAction} className="bg-brandLight text-xs md:text-sm text-white p-2 px-3 leading-none border border-2 md:border-3 border-white rounded-full flex items-center justify-center ml-1 transform transition hover:-translate-y-0.5 hover:bg-brand active:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-brand dark:border-grey-900">
        {active ?
          <Icon className="w-5 h-5" iconName="heart"/>
          :
          <Icon className="w-5 h-5" iconName="heart-outline"/>
        }
      </button>
    </div>
  )
}

export default LikeButton;
