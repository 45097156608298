import {Field, useField, useFormikContext} from "formik";
import React, {useEffect} from "react";
import FormField from "../Forms/FormField";

export const AutoCompleteFillableField = ({autoCompletedValue, label, ...props}: any) => {
  const [field, meta, helpers] = useField(props);
  const {
    touched,
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    // TODO: IMPORTANT Check on this sketchy function that could break everything
    //@ts-ignore
    if (autoCompletedValue && autoCompletedValue !== '') {
      //@ts-ignore
      setFieldValue(props.name, autoCompletedValue);
    }
  }, [setFieldValue, props.name, autoCompletedValue]);

  return (
    <FormField label={label} classes="mb-4 w-full"
               field={<Field {...field} {...props} className={`form-input ${meta.error && meta.touched && 'border-red-500'}`}/>}
               errors={meta.error && meta.touched ? meta.error : null}
    />
  );
};

export default AutoCompleteFillableField;
