import React from "react"

export interface AuthHeaderProps {
  classes?: string;
}

const AuthHeader: React.FC<AuthHeaderProps> = ({classes}) => {
  return (
    <div className={classes}>
      <h1 className="text-6xl md:text-7xl font-black text-white leading-none text-center lg:text-left">Tripfomo</h1>
      <p className="text-lg sm:text-2xl lg:text-4xl leading-none text-lightGray font-light text-center lg:text-left">Plan your next group adventure</p>
    </div>
  )
}

export default AuthHeader;
