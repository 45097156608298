import { Action } from 'redux';
import {configureStore, combineReducers, createAction, unwrapResult} from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import authReducer from './authSlice'
import tripChanges, {trip, tripList} from './tripSlice'
import planSlice from "./planSlice";
import publicTripSlice from "./publicTripSlice";
import {buddiesList, buddyInvites} from "./buddySlice";
import {tripInvitesReceived, tripInvitesSent} from "./tripInviteSlice";
import sidebarActive from "./ui";
import accomsSlice from "./accommodationSlice";
import {planGroupsSlice} from "./planGroupSlice";
import {tripMessagesSlice} from "./tripMessagesSlice";

export type RootState = ReturnType<typeof rootReducer>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

//Token and Headers
export const getTokenFromLocalStorage = () => {
  try {
    return JSON.parse(localStorage.getItem('tripfomo_token') as string)
  } catch(e) {
    console.warn(e);
  }
}

export const getIdFromLocalStorage = () => {
  try {
    return JSON.parse(localStorage.getItem('tripfomo_userid') as string)
  } catch(e) {
    console.warn(e);
  }
}

export const getTokenFromLocalStorageAsync = async () => {
  try {
    return await JSON.parse(localStorage.getItem('tripfomo_token') as string)
  } catch(e) {
    console.warn(e);
  }
}


// Setup Root reducer
const appReducer =  combineReducers({
  auth: authReducer,
  accommodations: accomsSlice,
  trips: tripList.reducer,
  trip: trip.reducer,
  tripChanges,
  tripInvitesReceived: tripInvitesReceived,
  tripInvitesSent: tripInvitesSent,
  tripMessages: tripMessagesSlice.reducer,
  plans: planSlice,
  publicTripSlice,
  buddies: buddiesList,
  buddyInvites: buddyInvites,
  ui: sidebarActive,
  planGroups: planGroupsSlice.reducer,
});

export const logout = createAction<undefined>('logout')
const rootReducer = (state: any, action: any) => {
  if (action.type === 'logout') {
    localStorage.removeItem('tripfomo_user')
    localStorage.removeItem('tripfomo_token')
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

// Configure Store
const store = configureStore({
  reducer: rootReducer,
  devTools: true,
})

// Types for basic app functions
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store

// Base Request url
export const BASE_URL = process.env.REACT_APP_BASE_URL

// Thunk config
export type thunkConfigTypes = { state: RootState; rejectValue: string[] }



