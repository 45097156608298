import React from 'react'

interface SidebarHeadingProps {
  text: string;
  classes?: string;
}

const SidebarHeading: React.FC<SidebarHeadingProps> = ({text, classes}) => {
  return (
    <h3 className={`text-lg md:text-2xl lg:text-3xl font-bold ${classes}`}>{text}</h3>
  )
}

export default SidebarHeading;
