export const darkModeStyles: google.maps.MapTypeStyle[] = [
  {elementType: "geometry", stylers: [{color: "#242424"}]},
  {elementType: "labels.text.stroke", stylers: [{color: "#0A0A0A"}]},
  {elementType: "labels.text.fill", stylers: [{color: "#BB7A56"}]},
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{color: "#836bd4"}],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{color: "#836bd4"}],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{color: "#232b2d"}],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{color: "#6b9a76"}],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{color: "#38414e"}],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{color: "#212a37"}],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{color: "#9ca5b3"}],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{color: "#5CC3C3"}],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{color: "#1f2835"}],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{color: "#39a1a1"}],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{color: "#2f3948"}],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{color: "#BB7A56"}],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{color: "#0a0a0a"}],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{color: "#353535"}],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{color: "#171717"}],
  },
];

