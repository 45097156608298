import React from 'react'

const Loading = ({className}: any) => {
  return (
    <div className={`loading-spinner ${className}`}>
      <div className="loading-spinner-bounce1"/>
      <div className="loading-spinner-bounce2"/>
      <div className="loading-spinner-bounce3"/>
    </div>
  )
}

export default Loading;
