import {BASE_URL, getIdFromLocalStorage, getTokenFromLocalStorage, RootState} from './store'
import {
  createAsyncThunk,
  createSlice,
  combineReducers
} from '@reduxjs/toolkit';
import {FetchState, tripId, userId, Trip} from "../types";
import {acceptTripInvite, tripInvite} from "./tripInviteSlice";

type TripsState = {
  tripList: Trip[];
  status: FetchState;
  errorDetail: {}
};

export const fetchMyTrips = createAsyncThunk<Trip[]>(
  'trips/fetchMyTrips',
  async () => {
    const response = await fetch(
      BASE_URL + '/api/trips/',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    );

    return await response.json();
  }
)

interface AttendingUserRemoveResponse {
  message: string;
  user_id: userId;
}

export const removeAttendingUserFromTrip = createAsyncThunk<AttendingUserRemoveResponse, { tripId: tripId, userId: userId }>(
  'trip/removeAttendingUser',
  async ({tripId, userId}) => {
    const response = await fetch(
      `${BASE_URL}/api/trips/${tripId}/remove_attendee/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify({id: userId})
      }
    );
    return await response.json();
  }
)

export const leaveTrip = createAsyncThunk<tripId, { tripId: tripId, userId: userId }>(
  'trips/leaveTrip',
  async ({tripId, userId}) => {
    const response = await fetch(
      `${BASE_URL}/api/trips/${tripId}/remove_attendee/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify({id: userId})
      }
    );
    return tripId
  }
)

// User can only join if trip is public
export const joinTrip = createAsyncThunk<Trip, { tripId: tripId, userId: userId }>(
  'trips/joinTrip',
  async ({tripId, userId}) => {
    const response = await fetch(
      `${BASE_URL}/api/trips/${tripId}/join_trip/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify({id: userId})
      }
    );
    return await response.json();
  }
)


export interface TripProps {
  id?: string;
  name: string;
  countries: string;
  start_date: string;
  end_date: string;
  attending?: userId[] | [];
  image?: File | null;
  image_thumbnail?: File | null;
}

export const createTrip = createAsyncThunk<Trip, TripProps>(
  'trips/addTrip',
  async ({name, start_date, end_date, countries, image}, {rejectWithValue}) => {
    const data = new FormData();

    //@ts-ignore
    data.append('owner', getIdFromLocalStorage());
    data.append('name', name);
    data.append('countries', countries);
    data.append('start_date', start_date);
    data.append('end_date', end_date);

    if (image) {
      data.append('image', image, image.name)
    }

    const response = await fetch(
      BASE_URL + '/api/trips/',
      {
        method: 'POST',
        headers: {
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: data
      }
    );
    if (!response.ok) {
      if (rejectWithValue) {
        try {
          return rejectWithValue(await response.json());
        } catch (e) {
          console.error(e);
        }
      }
      throw Error(`Unexpected status code - ${response.statusText}`);
    }
    if (response.status !== 204) {
      return (await response.json() as any) as TripsState;
    }
    return await response.json();
  }
)

export const updateTrip = createAsyncThunk<Trip, TripProps, { state: RootState; rejectValue: string[] }>(
  'trips/updateTrip',
  async ({id, name, start_date, end_date, countries, image}, {rejectWithValue}) => {

    const data = new FormData();

    //@ts-ignore
    data.append('owner', getIdFromLocalStorage());
    data.append('name', name);
    data.append('countries', countries);
    data.append('start_date', start_date);
    data.append('end_date', end_date);

    if (image) {
      data.append('image', image, image.name)
    }

    const response = await fetch(
      BASE_URL + '/api/trips/' + id + '/',
      {
        method: 'PATCH',
        headers: {
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: data
      }
    );
    if (!response.ok) {
      if (rejectWithValue) {
        try {
          return rejectWithValue(await response.json());
        } catch (e) {
          console.error(e);
        }
      }
      throw Error(`Unexpected status code - ${response.statusText}`);
    }
    if (response.status !== 204) {
      return (await response.json() as any) as TripsState;
    }
    return await response.json();
  }
)

export const deleteTrip = createAsyncThunk<Trip, tripId>(
  'trips/deleteTrip',
  async (id) => {
    const response = await fetch(
      BASE_URL + '/api/trips/' + id + '/',
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    );
    return await response.json();
  }
)

export const setTripPublic = createAsyncThunk<Trip, tripId>(
  'trips/setTripPublic',
  async (id) => {
    const response = await fetch(
      `${BASE_URL}/api/trips/${id}/make_public/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    );
    return await response.json();
  }
)

export const setTripPrivate = createAsyncThunk<Trip, tripId>(
  'trips/setTripPrivate',
  async (id) => {
    const response = await fetch(
      `${BASE_URL}/api/trips/${id}/make_private/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    );
    return await response.json();
  }
)

const initialUpdateState: TripUpdateState = {
  status: FetchState.Idle,
  errorDetail: {}
}

export interface TripUpdateState {
  status: FetchState;
  errorDetail: any;
}

const updating = createSlice({
    name: 'updatingTrip',
    initialState: initialUpdateState,
    reducers: {},
    extraReducers: builder => {
      builder.addCase(updateTrip.pending, (state) => {
        state.status = FetchState.Loading;
        state.errorDetail = {}
      });
      builder.addCase(updateTrip.fulfilled, (state, {meta, payload}) => {
        state.status = FetchState.Fulfilled;
      });
      builder.addCase(updateTrip.rejected, ((state, {meta, payload}) => {
        state.status = FetchState.Failed
        state.errorDetail = payload;
      }));
      builder.addCase(setTripPublic.rejected, (state, payload) => {
        state.status = FetchState.Failed
        state.errorDetail = payload;
      })
      builder.addCase(setTripPrivate.rejected, (state, payload) => {
        state.status = FetchState.Failed
        state.errorDetail = payload;
      })
    }
  }
)

export interface TripCreatingState {
  status: FetchState;
  errorDetail: {};
}

const creating = createSlice({
    name: 'creatingTrip',
    initialState: {} as TripCreatingState,
    reducers: {},
    extraReducers: builder => {
      builder.addCase(createTrip.pending, (state) => {
        state.status = FetchState.Loading;
        state.errorDetail = {}
      });
      builder.addCase(createTrip.fulfilled, (state, {meta}) => {
        state.status = FetchState.Fulfilled;

      });
      builder.addCase(createTrip.rejected, ((state, {meta, payload}) => {
        state.status = FetchState.Failed
        //@ts-ignore
        state.errorDetail = payload;
      }));
    }
  }
)


export interface TripDeleteState {
  status: FetchState;
  errorDetail: string[];
}

const deletingTrip = createSlice({
    name: 'deletingTrip',
    initialState: {} as TripDeleteState,
    reducers: {},
    extraReducers: builder => {
      builder.addCase(deleteTrip.pending, (state) => {
        state.status = FetchState.Loading;
      });
      builder.addCase(deleteTrip.fulfilled, (state, {meta}) => {
        const {arg} = meta;
        state.status = FetchState.Fulfilled;
        // @ts-ignore
        delete state[arg.id];
      });
      builder.addCase(deleteTrip.rejected, ((state, {meta, payload}) => {
        state.status = FetchState.Failed
        const {arg} = meta;
        // @ts-ignore
        state[arg.id] = {
          errorDetail: payload || {},
        };
      }));
    }
  }
)

export const fetchTrip = createAsyncThunk<Trip, tripId>(
  'trips/fetchTrip',
  async (id) => {
    const response = await fetch(
      BASE_URL + '/api/trips/' + id + '/',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    );
    return await response.json();
  }
)

const initialTripState = {
  trip: {} as Trip,
  status: FetchState.Idle,
  errorDetail: {}
}

export const trip = createSlice({
  name: 'trip',
  initialState: initialTripState,
  reducers: {
    clearTrip(state) {
      //@ts-ignore
      state.trip = {}
      state.status = FetchState.Idle
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchTrip.pending, (state) => {
      state.status = FetchState.Loading;
    });
    builder.addCase(fetchTrip.fulfilled, (state, {payload}) => {
      state.status = FetchState.Fulfilled;
      state.trip = payload
    });
    builder.addCase(fetchTrip.rejected, ((state, {payload}) => {
      state.status = FetchState.Failed
    }));
    builder.addCase(removeAttendingUserFromTrip.fulfilled, (state, {payload}) => {
      state.trip.attending = state.trip.attending.filter(({id}) => id !== payload.user_id)
    });
    builder.addCase(updateTrip.fulfilled, (state, {meta, payload}) => {
      state.trip = payload
    });
    builder.addCase(setTripPublic.fulfilled, (state => {
      state.trip.public = true;
    }))
    builder.addCase(setTripPrivate.fulfilled, (state => {
      state.trip.public = false;
    }))
  }
})


const initialState: TripsState = {
  tripList: [],
  status: FetchState.Idle,
  errorDetail: {}
}

export const tripList = createSlice({
  name: 'tripList',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMyTrips.pending, (state) => {
      state.status = FetchState.Loading;
    });
    builder.addCase(fetchMyTrips.fulfilled, (state, {payload}) => {
      state.status = FetchState.Fulfilled;
      state.tripList = payload
    });
    builder.addCase(fetchMyTrips.rejected, ((state, {payload}) => {
      state.status = FetchState.Failed
    }));
    builder.addCase(createTrip.fulfilled, (state, {payload}) => {
      state.tripList = [...state.tripList, payload]
    });
    builder.addCase(updateTrip.fulfilled, (state, {payload}) => {
      state.tripList = [...state.tripList, payload]
    });
    builder.addCase(deleteTrip.fulfilled, (state, {payload}) => {
      state.tripList = state.tripList.filter(({id}) => id !== payload.id)
    });
    builder.addCase(acceptTripInvite.fulfilled, (state, {payload}) => {
      state.tripList = [...state.tripList, payload.trip]
    });
    builder.addCase(leaveTrip.fulfilled, (state, {payload}) => {
      state.tripList = state.tripList.filter(({id}) => id !== payload)
    });
    builder.addCase(joinTrip.fulfilled, (state, {payload}) => {
      state.tripList = [...state.tripList, payload]
    });
  }
})

export const {clearTrip} = trip.actions;

export default combineReducers({
  updating: updating.reducer,
  creating: creating.reducer,
  deletingTrip: deletingTrip.reducer,
})
