import React from 'react'
import Icon from "../Icons/Icon";
import {Link} from "react-router-dom";

interface TripAttendeeProps {
  id: string | number;
  classes?: string;
  image?: string;
  name: string;
  traveler_type?: string;
  instagram?: string | null;
  pinterest?: string | null;
  showRemove?: boolean;
  showStar?: boolean;
  onRemove?: () => void;
  showCancelInvite?: boolean;
  onCancelInvite?: () => void;
}

const TripAttendee: React.FC<TripAttendeeProps> = ({id, classes, image, name, traveler_type, instagram, pinterest, showRemove, showStar, onRemove, showCancelInvite, onCancelInvite}) => {
  return (
    <div className={`border border-lightGray rounded-lg p-3 w-full flex flex-row items-center dark:border-0 dark:bg-grey-800 ${classes}`}>
      <div className="flex flex-row items-center w-full">
        <Link to={`/profile-${id}`}>
          {image ?
            <img className="w-8 h-8 rounded-full" src={image} alt={`${name}'s picture`}/> :
            <Icon iconName="person" className="w-8 h-8 text-black border border-white rounded-full"/>
          }
        </Link>
        <div className={`pl-3 flex flex-col ${showCancelInvite && 'opacity-50'}`}>
          <Link to={`/profile-${id}`} className="text-sm lg:text-base leading-none font-semibold">{name}</Link>
          <div className="space-x-1 flex flex-row items-center">
            <Link to={`/profile-${id}`} className="text-sm text-brand">{traveler_type}</Link>
            {instagram || pinterest ? <span className="mx-2 text-lightGray">|</span> : null}
            {instagram &&
            <a className="group transition" href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noreferrer noopener">
              <Icon className="text-gray w-3 h-3 group-hover:text-brand transition" iconName="instagram"/>
            </a>
            }
            {pinterest &&
            <a className="group transition" href={`https://www.pinterest.com/${pinterest}`} target="_blank" rel="noreferrer noopener">
              <Icon className="text-gray w-3 h-3 group-hover:text-brand transition" iconName="pinterest"/>
            </a>
            }
          </div>
        </div>
        {showRemove && <button className="text-xs lg:text-sm text-brand ml-auto dark:text-red-400 dark:hover:underline" onClick={onRemove}>Remove</button>}
        {showCancelInvite && <button className="text-xs lg:text-sm text-brand ml-auto dark:text-red-400 dark:hover:underline" onClick={onCancelInvite}>Cancel Invite</button>}
        {showStar && <div className="ml-auto text-xs text-gray">Owner ⭐</div>}
      </div>
    </div>
  )
}

export default TripAttendee
