import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Button from "../Button/Button";
import {setTripView, showIdeaForm, showTripStickyFooter} from "../../store/ui";
import PlanCard from "../PlanCard/PlanCard";
import Icon from "../Icons/Icon";
import {FetchState, IntervalTime, Plan, planId, TripViewType} from "../../types";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {fetchTrip} from "../../store/tripSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import {fetchPlansForTrip} from "../../store/planSlice";
import {useHistory} from "react-router-dom";
import HeadingBlock from "../HeadingBlock/HeadingBlock";
import Loading from "../Loading/Loading";
import {deletePlanGroup, fetchPlanGroups} from "../../store/planGroupSlice";
import PlanGroups from "../PlanGroups/PlanGroups";
import CategoryModal from "../CategoryModal/CategoryModal";
import {toast} from "react-toastify";
import useInterval from "react-useinterval";
import Marker from "../Map/Makrer";
import Map from "../Map/Map";
import {darkModeStyles} from "../Map/darkModeStyles";
import EmptyListingMessage from "../EmptyListingMessage/EmptyListingMessage";

export interface PlansProps {
  onEditPlan: (id: number) => void,
  tripId: string
}

const Plans: React.FC<PlansProps> = ({onEditPlan, tripId}) => {
  const [plansArray, setPlansArray] = useState<Plan[]>([])
  const [uncategorizedPlans, setUncategorizedPlans] = useState<Plan[] | null>(null)
  const [completedPlans, setCompletedPlans] = useState<Plan[]>()
  const [expandedCompletedPlans, setExpandedCompletedPlans] = useState<boolean>(false)
  const {plans} = useAppSelector((state) => state.plans.plansObject)
  const {planGroups} = useAppSelector((state) => state.planGroups)
  const {status: plansStatus} = useAppSelector((state) => state.plans.plansObject)
  const {attending} = useAppSelector((state) => state.trip.trip)
  const completedIdeasRef = useRef<HTMLButtonElement>(null)
  const [plansPolling, setPlansPolling] = useState<boolean>(false);
  const [categoriesPolling, setCategoriesPolling] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);
  const [firstLoadCategories, setFirstLoadCategories] = useState<boolean>(false);
  const [mapMarkersForPlans, setMapMarkersForPlans] = useState<Record<number, google.maps.Marker>>()
  const mapWrapperRef = useRef<HTMLDivElement | null>(null)
  const darkMode = useAppSelector((state) => state.auth.user?.profile.dark_mode_enabled)

  // Map Settings
  const [zoom, setZoom] = useState(9); // initial zoom
  const [center, setCenter] = useState<google.maps.LatLngLiteral | null>(null);
  const [bounds, setBounds] = useState(new google.maps.LatLngBounds());

  const dispatch = useAppDispatch()
  const history = useHistory()

  // So the loading indicator will only show on first load of this component.
  // This allows us to poll every 30 seconds without showing loading icon
  useEffect(() => {
    setFirstLoad(true);
    // Set trip view type so things like mobile subnav can utilize
    dispatch(setTripView(TripViewType.Idea))
  }, [])

  // Update or Adding categories
  const [showCategoryModal, setShowCategoryModel] = useState<boolean>(false)
  // This sets the ID of of the category in the modal so once you add a category it will become update mode.
  const [categoryId, setCategoryId] = useState<number | null>(null)

  // Load plans and load them in intervals after that
  const loadPlans = useCallback(() => {
    setPlansPolling(true);
    dispatch(fetchPlansForTrip(tripId))
      .then(unwrapResult)
      .then(() => {
        setPlansPolling(false);
        setFirstLoad(false);
      });
  }, [dispatch, tripId]);

  useInterval(() => loadPlans(), IntervalTime.HALF_MINUTE);

  useEffect(() => {
    loadPlans();
  }, [loadPlans]);

  // Convert plans object into an array to work with
  useEffect(() => {
    setPlansArray(Object.entries(plans).map(([key, value]) => ({key, ...value})))
  }, [plans])

  // Fetch the groups (categories) for plans
  const loadCategories = useCallback(() => {
    setCategoriesPolling(true);
    dispatch(fetchPlanGroups(tripId))
      .then(unwrapResult)
      .then(() => {
        setCategoriesPolling(false);
        setFirstLoadCategories(false);
      });
  }, [dispatch, tripId]);

  useInterval(() => loadCategories(), IntervalTime.HALF_MINUTE);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  // Group plans
  const groupedPlans = useMemo(() => {
    return planGroups.map((group) => {
      let plansList = Object.values(plans);
      let filteredPlans = plansList.filter((plan) => plan.group === group.id && !plan.completed)
      return {...group, plans: filteredPlans}
    })
  }, [plans, planGroups])

  // Filter out plans that aren't completed
  useEffect(() => {
    if (!plansArray) {
      return;
    }

    let upcoming: Plan[] = [];
    let completed: Plan[] = [];

    plansArray.forEach((plan) => {
      if (!plan.completed && !plan.group) {
        upcoming.push(plan)
      }
      if (plan.completed) {
        completed.push(plan);
      }
    })

    setUncategorizedPlans(upcoming)
    setCompletedPlans(completed)

  }, [plansArray, groupedPlans])

  // Show sticky footer for page (on mobile)
  useEffect(() => {
    dispatch(showTripStickyFooter())
  }, [])

  // Get trip when visiting page
  useEffect(() => {
    //@ts-ignore
    dispatch(fetchTrip(tripId))
      .then(unwrapResult)
      .catch(rejectedValueOrSerializedError => {
        console.log(rejectedValueOrSerializedError)
        history.push('/')
      })
    //TODO IMPORTANT: Make this rely on updating state to force rerender instead of fetching again
  }, [tripId, dispatch])

  const handleDeleteCategory = (id: number) => {
    try {
      dispatch(deletePlanGroup(id))
      toast.success(`💥 Deleted category.`)
    } catch (err) {
      toast.error(`😟 Something went wrong - ${err.message}`)
    }

    // Close and reset modal
    setShowCategoryModel(false)
    setCategoryId(null)
  }

  useEffect(() => {
    plansArray.forEach((plan) => {
      //@ts-ignore
      if (plan.lat && plan.lng) {
        //@ts-ignore
        setBounds(bounds.extend({lat: parseFloat(plan.lat), lng: parseFloat(plan.lng)}))
      }
    })
  }, [plansArray])

  const plansHaveCoordinates = useMemo(() => {
    return plansArray.some((plan) => {
      if (!plan.completed && plan.lat && plan.lng) {
        return plan;
      }
    })
  }, [plansArray])

  // Custom info window for map
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(null)
  useEffect(() => {
    setInfoWindow(new google.maps.InfoWindow())
  }, [])

  // NOTE: This is not rendered in react so the markup uses "class" not "className"
  const openInfoWindow = (marker: google.maps.Marker, plan: Plan) => {
    const {address, state, postal_code, city, emoji, url} = plan;
    let placeWebsite = '';
    if (url) {
      placeWebsite = `<a class="underline text-brand font-medium" href=${url} target="_blank" rel="noreferrer noopener">Website</a>`
    }
    const infoWindowContent = `
      <div class="min-w-[200px] py-2">
        <span class="text-base">${emoji != null ? emoji : ''}</span>
        <h4 class="heading-6 font-medium font-sans text-base mt-1">${plan.name}</h4>
        <p>${address}<br>${city}, ${state} ${postal_code}</p>
        ${placeWebsite}
      </div>
    `
    if (infoWindow) {
      infoWindow.close()
      infoWindow.setContent(infoWindowContent)
      infoWindow.open({
        anchor: marker,
      })
    }
  }

  const scrollToMap = () => {
    if (mapWrapperRef) {
      mapWrapperRef?.current?.scrollIntoView()
    }
  }

  // Trigger click event on Marker
  const handlePlanLocationClick = (planId: planId) => {
    if (mapMarkersForPlans) {
      scrollToMap()
      google.maps.event.trigger(mapMarkersForPlans[planId], 'click')
    }
  }

  return (
    <>
      <div className="mt-8 md:mt-12">
        <HeadingBlock title="Activity Ideas" subtitle="Below is a list of ideas of activities to do on the trip.">
          <div className="flex flex-row gap-2">
            <Button style="dark" onClick={() => dispatch(showIdeaForm())}>Add Idea</Button>
            <Button style="dark" onClick={() => setShowCategoryModel(true)}>Add Category</Button>
          </div>
        </HeadingBlock>

        {plansHaveCoordinates &&
        <div ref={mapWrapperRef}>
          <Map
            center={center}
            zoom={zoom}
            fullscreenControl={false}
            mapTypeControl={false}
            streetViewControl={false}
            controlSize={38}
            className="grow min-h-[250px] lg:min-h-[300px] w-full rounded-lg mb-8 md:mb-10 lg:mb-12"
            onSetBounds={(map) => {
              map.fitBounds(bounds)
            }}
            styles={darkMode ? darkModeStyles : null}
          >
            {plansArray.map((plan, index) => {
              if (plan.lat && plan.lng) {
                return <Marker
                  key={'plan' + plan.id + 'marker-' + index}
                  position={{lat: plan.lat, lng: plan.lng}}
                  icon={{
                    path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
                    fillColor: plan.completed ? "#5CC379" : "#836bd4",
                    fillOpacity: 0.9,
                    strokeWeight: 1,
                    strokeColor: 'white',
                    rotation: 0,
                    scale: 1,
                    labelOrigin: new google.maps.Point(0, -28)
                  }}
                   // If plan emoji show one
                  label={(plan.emoji && {text: plan.emoji, fontSize: "20px"})}
                  onClick={(marker) => {
                    openInfoWindow(marker, plan)
                  }}
                  // Store markers so we can attached click events to them
                  markerRef={(marker) => {
                    setMapMarkersForPlans((prevState: any) => ({
                      ...prevState,
                      [plan.id]: marker,
                    }))
                  }}
                />
              }
              return;
            })}
          </Map>
        </div>
        }

        {/* Show the loading indicator on first load and not on polling requests */}
        {plansStatus === FetchState.Loading && firstLoad && firstLoadCategories &&
        <Loading/>
        }

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-10">
          {uncategorizedPlans && uncategorizedPlans.length > 0 && uncategorizedPlans.map((plan) => (
              <PlanCard
                key={plan.id}
                id={plan.id}
                name={plan.name}
                notes={plan.notes}
                creator={plan.creator_details}
                attending={plan.attending}
                attending_details={plan.attending_details}
                url={plan.url}
                emoji={plan.emoji}
                address={plan.address}
                state={plan.state}
                city={plan.city}
                postal_code={plan.postal_code}
                onEditIdea={(id) => {
                  dispatch(showIdeaForm())
                  onEditPlan(id)
                }}
                showAttending={(attending?.length > 0)}
                completed={plan.completed}
                lat={plan.lat}
                lng={plan.lng}
                onLocationClick={(id) => handlePlanLocationClick(id)}
              />
            )
          )}
        </div>

        {uncategorizedPlans && uncategorizedPlans.length < 1 && completedPlans && completedPlans.length > 0 && groupedPlans.length < 1 &&
        <EmptyListingMessage onClick={() => dispatch(showIdeaForm())} message={`😭 You've completed all your ideas! `} buttonLabel="Add an idea."/>
        }

        {/* No Plans Created */}
        {uncategorizedPlans && uncategorizedPlans.length < 1 && groupedPlans.length < 1 && completedPlans && completedPlans.length < 1 &&
        <EmptyListingMessage onClick={() => dispatch(showIdeaForm())} message={` 😭 This trip doesn't have any ideas planned.`} buttonLabel="Add an idea."/>
        }

        <CategoryModal
          onAddOrUpdateCategory={(id) => setCategoryId(id)}
          isOpen={showCategoryModal}
          onAfterClose={() => {
            // Reset "editing" category id
            setCategoryId(null)
          }}
          onClose={() => {
            setShowCategoryModel(false)
          }}
          onDelete={(id) => {
            handleDeleteCategory(id)
          }}
          tripId={tripId}
          categoryId={categoryId}
        />

        {/* Categorized plans */}
        <PlanGroups
          showCategoryModal={() => setShowCategoryModel(true)}
          onEditCategory={(id) => setCategoryId(id)}
          plans={groupedPlans}
          showAttendeesOnCards={(attending?.length > 0)}
          onEditPlan={
            (id) => {
              dispatch(showIdeaForm())
              onEditPlan(id)
            }}
          onLocationClick={(planId) => handlePlanLocationClick(planId)}
          showHeading={(uncategorizedPlans && uncategorizedPlans?.length > 0 && groupedPlans.length > 0)}
        />

        {/* Completed Plans Button */}
        {plansArray && plansArray.length > 0 &&
        <div className="bg-offWhite flex flex-col rounded-md rounded-b-0 w-full overflow-hidden hover:-translate-y-0.5 transition hover:shadow-md mt-6 md:mt-10 lg:mt-12 dark:bg-grey-800">
          <div className="flex justify-between w-full items-center">
            <button tabIndex={-1} className="p-3 sm:p-4 w-full text-left flex flex-col"
                    aria-expanded={expandedCompletedPlans}
                    onClick={() => setExpandedCompletedPlans(!expandedCompletedPlans)}
            >
            <span className="font-bold text-md sm:text-lg md:text-xl inline-flex items-center gap-1">
            <Icon className="w-5 h-5" iconName="checked-clipboard"/>
              {completedPlans?.length} Completed Idea{completedPlans && completedPlans?.length === 1 ? '' : 's'}
            </span>
              <p className="mb-0 text-gray max-w-sm">This contains a list of ideas you have completed.</p>
            </button>

            <div className="self-stretch flex flex-row justify-center items-center">
              <button className="bg-lightGray h-full w-[35px] sm:w-[60px] flex justify-center items-center dark:bg-grey-950" aria-expanded={expandedCompletedPlans}
                      onClick={() => setExpandedCompletedPlans(!expandedCompletedPlans)}>
                <span className="sr-only">Show completed ideas</span>
                <Icon className={`w-3 h-3 sm:w-4 sm:h-4 transition text-brand ${expandedCompletedPlans ? 'rotate-90' : ''}`} iconName="chevron-right"/>
              </button>
            </div>
          </div>
        </div>
        }

        {/* Completed Plans */}
        {expandedCompletedPlans &&
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-10 pt-8" aria-expanded={expandedCompletedPlans}>
          {completedPlans && completedPlans.length > 0 && completedPlans.map((plan) => (
              <PlanCard
                key={plan.id}
                id={plan.id}
                name={plan.name}
                notes={plan.notes}
                creator={plan.creator_details}
                attending={plan.attending}
                attending_details={plan.attending_details}
                showAttending={(attending.length > 0)}
                url={plan.url}
                emoji={plan.emoji}
                address={plan.address}
                state={plan.state}
                city={plan.city}
                postal_code={plan.postal_code}
                onEditIdea={(id) => {
                  dispatch(showIdeaForm())
                  onEditPlan(id)
                }}
                completed={plan.completed}
                lat={plan.lat}
                lng={plan.lng}
                onLocationClick={(id) => handlePlanLocationClick(id)}
              />
            )
          )}
        </div>
        }
      </div>
    </>
  )
}

export default Plans;
