import React, {useRef, useState, useEffect} from 'react';
import NavbarMenuItems from "./NavbarMenuItems";
import {MenuItem} from "../../types";
import Icon from "../Icons/Icon";
import NameTag from "../NameTag";

interface NavbarMenuProps {
  menuItems: MenuItem[];
  image?: string | null;
  name?: string;
}

const NavbarMenu: React.FC<NavbarMenuProps> = ({menuItems, image, name}) => {
  const node = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOutside = (e: any) => {
    if (node.current !== null && node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  }

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <div ref={node} className="relative flex items-center">
      <button onClick={() => {
        setOpen(!open)
      }} className='flex ml-4 p-2 border border-lightGray rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-brand dark:border-grey-600'>
        {name && <NameTag name={name} image={image ? image : null}/>}
      </button>
      {open && (
        <NavbarMenuItems menuItems={menuItems}/>
      )}
    </div>
  )
}


export default NavbarMenu;
