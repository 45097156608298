import {Moment} from "moment";
import exp from "constants";
import {number} from "yup";
import trip from "./Pages/Trip";
import {DeltaStatic} from "quill";
import TripMessages from "./Components/TripMessages/TripMessages";

export enum FetchState {
  Idle = 'idle',
  Loading = 'loading',
  Fulfilled = 'fulfilled',
  Failed = 'failed',
}

export type MenuItem = {
  title: string;
  route: string;
  notifications?: number | null;
}

export type tripId = string;
export type userId = string;
export type groupId = number;

export interface Profile {
  image?: string;
  image_thumbnail?: any;
  traveler_type: string;
  buddies?: BuddyId[];
  bio: string | null;
  country: { code: string, name: string };
  pinterest: string | null;
  instagram: string | null;
  dark_mode_enabled: boolean;
}

export type BuddyId = userId;

export interface User {
  id: userId;
  username: string;
  email: string;
  email_verified: boolean;
  first_name: string | null;
  last_name: string | null;
  profile: Profile;
  trips_count: number;
}

type planStatusType = "idea" | "plan"
export type planId = number;
export type CreatorId = userId;
export type accommodationId = number;
export interface SmallUser  {
  id: userId,
  username: string,
  first_name: string,
  last_name: string,
  profile: {
    image: string,
    image_thumbnail: string,
    traveler_type: string,
  },
}

export interface Creator extends SmallUser {}

export interface Trip {
  id: tripId;
  name: string;
  countries: string;
  start_date: string;
  end_date: string;
  owner: User;
  attending: User[];
  image?: string | null;
  image_thumbnail?: string | null;
  stock_image_url?: string | null;
  public: boolean;
}

export interface Plan {
  id: planId;
  name: string;
  notes?: string;
  date?: Date;
  time?: string;
  address?: string;
  city?: string;
  country?: string;
  postal_code?: string;
  price?: string;
  status?: planStatusType;
  url?: string;
  emoji?: string;
  creator?: CreatorId;
  creator_details: Creator;
  attending: userId[];
  attending_details: SmallUser[];
  state: string;
  group?: number;
  objectState: FetchState;
  completed: boolean;
  lat: number | null;
  lng: number | null;
}

export enum SidebarTypes {
  Idea = 'idea',
  Invite = 'invite',
  Trip = 'trip',
  TripDetails = 'trip-details',
  Accommodation = 'accommodation',
  TripMessages = 'trip-messages'
}

export enum TripViewType {
  Idea = 'idea',
  Accommodation = 'accommodation',
  TripMessages = 'trip-messages'
}

export enum StickyFooterTypes {
  Trip = 'trip',
  Accommodation = 'accommodations',
  Messages = 'messages'
}

export interface Coordinates {
  lat: number,
  lng: number
}

export interface Accommodation {
  id: accommodationId;
  name: string;
  notes?: string;
  check_in_date?: Date;
  check_out_date?: Date;
  time?: string;
  address?: string;
  city?: string;
  country?: string;
  postal_code?: string;
  price?: string;
  status?: planStatusType;
  website?: string;
  creator?: CreatorId;
  creator_details: Creator;
  state: string;
  objectState: FetchState;
  lat: number | null;
  lng: number | null;
}

export interface PublicTrip {
  id: tripId;
  name: string;
  countries: string;
  start_date: string;
  end_date: string;
  owner: User;
  attending_count: number;
  image?: string | null;
  image_thumbnail?: string | null;
  stockImageUrl?: string | null;
}

export interface TripMessage {
  id: number;
  content_html: string;
  posted_by_profile: User;
  tripId: tripId;
  created_at: Date;
}

export type profileSettingsId = number;

export interface ProfileNotificationSetting {
  id: string,
  user: userId | null,
  receive_trip_message_emails: boolean,
  receive_trip_invite_emails: boolean,
  receive_buddy_invite_emails: boolean
}

// ------------------
// OTHER GLOBAL TYPES
// ------------------
export enum AppQueryStrings {
  public_trip_referral = 'public_trip_referral',
  reset_pass_token = 'reset_pass_token',
  verify_email_token = 't',
  base_64_uid_verify_email = 'uidb64'
}

export enum IntervalTime {
  SECOND = 1000,
  MINUTE = 60 * SECOND,
  HALF_MINUTE = 30 * SECOND,
}


export enum FIELD_ERRORS {
  empty = 'You must enter in some text first!'
}

// This is to match the type in Django-quill for rich text editors
export type FieldQuill = {
  delta: DeltaStatic | null;
  html: string;
}
