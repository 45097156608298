import React, {ReactElement, useEffect} from "react";
import {Coordinates, Creator, SmallUser, userId} from "../../types";
import Icon from "../Icons/Icon";
import ActionMenu from "../ActionMenu/ActionMenu";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {deleteAccommodation} from "../../store/accommodationSlice";
import {toast} from "react-toastify";
import moment from "moment";
// Google maps api
import Map from '../Map/Map'
import Marker from '../Map/Makrer'
import {darkModeStyles} from "../Map/darkModeStyles";

interface AccommodationCardProps {
  name: string,
  id: number;
  creator: Creator;
  notes?: string | null;
  check_in_date?: Date;
  check_out_date?: Date;
  website?: string | null;
  address?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  latLng: google.maps.LatLngLiteral | null;
  onEditAccom: (id: number) => void;
}

const AccommodationCard = ({name, id, creator, notes, check_in_date, check_out_date, website, address, city, state, postal_code, latLng, onEditAccom}: AccommodationCardProps) => {

  const {user} = useAppSelector(auth => auth.auth)
  const fullAddress = `${address}+${city}+${state}+${postal_code}`
  const darkMode = useAppSelector((state) => state.auth.user?.profile.dark_mode_enabled)
  const dispatch = useAppDispatch()

  async function handleDelete(id: number) {
    try {
      const result: any = await dispatch(deleteAccommodation(id))
      toast.success(`💥 Deleted Accommodation.`)
    } catch (err) {
      toast.error(`😟 Something went wrong - ${err.message}`)
    }
  }

  const [clicks, setClicks] = React.useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = React.useState(10); // initial zoom
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral | null>(latLng);

  // Update center when latlng changes
  useEffect(() => {
    setCenter(latLng)
  }, [latLng])

  const onClick = (e: google.maps.MapMouseEvent) => {
    // avoid directly mutating state - storing this for fun right now
    setClicks([...clicks, e.latLng!]);
  };

  const onIdle = (m: google.maps.Map) => {
    return
  };


  return (
    <>
      <div className="rounded-lg bg-white text-xs grid md:grid-cols-2 gap-8 border border-lightGray shadow-md dark:bg-grey-800 dark:border-0">
        {latLng &&
        <Map
          center={center}
          onClick={onClick}
          onIdle={onIdle}
          zoom={zoom}
          disableDefaultUI={true}
          className="grow min-h-[250px] lg:min-h-[300px] w-full"
          styles={darkMode ? darkModeStyles : null}
          zoomControl={true}
          fullscreenControl={true}
        >
          <Marker position={latLng}/>
        </Map>
        }
        <div className={`px-4 pb-4 md:py-8 ${!latLng && 'md:col-span-2 pt-4'}`}>
          <div className="flex flex-row justify-between items-center w-full">
            <span className="font-bold text-lg md:text-xl block">🏠 {name}</span>
            {creator.id === user?.id && <ActionMenu name={name} onEdit={() => onEditAccom(id)} onDelete={() => handleDelete(id)}/>}
          </div>
          <div>
            <span className="text-sm font-medium text-brand block">{address}</span>
            <span className="text-sm font-medium text-brand block">{city}{state && city && ', '} {state} {postal_code}</span>
          </div>

          {/* Check in & Check Out */}
          <div className="flex flex-row justify-start space-x-4">
            <div className="text-base mt-4 space-y-1">
              <span className="font-bold text-sm uppercase text-brand">Check-in date:</span>
              <p>{moment(check_in_date).format('MMMM DD, YYYY')}</p>
              <p><span className="font-semibold">At: </span>{moment.utc(check_in_date).format('hh:mm A')}</p>
            </div>
            <div className="text-base mt-4 space-y-1">
              <span className="font-bold text-sm uppercase text-brand">Checkout date:</span>
              <p>{moment(check_out_date).format('MMMM DD, YYYY')}</p>
              <p><span className="font-semibold">At: </span>{moment.utc(check_out_date).format('hh:mm A')}</p>
            </div>
          </div>

          {notes &&
          <div className="text-base mt-4">
            <span className="font-bold text-sm uppercase text-brand">Notes:</span>
            <p>{notes}</p>
          </div>
          }

          {/* Location and Website buttons */}
          <div className="mt-4 flex flex-row space-x-2">
            {(address || city || state || postal_code) &&
            <a className="py-1 px-2 rounded-full bg-white text-xs flex flex-row items-center border border-lightGray hover:text-brand dark:bg-grey-700 dark:border-0 dark:hover:bg-brand dark:hover:text-white"
               href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`} target="_blank" rel="noreferrer noopener">
              <Icon className="w-4 h-4  transition pr-1" iconName="map-pin"/>
              Location
            </a>
            }
            {website &&
            <a className="py-1 px-2 rounded-full bg-white text-xs hover:text-brand flex flex-row items-center border border-lightGray dark:bg-grey-700 dark:border-0 dark:hover:bg-brand dark:hover:text-white" href={website} target="_blank" rel="noopener noreferrer">
              <Icon className="w-4 h-4 pr-1" iconName="external-link"/>
              <span className="pt-1">Website</span>
            </a>}
          </div>
        </div>
      </div>
    </>
  )
}

export default AccommodationCard
