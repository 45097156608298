import React, {useState} from 'react'
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
  Suggestion
} from 'react-places-autocomplete';
import {Coordinates} from "../../types";
import Loading from "../Loading/Loading";

interface InputProps {
  getInputProps: () => {},
  suggestions: Suggestion[],
}

interface LocationSearchInputProps {
  searchOptions: {},
  onLocationSelected: ({}: google.maps.places.PlaceResult, suggestion: Suggestion) => void;
  onGetLatLng: ({}: Coordinates) => void;
  fieldValue: string; //formik field
  fieldName: string;
  fieldPlaceholder: string;
  onChange: (address: string) => void;
}

const LocationSearchInput = ({onChange, fieldValue, fieldName, fieldPlaceholder, searchOptions, onLocationSelected, onGetLatLng} :LocationSearchInputProps) => {

  const handleSelect = (address: string, placeID: string, suggestion: Suggestion) => {
    geocodeByPlaceId(placeID)
      .then((results) => {
        if (results[0]) {
          onLocationSelected(results[0], suggestion)
        }
        return getLatLng(results[0])
      })
      .then(({lat, lng}) => {
        onGetLatLng({lat: parseFloat(lat.toFixed(16)), lng: parseFloat(lng.toFixed(16))})
      })
      .catch((error: any) => console.error('Error', error));
  }

  return (
    <>
      <PlacesAutocomplete
        value={fieldValue}
        //@ts-ignore
        onSelect={handleSelect}
        onChange={(address) => {
          onChange(address)
        }}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="relative">

            <input {...getInputProps()} type="text" className="form-input location-search-input" name={fieldName} placeholder={fieldPlaceholder}/>

            <div className="bg-white rounded-md shadow-md absolute top-[42px] left-0 z-20 overflow-hidden w-full dark:bg-grey-700">
              {loading && <div className="text-center text-bold font-medium"><Loading/></div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'p-2 autocomplete-suggestion-item--active transition text-brand bg-offWhite hover:bg-offWhite font-medium hover:cursor-pointer w-full dark:bg-brand dark:text-white'
                  : 'p-2 hover:cursor-pointer hover:bg-offWhite hover:text-brand hover:font-medium transition w-full';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  )
}

export default LocationSearchInput;
