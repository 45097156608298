import React, {useEffect, useState} from 'react';
import {fetchProfileNotificationSettings} from "../../store/authSlice";
import {useAppDispatch} from "../../store/store";
import {unwrapResult} from "@reduxjs/toolkit";
import {FetchState, ProfileNotificationSetting} from "../../types";
import {toast} from "react-toastify";
import Loading from "../Loading/Loading";
import Button from "../Button/Button";
import {Field, Formik} from "formik";
import FormField from "../Forms/FormField";
import Toggle from "../Toggle/Toggle";

interface ProfileNotificationSettingsProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  onBlur: (e: React.FocusEvent<any>) => void,
  onFetchNotificationSettings: (settings: ProfileNotificationSetting) => void,
  notificationSettings: ProfileNotificationSetting | null,
  values: any,
}

const ProfileNotificationSettings = ({setFieldValue, onFetchNotificationSettings, notificationSettings, onBlur, values}: ProfileNotificationSettingsProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [errored, setErrored] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProfileNotificationSettings())
      .then(unwrapResult)
      .then((result) => {
        onFetchNotificationSettings(result[0])
        setLoading(false);
        setErrored(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrored(true)
        console.log(error)
      })
  }, [])

  return (
    <div>
      <h4 className="mb-4">Email Notifications</h4>

      <div className="shadow-sm rounded-lg p-4 md:p-8 relative border border-lightGray dark:border-grey-600">
        {loading &&
          <Loading/>
        }

        {errored &&
          <>
            <p>Sorry there was an error loading your email settings, please come back later.</p>
          </>
        }

        {notificationSettings &&
          <div className="grid md:grid-cols-2 gap-8 md:gap-4 w-full md:divide-x divide-lightGray dark:divide-grey-600">
            <div className="md:pr-6">
              <h5 className="text-gray">Trips</h5>
              <div className="grid grid-cols-1 gap-4 mt-4">
                <div className="flex items-center">
                  <Toggle
                    onBlur={(e: any) => onBlur(e)}
                    onChange={(value) => {
                      setFieldValue('receive_trip_message_emails', value)
                    }}
                    checked={values.receive_trip_message_emails}
                    label="Trip message emails"
                  />
                </div>

                <div className="flex items-center">
                  <Toggle
                    onBlur={(e: any) => onBlur(e)}
                    onChange={(value) => setFieldValue('receive_trip_invite_emails', value)}
                    checked={values.receive_trip_invite_emails}
                    label="Trip invite emails"
                  />
                </div>
              </div>
            </div>

            <div className="md:pl-6 lg:pl-8">
              <h5 className="text-gray">Travel Buddies</h5>
              <div className="grid grid-cols-1 gap-4 mt-4">
                <div className="flex items-center">
                  <Toggle
                    onBlur={(e: any) => onBlur(e)}
                    onChange={(value) => setFieldValue('receive_buddy_invite_emails', value)}
                    checked={values.receive_buddy_invite_emails}
                    label="Buddy invite emails"
                  />
                </div>
              </div>

            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default ProfileNotificationSettings;
