import React, {useEffect, useMemo, useState} from "react";
import Button from "../Button/Button";
import PlanGroup from "../PlanGroup/PlanGroup";
import {Plan} from "../../types";

interface GroupWithPlans {
  plans: Plan[];
  id: number;
  title: string;
  trip: string;
}

export interface PlanGroupsProps {
  showCategoryModal: () => void;
  onEditCategory: (id: number) => void;
  onEditPlan: (id: number) => void;
  onLocationClick: (planId: number) => void;
  plans: GroupWithPlans[];
  showAttendeesOnCards: boolean;
  showHeading: boolean | null;
}

const PlanGroups = ({showCategoryModal, onEditCategory, plans, onEditPlan, onLocationClick, showAttendeesOnCards, showHeading}: PlanGroupsProps) => {

  return (
    <div>

      {showHeading &&
      <div className="flex flex-col md:flex-row items-start justify-between mb-6 mt-10 lg:mt-12 lg:mt-14 md:mb-10">
        <div className="mb-4 md:mb-0">
          <span className="text-xl md:text-2xl font-bold">Categorized Ideas</span>
          <p className="mb-0 text-gray max-w-sm">Use this area to organize your activities.</p>
        </div>
      </div>
      }

      {plans && plans.length > 0 &&
      <div className="mt-4 md:mt-8">
        {plans.map((group) => (
          <PlanGroup
            key={group.id}
            title={group.title}
            plans={group.plans}
            onEditPlan={onEditPlan}
            groupId={group.id}
            onSettingsClick={(id) => {
              showCategoryModal()
              onEditCategory(id)
            }}
            onLocationClick={(planId) => onLocationClick(planId)}
            showAttendeesOnCards={showAttendeesOnCards}
          />
        ))}
      </div>
      }
    </div>
  )
}

export default PlanGroups;
