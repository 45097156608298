import React, {useState} from "react";
import Navbar from "../Components/Nav/Navbar";
import Icon from "../Components/Icons/Icon";
import Footer from "../Components/Footer/Footer";

type FAQItem = {
  question: string,
  answer: string,
  isOpen: boolean
}

const SupportPage = () => {
  const handleQuestionToggle = (index: number) => {
    setGeneralQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index].isOpen = !updatedQuestions[index].isOpen;
      return updatedQuestions;
    });
  };

  const [generalQuestions, setGeneralQuestions] = useState<FAQItem[]>([
    { question: 'How do I add a travel buddy to a trip?', answer: 'You must first invite them to be your travel buddy. Once they accept you can visit your trip and click on invite. The invite list shows a list of your current travel buddies that you can invite.', isOpen: false },
    { question: 'How do I add a trip?', answer: 'Website: On the trips screen there is a button on the top right to add a new trip. App: On the trips (home) screen there is a small plus button on the top right of the screen. Press that to add a new trip.', isOpen: false },
  ]);

  return (
    <div>
      <Navbar/>
      <section className="max-w-4xl container px-6 md:px-8 mt-12 md:mt-14 lg:mt-20 prose">
        <h1 className={"text-h1 text-brand"}>Help & Support</h1>
        <p>
          Experiencing issues with our app or website? We're here to help! Contact us at <a href="mailto:tripfomo.app@gmail.com">tripfomo.app@gmail.com</a> for prompt assistance.
          Whether it's technical glitches, account access problems, or navigating our platform, we're dedicated to
          ensuring your Tripfomo experience is seamless. Your journey matters, and we're committed to providing the support you need.
        </p>

        <h2 className={"text-h2 text-brand"}>Account & Data Removal</h2>
        <p>We at TripFomo are committed to ensuring the confidentiality and control of your personal data. Should you decide to remove your data from our servers or wish to delete your account, the process can be seamlessly conducted through the TripFomo application. Please navigate to your user profile by selecting your profile picture, then proceed to the 'Account' section. At the bottom of this page, you will find the option to 'Delete Account.' Selecting this option will initiate the process to permanently erase your data from our servers, typically completed within a few minutes.</p>
        <p>In instances where you may not have access to the app, or prefer an alternative method, we are here to assist. Please reach out to us via email at <a href="mailto:tripfomo.app@gmail.com">tripfomo.app@gmail.com</a>, and our support team will guide you through the necessary steps to ensure your account and data are removed according to your preferences.</p>

        {/* Fix this later - Too lazy right now */}
        {/*<div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">*/}
        {/*  <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">*/}
        {/*    <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>*/}
        {/*    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">*/}
        {/*      {generalQuestions.map((faq, index) => (*/}
        {/*        <div className="pt-6" key={`faq-block-${index}`}>*/}
        {/*          <dt>*/}
        {/*            <button type="button"*/}
        {/*                    className="flex w-full items-start justify-between text-left text-gray-900"*/}
        {/*                    aria-controls={`faq-answer-${index}`}*/}
        {/*                    aria-expanded={faq.isOpen}*/}
        {/*                    onClick={() => handleQuestionToggle(index)}>*/}
        {/*              <span className="text-base font-semibold leading-7">{faq.question}</span>*/}
        {/*              <span className="ml-6 flex h-7 items-center">*/}
        {/*               {faq.isOpen ? <Icon className={"w-6 h-6"} iconName={"minus"}/> : <Icon className={"w-6 h-6"} iconName={"plus"}/>}*/}
        {/*            </span>*/}
        {/*            </button>*/}
        {/*          </dt>*/}
        {/*          <dd className={`mt-2 pr-12 ${!faq.isOpen ? 'hidden' : ''}`} id={`faq-answer-${index}`}>*/}
        {/*            <p className="text-base leading-7 text-gray-600">{faq.answer}</p>*/}
        {/*          </dd>*/}
        {/*        </div>*/}
        {/*      ))}*/}
        {/*    </dl>*/}
        {/*  </div>*/}
        {/*</div>*/}

      </section>
      <Footer/>
    </div>
  )
}

export default SupportPage;
