import {BASE_URL, getIdFromLocalStorage, getTokenFromLocalStorage, RootState} from './store'
import {
  createAsyncThunk,
  createSlice,
  combineReducers
} from '@reduxjs/toolkit';
import {FetchState, Plan, tripId} from "../types";
import {reducer} from "react-toastify/dist/hooks/toastContainerReducer";

export interface PlanGroup {
  id: number;
  title: string;
  trip: tripId
}

export interface PlanGroupProps {
  id?: number;
  title: string;
  trip: tripId
}

export const fetchPlanGroups = createAsyncThunk<PlanGroup[],  tripId>(
  'planGroups/fetchPlanGroups',
  async (tripId) => {
    const response = await fetch(
      `${BASE_URL}/api/trips/${tripId}/plan_groups/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    );

    return await response.json();
  }
)

export const addPlanGroup = createAsyncThunk<PlanGroup, PlanGroupProps>(
  'planGroups/addPlanGroup',
  async ({title, trip}) => {
    const response = await fetch(
      `${BASE_URL}/api/plangroups/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
          title,
          trip
        })
      }
    );

    return await response.json();
  }
)


export const updatePlanGroup = createAsyncThunk<PlanGroup, PlanGroupProps>(
  'planGroups/updatePlanGroup',
  async ({id, trip, title}) => {
    const response = await fetch(
      `${BASE_URL}/api/plangroups/${id}/`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
          title,
          trip
        })
      }
    );
    return await response.json();
  }
)


export const deletePlanGroup = createAsyncThunk<null, number>(
  'planGroups/deletePlanGroup',
  async (id, rejectWithValue) => {
    const response = await fetch(
      `${BASE_URL}/api/plangroups/${id}/`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + getTokenFromLocalStorage()
        },
      }
    );

    if (!response.ok) {
      if (rejectWithValue) {
        try {
          // @ts-ignore
          return rejectWithValue(await response.json());
        } catch (e) {
          console.error(e);
        }
      }
      throw Error(`Unexpected status code - ${response.statusText}`);
    }

    return id;
  }
)


const initialPlanGroupState = {
  planGroups: [] as PlanGroup[],
  status: FetchState.Idle,
}

export const planGroupsSlice = createSlice({
  name: 'planGroupsSlice',
  initialState: initialPlanGroupState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPlanGroups.pending, (state) => {
      state.status = FetchState.Loading;
    });
    builder.addCase(fetchPlanGroups.fulfilled, (state, {payload}) => {
      state.status = FetchState.Fulfilled;
      state.planGroups = payload
    });
    builder.addCase(fetchPlanGroups.rejected, (state, {payload}) => {
      state.status = FetchState.Failed;
    });
    builder.addCase(addPlanGroup.fulfilled, (state, {payload}) => {
      state.planGroups = [...state.planGroups, payload]
    });
    builder.addCase(updatePlanGroup.fulfilled, (state, {payload}) => {
      //TODO: have group Id's as the key in the future to avoid this expensive operation
      // Return the group or spread the payload for the match
      state.planGroups = state.planGroups.map(group => group.id === payload.id ? {...payload} : group)
    });
    builder.addCase(deletePlanGroup.fulfilled, (state, {meta}) => {
      const {arg} = meta;
      //Filter out groups whose id were passed as an argument
      state.planGroups = state.planGroups.filter((group) => group.id !== arg)
    });
  }
})

