import React, {useEffect} from 'react'
import Input from "../Forms/Input";
import {useAppSelector} from "../../store/store";
import TravelBuddyCard from "../TravelBuddyCard/TravelBuddyCard";
import {getName} from "../../utils/utils";
import {tripId, userId, User} from "../../types";
import Button from "../Button/Button";
import Icon from "../Icons/Icon";
import {fetchBuddies, sendBuddyRequest} from "../../store/buddySlice";
import {inviteToTrip} from "../../store/tripInviteSlice";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {Link} from 'react-router-dom'

interface InviteAttendeesForm {
  onCancel: () => void;
  tripId: tripId
}

const InviteAttendeesForm: React.FC<InviteAttendeesForm> = ({tripId, onCancel}) => {
  const dispatch = useDispatch()
  const {buddies} = useAppSelector((state) => state.buddies)
  const {attending} = useAppSelector((state) => state.trip.trip)
  const {owner} = useAppSelector((state) => state.trip.trip)
  const {invites} = useAppSelector((state) => state.tripInvitesSent)
  const attendingIds = attending.map((attending) => attending.id)
  const invitedPeopleIds = invites.map((invite) => invite.receiver.id)

  //Filter out buddies attending and buddies that are already invited
  const filteredBuddies = buddies
    .filter((invited) => !invitedPeopleIds.includes(invited.id))
    .filter((attending) => !attendingIds.includes(attending.id))
    .filter((buddy) => buddy.id !== owner.id)

  useEffect(() => {
    dispatch(fetchBuddies())
  }, [])

  function onEnterSearchTerm(value: string) {
    // console.log(value)
  }

  function handleInviteToTrip(buddyId: userId, name: string) {
    try {
      dispatch(inviteToTrip({trip: tripId, receiver: buddyId}))
      toast.success(`😀 Invited ${name}`)
      return true
    } catch (err) {
      toast.error(`😟 Something went wrong - ${err.message}`)
      return false
    }
  }

  return (
    <div className="relative">
      <button onClick={onCancel} className="absolute right-0 p-2" aria-label="Close invite form">
        <Icon className="w-4 h-4 text-brand" iconName="close"/>
      </button>
      <h2 className="text-xl md:text-2xl font-bold">Invite a travel buddy to this trip</h2>
      {filteredBuddies.length > 0 &&
      <>
        <p className="text-gray mb-6">Select one of your travel buddies to invite</p>
        {/*TODO: Add filtering for travel buddies*/}
        {/*<div className="flex flex-row items-center mb-4 mt-6">*/}
        {/*  <Input type="search" placeholder="Filter travel buddy by name" onChange={(value) => onEnterSearchTerm(value)}/>*/}
        {/*</div>*/}
        <div className="mb-6 grid grid-cols-1 gap-4 md:max-h-[500px] overflow-y-scroll px-4 -mx-4">
          {filteredBuddies.map((buddy) => (
            <TravelBuddyCard
              id={buddy.id}
              key={buddy.id}
              name={getName(buddy)}
              image={buddy.profile.image_thumbnail}
              instagram={buddy.profile.instagram}
              pinterest={buddy.profile.pinterest}
              traveler_type={buddy.profile.traveler_type}
              type="invite"
              onInvite={() => handleInviteToTrip(buddy.id, getName(buddy))}/>
          ))}
        </div>
      </>
      }

      {filteredBuddies.length < 1 &&
      <div className="bg-offWhite p-4 rounded-md my-6 dark:bg-grey-800">You have invited all your buddies!{' '}
        <Link className="text-brand underline font-bold" to="/travel-buddies">Add more buddies</Link>
      </div>
      }
      <Button style="light" onClick={onCancel} classes="mb-4" aria-label="Close invite form">Go Back</Button>
    </div>
  )
}

export default InviteAttendeesForm
