import React, {useState, useRef, useEffect} from "react";
import Button from "../Button/Button";
import {BASE_URL} from '../../store/store'
import {tripId} from "../../types";


interface ShareTripFormProps {
  tripId: tripId;
  publicTrip: boolean;
  onSetPermission: (value: boolean) => void;
}

const ShareTripForm = ({tripId, publicTrip, onSetPermission}: ShareTripFormProps) => {
  const [copying, setCopying] = useState<boolean>(false);
  // @ts-ignore
  const [runningTimer, setRunningTimer] = useState<NodeJS.Timeout>(null);
  const linkInput = useRef<HTMLInputElement>(null);
  const [urlField, setUrlField] = useState<string>()
  const url = `https://tripfomo.com/public-trip/${tripId}`

  const handleOnChecked = () => {
    onSetPermission(!publicTrip);
  }

  useEffect(() => {
    if (publicTrip) {
      setUrlField(url);
    } else {
      setUrlField('')
    }
  }, [publicTrip])

  // Clear running timeout on component unmount
  useEffect(() => {
    return () => clearTimeout(runningTimer);
  }, [runningTimer]);

  function copyText() {
    if (linkInput.current) {
      navigator.clipboard.writeText(linkInput.current.value).then(() => {
        if (linkInput.current) {
          linkInput.current.select();
        }
        setCopying(true);

        clearTimeout(runningTimer);
        const newTimer = setTimeout(() => {
          setCopying(false);
        }, 2500);
        setRunningTimer(newTimer);
      });
    }
  }

  return (
    <div className="md:min-w-[500px] mb-4">
      <h2 className="text-2xl md:text-3xl front-bold mb-4">Share your trip</h2>

      <div className="mb-6 max-w-2xl">
        <div className="flex flex-row items-start justify-center space-x-2">
          <input id="make-trip-public" type="checkbox" className="form-checkbox rounded-md w-5 h-5 text-brand mt-1" checked={publicTrip} onChange={() => handleOnChecked()}/>
          <label htmlFor="make-trip-public" className="font-medium">Make trip publicly visible</label>
        </div>
        <p className="text-sm text-brand mx-w-2xl mt-2">Anyone will be able to join your trip and see the trip information.</p>
      </div>

      <div className="my-6">
        <p className="text-left uppercase text-sm text-brand">Shareable Trip Url</p>
        <div className="flex flex-row justify-center items-center space-x-2">
          <input type="text" className="form-input" value={urlField} ref={linkInput}/>
          <Button  style="dark" onClick={() => copyText()}>{copying ? 'Copied!' : 'Copy'}</Button>
        </div>
      </div>

    </div>
  )
}

export default ShareTripForm
