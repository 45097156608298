import {createAsyncThunk} from "@reduxjs/toolkit";
import {BASE_URL} from "./store";

interface resetPasswordEmailProps {
  email: string
}

export const resetPasswordEmail = createAsyncThunk<void, resetPasswordEmailProps>(
  'resetPasswordEmail',
  async ({email}, {rejectWithValue}) => {
    const response = await fetch(
      BASE_URL + '/api/password_reset/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
        })
      }
    );
    if (!response.ok) {
      if (rejectWithValue) {
        try {
          return rejectWithValue(await response.json());
        } catch (e) {
          console.error(e);
        }
      }
      throw Error(`Unexpected status code - ${response.statusText}`);
    }
    if (response.status !== 204) {
      return (await response.json() as any);
    }
    return await response.json();
  }
)

export const verifyResetPasswordToken = createAsyncThunk<any, string>(
  'verifyResetPasswordToken',
  async (token, {rejectWithValue}) => {
    const response = await fetch(
      BASE_URL + '/api/password_reset/validate_token/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token,
        })
      }
    );
    if (!response.ok) {
      if (rejectWithValue) {
        try {
          return rejectWithValue(await response.json());
        } catch (e) {
          console.error(e);
        }
      }
      throw Error(`Unexpected status code - ${response.statusText}`);
    }
    if (response.status !== 204) {
      return (await response.json() as any);
    }
    return await response.json();
  }
)

interface resetPasswordProps {
  password: string,
  token: string | null,
}

export const resetPassword = createAsyncThunk<any, resetPasswordProps>(
  'resetPassword',
  async ({password, token}, {rejectWithValue}) => {
    const response = await fetch(
      BASE_URL + '/api/password_reset/confirm/',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password,
          token,
        })
      }
    );
    if (!response.ok) {
      if (rejectWithValue) {
        try {
          return rejectWithValue(await response.json());
        } catch (e) {
          console.error(e);
        }
      }
      throw Error(`Unexpected status code - ${response.statusText}`);
    }
    if (response.status !== 204) {
      return (await response.json() as any);
    }
    return await response.json();
  }
)
