import React from 'react'
import {User} from "../../types"
import SmallAvatar from "../SmallAvatar/SmallAvatar";
import {Link} from 'react-router-dom'
import {countryNames} from "../../utils/utils";
import cardDefault from "../../img/card-default.jpg"
import {useHistory} from "react-router-dom";

export interface TripCardProps {
  id: string;
  title: string;
  image_thumbnail?: string | null;
  stock_image_url?: string | null;
  startDate: string;
  endDate: string;
  countries: string;
  owner: User;
  attending?: User[];
}

const TripCard: React.FC<TripCardProps> = ({id, title, image_thumbnail, stock_image_url, startDate, endDate, countries, owner, attending}) => {

  let history = useHistory();

  const imagePath = () => {
    // Could also be a switch statement here but yolo
    if (stock_image_url) {
      return stock_image_url
    }
    if (image_thumbnail) {
      return image_thumbnail
    }
    return cardDefault
  }


  return (
    <Link to={`/trip/${id}`} className="bg-white rounded-lg transform hover:translate-y-[-5px] transition duration-300 shadow-md hover:shadow-xl hover:cursor-pointer dark:bg-grey-800">
      <div className={`relative h-52 lg:h-56 overflow-hidden rounded-t-lg ${!image_thumbnail && !stock_image_url? 'dark:bg-grey-800 dark:opacity-50' : ''}`}>
        {/* TODO: Remove or revise random image here to work with unsplash api properly */}
        <img className="absolute object-cover h-full w-full left-1/2 top-1/2 min-w-full transform -translate-y-1/2 -translate-x-1/2" src={imagePath()} alt=""/>
      </div>
      <div className="flex flex-col p-4 md:p-8 rounded-lg bg-white relative transform translate-y-[-10px] dark:bg-grey-800">
        <h3 className="mb-1 font-bold text-xl md:text-2xl leading-none md:leading-none">{title}</h3>
        <span className="text-gray text-sm md:text-base">{startDate} - {endDate}</span>
        {countries &&
        <span className="text-brand text-sm md:text-base">{countryNames(countries)}</span>
        }
        <div className="mt-2 md:mt-3">
          <span className="text-xs uppercase font-bold text-brandLight mb-2 inline-block tracking-wide">{attending && attending.length > 0 ? attending.length + 1 : 1} Attending</span>
          <div className="flex flex-row justify-start items-center">
            {owner &&
            <div>
              <span className="sr-only">View {owner.first_name ? owner.first_name : owner.username}'s Profile</span>
              <SmallAvatar name={owner.first_name ? owner.first_name : owner.username} image={owner.profile?.image_thumbnail} showNameOnHover/>
            </div>
            }
            {attending &&
            attending.map((person, index) => (
              <div key={`trip-${id}-card-attending-${index}`}>
                <span className="sr-only">View {person.first_name ? person.first_name : person.username}'s Profile</span>
                <SmallAvatar key={`person-${person.id}`} name={person.first_name ? person.first_name : person.username} image={person.profile?.image_thumbnail} showNameOnHover/>
              </div>
            ))
            }
          </div>
        </div>
      </div>
    </Link>
  )
}

export default TripCard
