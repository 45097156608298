import React from "react";

export interface ButtonProps {
  style: 'dark' | 'light' | 'brand' | 'link' | 'brand-gradient' | 'outline-dark' | 'success' | 'danger';
  type?: "button" | "submit" | "reset";
  classes?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

// Types are dark light and brand
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({type, style, classes, children, onClick, disabled}, ref) => {

    function getButonStyle(style: string) {
      switch (style) {
        case "dark":
           return  'btn--dark';
          break;
        case "light":
          return 'btn--light';
          break;
        case "brand":
          return 'btn--brand';
          break;
        case "outline-dark":
          return 'btn--outline-dark';
          break;
        case "link":
          return '!bg-transparent'
          break
        case 'success':
          return 'btn--success'
          break
        case 'brand-gradient':
          return 'btn--brand-gradient'
          break
        case 'danger':
          return 'btn--danger'
          break
        default:
          break;
      }
    }

    let buttonStyle = 'btn ' + getButonStyle(style);

    return (
      <button className={buttonStyle + ' ' + classes} onClick={onClick} ref={ref} type={type} disabled={disabled}>
        {children}
      </button>
    )
  }
)

export default Button;
